import React, { useState } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  Container,
  Grid,
  ListItemButton,
  IconButton,
} from "@mui/material";
import { linkListData } from "../../utils/landingData.js";
import faqData from "../../utils/Faq.json"; // Adjust the import path as needed

import { styles } from "../../styles/styles";
import Plus from "../../assests/svg/Plus";
import { MdAdd, MdCheck, MdRemove } from "react-icons/md";

const FaqSection = ({ theme }) => {
  const [expandedPanel, setExpandedPanel] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [section, setSection] = useState("about");
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item.id);
    setSection(item.section);
  };

  const buttonColors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
  ];
  const renderAccordion = () => {
    return (
      <>
        {faqData
          .filter((item) => item.section === section)
          .map((item, index) => (
            <Accordion
              key={item.id}
              expanded={expandedPanel === `panel${index}`}
              onChange={handleAccordionChange(`panel${index}`)}
              sx={{
                borderRadius: "12px",
                mb: 4,
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
                borderWidth: 0,
                outline: "none",
                outlineWidth: 0,
                "&.MuiAccordion-root:before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedPanel === `panel${index}` ? (
                    <MdRemove color="white" />
                  ) : (
                    <MdAdd color="black" />
                  )
                }
                sx={{
                  "&.Mui-expanded": {
                    backgroundColor: theme.palette.primary.main, // Background color for expanded state
                  },
                  borderRadius: "12px",
                  pt: 1,
                  pb: 1,
                }}
              >
                <Box sx={{ mr: 2 }}>
                  <Plus
                    color={
                      expandedPanel === `panel${index}`
                        ? theme.palette.accent.main
                        : theme.palette.primary.main
                    }
                  />
                </Box>
                <Typography
                  variant="body1"
                  color={
                    expandedPanel === `panel${index}`
                      ? "white"
                      : theme.palette.dark.main
                  }
                >
                  {item.text}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  minHeight: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="faqtest"
              >
                {/* Replace "aquí" with a clickable mailto link */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.answer.replace(
                      /aquí/g,
                      `<a style={{color:"#F6A517"}} href="mailto:${item.link}">${item.link}</a>`
                    ),
                  }}
                />
              </AccordionDetails>
            </Accordion>
          ))}
      </>
    );
  };
  const renderLinks = () => {
    return (
      <List sx={{ pb: 0, pt: 0 }}>
        {linkListData.slice(0, 4).map((item, index) => (
          <ListItemButton
            key={item.id}
            selected={item.id === selectedItem}
            onClick={() => handleItemClick(item)}
            sx={{
              // width: "45%",
              borderRadius: "18px",
              mt: 0,
              fontWeight: "600",
              fontSize: 38,
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
            }}
          >
            <IconButton
              aria-label="goto"
              sx={{
                mr: 2,
                backgroundColor: buttonColors[index % buttonColors.length], // Cycle through buttonColors
              }}
            >
              <MdCheck size={10} color="white" />
            </IconButton>
            <ListItemText>
              <Typography color={theme.palette.dark.main} fontSize={12}>
                {item.text}
              </Typography>
            </ListItemText>
          </ListItemButton>
        ))}
      </List>
    );
  };
  const renderLinks2 = () => {
    return (
      <List sx={{ pt: 0 }}>
        {linkListData.slice(4, 7).map((item, index) => (
          <ListItemButton
            key={item.id}
            selected={item.id === selectedItem}
            onClick={() => handleItemClick(item)}
            sx={{
              // width: "45%",
              borderRadius: "18px",
              mt: 0,
              fontWeight: "600",
              fontSize: 38,
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
            }}
          >
            <IconButton
              aria-label="goto"
              sx={{
                mr: 2,
                backgroundColor: buttonColors[index % buttonColors.length], // Cycle through buttonColors
              }}
            >
              <MdCheck size={10} color="white" />
            </IconButton>
            <ListItemText>
              <Typography color={theme.palette.dark.main} fontSize={12}>
                {item.text}
              </Typography>
            </ListItemText>
          </ListItemButton>
        ))}
      </List>
    );
  };

  return (
    <Container maxWidth="xl">
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        mt={10}
        sx={styles.faqCard}
      >
        <Grid
          item
          container
          xs={12}
          md={4}
          mt={{ xs: 4, md: 10, lg: 10 }}
          //   mb={10}
        >
          <Typography
            variant="h4"
            color={theme.palette.dark.main}
            sx={{
              fontWeight: "600",
              fontSize: 48,
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                fontWeight: "600",
                fontSize: 28,
              },
            }}
          >
            Preguntas Frecuentes
          </Typography>
          <Box sx={{ width: "85%" }} mt={{ xs: 1, md: 6 }}>
            <Typography
              variant="body1"
              color={theme.palette.dark.main}
              sx={{ fontWeight: "400" }}
            >
              Obtén respuestas rápidas y encuentra la información que necesitas
              fácilmente.
            </Typography>
          </Box>
          <Grid container mt={6}>
            <Grid item lg={6} md={12}>
              {renderLinks()}
            </Grid>
            <Grid item lg={6} md={12}>
              {renderLinks2()}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={7}
          mt={{ xs: 4, md: 10, lg: 10 }}
          //   mb={10}
        >
          <Box
            sx={{
              width: "75%",
              [theme.breakpoints.down("md")]: {
                width: "100%",
                margin: "0 auto",
              },
            }}
            mt={1}
          >
            {renderAccordion()}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FaqSection;
