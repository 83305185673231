import { useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";

const InsuranceSummary = ({ plan, priceToCharge, apportionmentPrice }) => {
  const price = plan.finalPrice / (1 + plan.iva / 100);

  const total = plan.finalPrice;
  // const [priceNoIVA, setPriceNoIva] = useState(0);
  // const [priceToCharge, setPriceToCharge] = useState(0);
  // const [apportionmentPrice, setApportionmentPrice] = useState(0);

  // useEffect(() => {
  //   const overallDate = new Date();
  //   const year = overallDate.getFullYear();
  //   const month = overallDate.getMonth() + 1;
  //   const date = overallDate.getDate();

  //   //Days in the month
  //   const apportionmentDays = new Date(year, month, 0).getDate();
  //   //Days left in the month
  //   const apportionment = apportionmentDays - date + 1;
  //   //SET Price without IVA
  //   setPriceNoIva(plan.finalPrice - plan.finalPrice * 0.13);

  //   setApportionmentPrice((priceNoIVA / apportionmentDays) * apportionment);

  //   setPriceToCharge(
  //     (apportionmentPrice + apportionmentPrice * 0.13).toFixed(2)
  //   );
  // }, [apportionmentPrice, plan, priceNoIVA]);

  const ivaPrice = apportionmentPrice * (plan.iva / 100);
  const matches720 = useMediaQuery("(max-width:720px)");
  const matches768 = useMediaQuery("(max-width:768px)");
  const matches1200 = useMediaQuery("(max-width:1199px)");

  return (
    <>
      <h4 className="mt-2 fw-bold">Resumen de compra</h4>
      <div
        className={matches720 ? "" : "d-flex"}
        style={{
          height: matches720 ? "auto" : "140px",
          position: "relative",
          //marginBottom: matches720 && "160px",
        }}
      >
        <Card
          className="border-0"
          style={{
            backgroundColor: "#8e95ff",
            borderRadius: "18px",
            width: matches720 ? "100%" : "50%",
            marginBottom: matches720 && "10px",
            position: "relative",
            zIndex: 100,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Card.Body className="text-white">
            <p className="mb-0">
              Plan: <span className="fw-bold">{plan.subTier}</span>
            </p>
            <p className="mb-0">
              Precio: <span className="fw-bold">{apportionmentPrice}</span>
            </p>
            <p className="mb-0">
              I.V.A: <span className="fw-bold">{ivaPrice.toFixed(2)}</span>
            </p>
            <p className="mb-0">
              Total: <span className="fw-bold">{priceToCharge}</span>
            </p>
          </Card.Body>
        </Card>
        <Card
          className="border-0"
          style={{
            backgroundColor: "#b5b9ff",
            borderRadius: "18px",
            position: matches720 ? "initial" : "absolute",
            left: matches1200 ? "260px" : "170px",
            height: "140px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Card.Body
            className="text-white"
            style={{
              fontSize: "14px",
              padding: "10px",
              paddingLeft: matches768 ? (matches720 ? "10px" : "100px") : "",
            }}
          >
            <Row>
              <Col md="4"></Col>
              <Col md="8">
                <p>
                  El monto de tu plan {plan.subTier} fue ajustando al{" "}
                  {new Date().toLocaleDateString("CR")}. Al activar este plan
                  autorizas su recurrencia mensual el primer día del mes en
                  curso por un total de {total} I.V.A.I. mensuales.
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default InsuranceSummary;
