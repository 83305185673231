import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { styles } from "../../styles/styles";

import { ASbutton } from "../buttons/ASbutton";

import Plus from "../../assests/svg/Plus";
import phones from "../../assests/phonemockup.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import playstore from "../../assests/playstore.png";
import apple from "../../assests/apple.png";
import { cardData } from "../../utils/landingData";
import { useSelector } from "react-redux";
import DownloadModal from "./DownloadModal";

const MySwal = withReactContent(Swal);

const UserBenefits = ({}) => {
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleApple = () => {
    window.open(
      "https://apps.apple.com/us/app/all-sports/id1625764526",
      "_blank"
    );
  };
  const handleAndroid = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=plus.allsports.app",
      "_blank"
    );
  };
  const renderList = () => {
    return cardData.map((item, index) => (
      <Grid item lg={2.7} md={5} sm={12} xs={12} key={index}>
        <Box
          container
          sx={[
            styles.innerCard,
            { backgroundColor: "#EEEEEE", minHeight: "400px" },
          ]}
        >
          <Box mt={2} sx={{ width: "100%" }}>
            <Typography
              color={theme.palette.dark.main}
              variant="body1"
              sx={{ fontSize: 12 }}
            >
              {item.text}
            </Typography>
          </Box>
          <Box mt={2} sx={{ width: "100%" }}>
            <Typography variant="h4" sx={{ fontSize: 20, fontWeight: "bold" }}>
              {item.title}
            </Typography>
          </Box>
          <List sx={{ mt: 2 }}>
            {item.list.map((item) => (
              <ListItem key={item.id} sx={{ pl: 0, pr: 0, mb: 1 }}>
                <Box mr={1}>
                  <Plus />
                </Box>
                <ListItemText>
                  <Typography
                    color={theme.palette.dark.main}
                    variant="body1"
                    sx={{ fontSize: 12 }}
                  >
                    {item}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
    ));
  };

  if (loading) {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "450px",
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box sx={styles.formContainer}>
      <Box>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Beneficios
        </Typography>
      </Box>

      <Grid
        container
        mt={2}
        justifyContent={{
          xs: "center",
          sm: "space-around",
          md: "space-around",
          lg: "space-between",
        }}
        rowGap={2}
        p={4}
        columnGap={{ xs: 2, sm: 1, md: 2, lg: 2 }}
      >
        {renderList()}
      </Grid>
      <Grid
        container
        mt={2}
        justifyContent={{
          xs: "center",
          sm: "space-around",
          md: "space-around",
          lg: "space-between",
        }}
        rowGap={2}
        p={4}
        columnGap={{ xs: 2, sm: 1, md: 2, lg: 2 }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component={"img"}
            alt="getApp"
            src={phones}
            sx={{ mr: 2, width: "120px", height: "auto", maxHeight: "120px" }}
          />
          <Box>
            <Typography
              color={theme.palette.primary.main}
              variant="body1"
              sx={{ fontWeight: "600" }}
            >
              ¡Descárgala All Sports + GRATIS
            </Typography>
            <Typography
              color={theme.palette.primary.main}
              variant="body1"
              sx={{ fontWeight: "600" }}
            >
              para disfrutar todos los beneficios!
            </Typography>
            <Typography
              mt={2}
              sx={{
                fontWeight: "400",
                fontSize: 14,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setOpen(true)}
            >
              ¿Cómo descargar el app?
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          xs={12}
          sm={12}
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
          justifyContent={{
            sm: "flex-start",
            md: "space-between",
          }}
          alignItems={"center"}
        >
          <Grid item md={6} xs={6} container justifyContent={"center"}>
            <Box
              component="img"
              sx={{
                position: "relative",
                width: "150px",
                cursor: "pointer",
                zIndex: 3,
                [theme.breakpoints.down("md")]: {
                  width: "115px",
                },
                [theme.breakpoints.down("sm")]: {
                  width: "100px",
                },
              }}
              alt="playstore"
              onClick={handleAndroid}
              src={playstore}
              mr={{ sm: 6, xs: 2 }}
            />
          </Grid>
          <Grid item md={6} xs={6} container justifyContent={"center"}>
            <Box
              component="img"
              sx={{
                position: "relative",
                cursor: "pointer",
                zIndex: 3,
                width: "150px",
                [theme.breakpoints.down("md")]: {
                  width: "115px",
                },
                [theme.breakpoints.down("sm")]: {
                  width: "100px",
                },
              }}
              alt="app store"
              onClick={handleApple}
              src={apple}
            />
          </Grid>
        </Grid>
      </Grid>
      <DownloadModal open={open} onClose={() => setOpen(false)} />
    </Box>
  );
};
export default UserBenefits;
