import React from "react";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import CssBaseline from "@mui/material/CssBaseline";
import Router from "./app/routes/Router";
import theme from "./app/styles/theme";
import { Box, ThemeProvider } from "@mui/material";
import Header from "./app/components/header/Header";
import Footer from "./app/shared/ASFooter";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Header />
          <Box mt={"75px"}>
            <Router />
          </Box>
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
