import * as yup from "yup";

export const signUpFormFormSchema = yup.object({
  name: yup.string().required("Campo requerido"),
  lastname: yup.string().required("Campo requerido"),
  secondLastname: yup.string().required("Campo requerido"),
  email: yup.string().email("Formato no valido").required("Campo requerido"),
  password: yup.string().required("Campo requerido"),
  termsAndConditions: yup
    .boolean()
    .oneOf([true], "Debe aceptar antes de continuar"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden")
    .required("Campo requerido"),
});
