import ListGroup from "react-bootstrap/ListGroup";
import { handleWhatsapp } from "../../utils/Links";
import { useSelector } from "react-redux";
import DownloadModal from "../profile/DownloadModal";
import { useState } from "react";
import { useTheme } from "@emotion/react";

const Support = () => {
  const { supportPhone } = useSelector((state) => state.auth);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  return (
    <>
      <ListGroup>
        <ListGroup.Item
          className="border-0 text-white fw-bold ps-0"
          style={{
            backgroundColor: theme.palette.dark.main,
            cursor: "pointer",
          }}
        >
          Soporte
        </ListGroup.Item>
        <ListGroup.Item
          className="border-0 ps-0"
          style={{
            backgroundColor: theme.palette.dark.main,
            cursor: "pointer",
          }}
        >
          <a onClick={() => setOpen(true)} style={{ color: "#9b9ca8" }}>
            Descargas
          </a>
        </ListGroup.Item>
        <ListGroup.Item
          className="border-0 ps-0"
          style={{
            backgroundColor: theme.palette.dark.main,
            cursor: "pointer",
          }}
        >
          <a
            style={{ color: "#9b9ca8", cursor: "pointer !important" }}
            onClick={() => handleWhatsapp(supportPhone)}
          >
            Ayuda
          </a>
        </ListGroup.Item>
        <ListGroup.Item
          className="border-0 ps-0"
          style={{
            backgroundColor: theme.palette.dark.main,
            cursor: "pointer",
          }}
        >
          <a
            onClick={() => handleWhatsapp(supportPhone)}
            style={{
              color: "#9b9ca8",
            }}
          >
            En Línea
          </a>
        </ListGroup.Item>
        {/* <ListGroup.Item
          className="border-0 ps-0"
          style={{ backgroundColor: "#1f2131" }}
        >
          <a href="#" style={{ color: "#9b9ca8" }}>
            Tutoriales
          </a>
        </ListGroup.Item> */}
        {/* <ListGroup.Item
          className="border-0 ps-0"
          style={{ backgroundColor: "#1f2131" }}
        >
          <a href="#" style={{ color: "#9b9ca8" }}>
            Partners
          </a>
        </ListGroup.Item> */}
      </ListGroup>
      <DownloadModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default Support;
