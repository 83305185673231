import {
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useTheme,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { styles } from "../../styles/styles";
import { Typography } from "@mui/material";
import hacker from "../../assests/hacker.png";
import { Md10K, MdQuestionAnswer } from "react-icons/md";
import { getSupportPhone } from "../../store/actions/auth";
import { useEffect, useState } from "react";

import phones from "../../assests/phonemockup.png";
import playstore from "../../assests/playstore.png";
import apple from "../../assests/apple.png";
import DownloadModal from "./DownloadModal";

const Inquiries = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { supportPhone } = useSelector((state) => state.auth);
  useEffect(() => {
    const fetchSupportPhone = async () => {
      if (!supportPhone) {
        dispatch(getSupportPhone());
      }
    };

    fetchSupportPhone();
  }, [dispatch, supportPhone]);
  const whatsappLink = `https://wa.me/${supportPhone}`;
  const [open, setOpen] = useState(false);
  const questions = [
    {
      title: "Enviar una consulta",
      description: "Puede enviar a través de WhatsApp tu consulta",
      icon: <MdQuestionAnswer />,
      link: () => window.open(whatsappLink, "_blank"),
    },
    {
      title: "Reportar un Error",
      description: "Juntos mejoramos la experiencia de Usuario",
      icon: <MdQuestionAnswer />,
      link: () => window.open(whatsappLink, "_blank"),
    },
    {
      title: "Como utilizar el sistema",
      description:
        "Encontra el tutorial de uso de la Herramienta Allsports B2B",
      icon: <MdQuestionAnswer />,
      link: () => window.open(whatsappLink, "_blank"),
    },
    {
      title: "Soporte Técnico",
      description: "Estamos para apoyarte en toda situación",
      icon: <MdQuestionAnswer />,
      link: () => window.open(whatsappLink, "_blank"),
    },
    {
      title: "Consultar sobre un suceso a la Aseguradora",
      description: "En caso de un suceso ingrese aquí",
      icon: <MdQuestionAnswer />,
      link: () => window.open(whatsappLink, "_blank"),
    },
  ];

  const handleApple = () => {
    window.open(
      "https://apps.apple.com/us/app/all-sports/id1625764526",
      "_blank"
    );
  };
  const handleAndroid = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=plus.allsports.app",
      "_blank"
    );
  };

  const renderFaq = () => {
    return (
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {questions.map((question, index) => (
          <ListItem>
            <ListItemAvatar>
              <IconButton
                sx={{
                  bgcolor: theme.palette.terciary.main,
                  width: 48,
                  height: 48,
                  mr: 2,
                }}
                onClick={question.link}
              >
                {question.icon}
              </IconButton>
            </ListItemAvatar>
            <ListItemText
              sx={{ cursor: "pointer" }}
              onClick={() => window.open(whatsappLink, "_blank")}
              primary={question.title}
              secondary={question.description}
            />
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Grid
      container
      mt={4}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        mb: 4,
      }}
    >
      <Grid
        item
        md={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          [theme.breakpoints.down("md")]: {
            display: "flex",
            width: "325px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto 2em auto",
            maxWidth: "325px",
          },
          [theme.breakpoints.up("lg")]: {
            display: "flex",
            width: "450px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto 2em auto",
            maxWidth: "450px",
          },
        }}
      >
        <img src={hacker} alt="faq" style={{ width: "80%", height: "auto" }} />
      </Grid>
      <Grid item md={7}>
        {renderFaq()}
      </Grid>
      <Grid
        container
        mt={2}
        justifyContent={{
          xs: "center",
          sm: "space-around",
          md: "space-around",
          lg: "space-between",
        }}
        rowGap={2}
        columnGap={{ xs: 2, sm: 1, md: 2, lg: 2 }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component={"img"}
            alt="getApp"
            src={phones}
            sx={{ mr: 2, width: "120px", height: "auto", maxHeight: "120px" }}
          />
          <Box>
            <Typography
              color={theme.palette.primary.main}
              variant="body1"
              sx={{ fontWeight: "600" }}
            >
              ¡Descárgala All Sports + GRATIS
            </Typography>
            <Typography
              color={theme.palette.primary.main}
              variant="body1"
              sx={{ fontWeight: "600" }}
            >
              para disfrutar todos los beneficios!
            </Typography>
            <Typography
              mt={2}
              sx={{
                fontWeight: "400",
                fontSize: 14,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setOpen(true)}
            >
              ¿Cómo descargar el app?
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          xs={12}
          sm={12}
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
          justifyContent={{
            sm: "flex-start",
            md: "space-between",
          }}
          alignItems={"center"}
        >
          <Grid item md={6} xs={6} container justifyContent={"center"}>
            <Box
              component="img"
              sx={{
                position: "relative",
                width: "150px",
                cursor: "pointer",
                zIndex: 3,
                [theme.breakpoints.down("md")]: {
                  width: "115px",
                },
                [theme.breakpoints.down("sm")]: {
                  width: "100px",
                },
              }}
              alt="playstore"
              onClick={handleAndroid}
              src={playstore}
              mr={{ sm: 6, xs: 2 }}
            />
          </Grid>
          <Grid item md={6} xs={6} container justifyContent={"center"}>
            <Box
              component="img"
              sx={{
                position: "relative",
                cursor: "pointer",
                zIndex: 3,
                width: "150px",
                [theme.breakpoints.down("md")]: {
                  width: "115px",
                },
                [theme.breakpoints.down("sm")]: {
                  width: "100px",
                },
              }}
              alt="app store"
              onClick={handleApple}
              src={apple}
            />
          </Grid>
        </Grid>
      </Grid>
      <DownloadModal open={open} onClose={() => setOpen(false)} />
    </Grid>
  );
};

export default Inquiries;
