import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const NotificationDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userNotifications, setUserNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notification-popover" : undefined;
  // const handleNotificationClick = (notification) => {
  //   // Mark the notification as read (update its isRead property)
  //   const updatedNotification = {
  //     ...notification,
  //     id: notification.id,
  //     isRead: true,
  //   };

  //   addNotificationToAdmin(user.uid, updatedNotification);

  //   navigate(notification.route);
  //   handleClose();
  // };
  // useEffect(() => {
  //   const unsubscribe = subscribeToNotifications(
  //     associationId,
  //     (newNotifications) => {
  //       console.log("new", newNotifications);
  //       dispatch(getNotifications(newNotifications));
  //     }
  //   );

  //   return () => {
  //     unsubscribe();
  //   };
  // }, [associationId]);
  // useEffect(() => {
  //   const unsubscribe = subscribeToUserNotifications(
  //     user.uid,
  //     (userNotifications) => {
  //       // console.log("Received userNotifications:", userNotifications);

  //       const fetchUserNotifications = async () => {
  //         const notificationsWithReadStatus = notifications.map(
  //           (notification) => ({
  //             ...notification,
  //             isUnread: !userNotifications.some(
  //               (userNotification) => userNotification.id === notification.id
  //             ),
  //           })
  //         );

  //         const unreadNotifications = notificationsWithReadStatus.filter(
  //           (n) => n.isUnread
  //         );
  //         setNotificationCount(unreadNotifications.length);
  //         setUserNotifications(notificationsWithReadStatus);
  //       };

  //       fetchUserNotifications();
  //     }
  //   );

  //   return () => {
  //     unsubscribe();
  //   };
  // }, [user.uid, notifications]);

  // const markAllNotificationsAsRead = () => {
  //   // Loop through userNotifications and mark them as read
  //   const updatedNotifications = userNotifications.map((notification) => ({
  //     ...notification,
  //     isRead: true,
  //   }));

  //   setUserNotifications(updatedNotifications);
  //   notifications.forEach((notification) => {
  //     addNotificationToAdmin(user.uid, {
  //       ...notification,
  //       id: notification.id,
  //       isRead: true,
  //     });
  //   });

  //   handleClose();
  // };
  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        color="inherit"
        sx={{ mx: 2 }}
      >
        <Badge badgeContent={notificationCount} color="error">
          <NotificationsIcon sx={{ color: "text.secondary" }} />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          {userNotifications.length === 0 ? (
            <ListItem>
              <ListItemText primary="No hay notificaciones" />
            </ListItem>
          ) : (
            userNotifications.map((notification) => (
              <ListItem
                key={notification.id}
                // onClick={() => handleNotificationClick(notification)}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                  },
                  minWidth: "125px",
                }}
              >
                <ListItemText
                  primary={notification.title}
                  sx={{ textAlign: "center" }}
                />
                {notification.isUnread && (
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      backgroundColor: "red",
                      borderRadius: "50%",
                      marginLeft: "8px",
                    }}
                  />
                )}
              </ListItem>
            ))
          )}
          {userNotifications.length !== 0 ? (
            <ListItem
              sx={{
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
                minWidth: "125px",
              }}
              // onClick={markAllNotificationsAsRead}
            >
              <ListItemText primary="Marcar como leídas" />
            </ListItem>
          ) : null}
        </List>
      </Popover>
    </>
  );
};

export default NotificationDropdown;
