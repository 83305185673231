import React, { useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  useTheme,
  IconButton,
  Box,
} from "@mui/material";
import Hero from "../components/hero/Hero";

import Footer from "../shared/ASFooter";
import FaqSection from "../components/bottom/FaqSection";
import BottomSection from "../components/bottom/BottomSection";

const FAQPage = () => {
  const theme = useTheme();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, []);
  return (
    <>
      <Hero theme={theme} />
      <Container maxWidth={"lg"}>
        <Grid container>
          <Grid item md={12} sm={6}>
            <FaqSection theme={theme} />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={"xl"}>
        <Box mb={10} mt={40}>
          <BottomSection theme={theme} />
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default FAQPage;
