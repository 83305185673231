import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  useTheme,
  IconButton,
  Box,
  TextField,
  Button,
} from "@mui/material";
import Hero from "../components/hero/Hero";

import contact from "../assests/contact.png";

import { styles } from "../styles/styles";
import { contactData } from "../utils/landingData";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { uploadForm } from "../store/actions/user";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BottomSection from "../components/bottom/BottomSection";

const MySwal = withReactContent(Swal);

const ContactPage = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, []);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo Obligatorio"),
    email: Yup.string().email("Correo Inválido").required("Campo obligatorio"),
    message: Yup.string().required("Campo Obligatorio"),
  });
  const handleSubmit = async (values, { resetForm }) => {
    const confirmResult = await MySwal.fire({
      title: "¿Enviar? ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, enviar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    });

    if (confirmResult.isConfirmed) {
      try {
        await uploadForm(values);

        MySwal.fire("Enviado!", "", "success");
      } catch (error) {
        MySwal.fire("Error", "Hubo un error al enviar tu mensaje", "error");
      }

      setLoading(false);
    }

    resetForm();
  };

  return (
    <>
      <Hero theme={theme} />
      <Container maxWidth={"lg"}>
        <Grid
          container
          rowGap={6}
          columnGap={8}
          mt={10}
          mb={4}
          justifyContent={{
            xs: "center",
            sm: "space-between",
            md: "space-between",
            lg: "space-between",
            xl: "space-between",
          }}
        >
          {contactData.map((item, index) => (
            <Grid
              item
              md={3.5}
              sm={5}
              xs={10}
              key={index}
              sx={styles.contactCard}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <IconButton
                  sx={{
                    // background:
                    //   "radial-gradient(circle, rgba(8,31,85,1) 53%, rgba(85,132,234,1) 99%)",
                    backgroundColor: theme.palette.primary.main,
                    mb: 2,
                  }}
                >
                  {item.icon}
                </IconButton>
              </a>
              <Box sx={{ minHeight: 50 }}>
                {item.link.startsWith("mailto:") ? (
                  <a
                    style={{ color: theme.palette.primary.main }}
                    href={item.link}
                  >
                    {item.text}
                  </a>
                ) : (
                  <a
                    style={{ color: theme.palette.primary.main }}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.text}
                  </a>
                )}
                <Typography variant="body1">{item.text2}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          spacing={2}
          alignItems="center"
          mt={10}
          mb={4}
          justifyContent={"center"}
        >
          <Grid item container xs={12} sm={12} md={6} justifyContent={"center"}>
            <Box
              component="img"
              sx={{
                width: "100%",
                height: "auto",
                cursor: "pointer",
                p: 1,
                [theme.breakpoints.down("md")]: {
                  width: "90%",
                  margin: "0 auto",
                  height: "auto",
                },
              }}
              alt="contact"
              src={contact}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={10}
            md={6}
            mt={{ xs: 6, sm: 6 }}
            justifyContent={"center"}
          >
            <Formik
              initialValues={{
                name: "",
                email: "",
                message: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, isValid }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        label="Ingrese su Nombre"
                        variant="outlined"
                        name="name"
                      />
                      <ErrorMessage name="name" component="div" />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        label="Su email"
                        variant="outlined"
                        name="email"
                      />
                      <ErrorMessage name="email" component="div" />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        fullWidth
                        multiline
                        rows={4}
                        label="¿Comó podemos ayudarle?"
                        variant="outlined"
                        name="message"
                      />
                      <ErrorMessage name="message" component="div" />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={!isValid}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: 200,
                          height: 60,
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: "18px",
                          pr: 2,
                          pl: 2,

                          "&:hover": {
                            backgroundColor: theme.palette.secondary.main,
                          },
                        }}
                      >
                        Enviar
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>

        <Box mb={10} mt={40}>
          <BottomSection theme={theme} />
        </Box>
      </Container>
    </>
  );
};

export default ContactPage;
