import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import heroBackground from "../../assests/bg-hero.png";
import appExample from "../../assests/celulares.png";
import IconOne from "../../assests/Icon.png";
import IconTwo from "../../assests/Icon2.png";
import IconThree from "../../assests/Icon3.png";
import right from "../../assests/HAND.png";
import improve from "../../assests/improve.png";
import circle from "../../assests/yellowCircle.png";
import AsGirl from "../../assests/chicaAS.png";
import { cardData } from "../../utils/landingData.js";
import PromoCard from "../cards/PromoCard";
import { ASbutton } from "../buttons/ASbutton";
import { useNavigate } from "react-router-dom";

const PlansPreview = ({ theme }) => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const navigateTo = (route) => {
    navigate(route);
  };
  const data = [
    {
      id: 1,
      text: "Sincroniza con relojes inteligentes",
      icon: IconOne,
    },
    {
      id: 2,
      text: "Cada día es un inicio",
      icon: IconTwo,
    },
    {
      id: 3,
      text: "Desbloquea recompensas ",
      icon: IconThree,
    },
  ];

  const handleItemClick = (item) => {
    setSelectedItem(item.id);
    // Add your onClick logic here
  };

  const renderList = () => {
    return (
      <List>
        {data.map((item) => (
          <ListItem
            key={item.id}
            onClick={() => handleItemClick(item)}
            sx={{
              width: "65%",
              borderRadius: "18px",
              mt: 2,
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
            }}
          >
            <Box
              component="img"
              sx={{
                height: "auto",
                width: "24px",
                marginRight: "16px",
              }}
              alt="Icon"
              src={item.icon}
            />
            <ListItemText>
              <Typography color={theme.palette.dark.main}>
                {item.text}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    );
  };

  const renderCardGrid = () => {
    return (
      <Grid
        container
        mt={{ xs: 4, md: 10, lg: 10 }}
        justifyContent={{
          xs: "center",
          sm: "space-around",
          md: "space-around",
          lg: "space-between",
        }}
        rowGap={2}
        p={{ xs: 1, sm: 2, md: 4 }}
        columnGap={{ xs: 0, sm: 1, md: 2, lg: 2 }}
      >
        {cardData.map((item) => (
          <Grid item lg={2.5} md={4} sm={5} xs={12}>
            <PromoCard item={item} theme={theme} />
          </Grid>
        ))}
        <Box
          className="d-flex m-5"
          sx={{
            margin: "0 auto",
            width: "100%",
            justifyContent: "center",

            [theme.breakpoints.down("sm")]: {
              justifyContent: "center",
              margin: "0 auto",
            },
          }}
        >
          <ASbutton
            backgroundColor={theme.palette.primary.main}
            height={85}
            responsiveHeight={60}
            width={"100%"}
            buttonText={
              "    Pregúntanos cualquier duda sobre los requisitos o servicios"
            }
            onClick={() => navigateTo("/contact")}
          ></ASbutton>
        </Box>
      </Grid>
    );
  };

  return (
    <Container maxWidth="xl">
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        mt={{ xs: 2, sm: 10, md: 10 }}
      >
        <Grid
          item
          container
          justifyContent={{ xs: "center", sm: "center", lg: "flex-start" }}
          xs={12}
          sm={12}
          md={6}
          order={{ xs: 2, sm: 1 }}
        >
          <Box
            component="img"
            sx={{
              height: "auto",
              width: "85%",
              [theme.breakpoints.down("md")]: {
                width: "90%",
              },
              [theme.breakpoints.down("sm")]: {
                width: "75%",
              },
            }}
            alt="The house from the offer."
            src={appExample}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          p={{ xs: 1, sm: 2, md: 4 }}
          order={{ xs: 1, sm: 2 }}
        >
          <Typography
            sx={{ color: theme.palette.secondary.main, fontWeight: "600" }}
          >
            Con All Sports +
          </Typography>
          <Box sx={{ width: "100%" }} mt={1}>
            <Typography
              variant="h3"
              color={theme.palette.dark.main}
              sx={{
                fontWeight: "600",
                fontSize: 48,
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                  fontWeight: "600",
                  fontSize: 24,
                },
              }}
            >
              Toma el control de tu actividad física y celebra con recompensas.
            </Typography>
          </Box>

          {renderList()}
        </Grid>
      </Grid>

      {/* SECOND SECTION */}

      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        mt={{ xs: 4, md: 10, lg: 10 }}
        mb={{ xs: 4, md: 10, lg: 10 }}
        sx={{ height: "100%", position: "relative" }}
      >
        <Grid
          item
          container
          xs={12}
          md={6}
          pr={4}
          flexDirection={"column"}
          justifyContent={"center"}
          p={{ xs: 1, sm: 2, md: 4 }}
        >
          <Box sx={{ width: "45%" }}>
            <Typography
              sx={{ color: theme.palette.primary.main, fontWeight: "600" }}
            >
              ¿Pensando en Bienestar?
            </Typography>
          </Box>
          <Box
            sx={{
              width: "50%",
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
            }}
            mt={2}
          >
            <Typography
              variant="h3"
              color={theme.palette.dark.main}
              sx={{
                fontWeight: "600",
                fontSize: 48,
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                  fontWeight: "600",
                  fontSize: 24,
                },
              }}
            >
              Mejoremos tu calidad de vida con herramientas integrales.
            </Typography>
          </Box>
          <Box sx={{ width: "85%" }} mt={{ xs: 2, sm: 10 }}>
            <Typography
              variant="body1"
              color={theme.palette.dark.main}
              sx={{
                width: "65%",
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                },
              }}
            >
              Descubre cómo tus hábitos saludables pueden tener un impacto
              positivo en tu vida.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          pr={4}
          flexDirection={"column"}
          justifyContent={"center"}
          p={{ xs: 1, sm: 2, md: 4 }}
        >
          <Box
            sx={{
              position: "relative",
              backgroundImage: `url(${improve})`, // Set the background image here
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "auto", // Set the height as needed
              width: "100%", // Set the width as needed
              marginRight: "16px",
              zIndex: -1,
            }}
          >
            <Box
              component="img"
              sx={{
                visibility: "hidden",
                height: "auto",
                width: "100%",
              }}
              alt="Icon"
              src={improve}
            />
          </Box>
        </Grid>
      </Grid>

      {/* THIRD SECTION */}

      {renderCardGrid()}

      {/* FOURTH SECTION */}

      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        sx={{ height: "100% !important" }}
        mt={10}
      >
        <Grid
          item
          container
          xs={12}
          md={6}
          justifyContent={"center"}
          alignItems={"center"}
          p={{ xs: 2, sm: 2, md: 4 }}
        >
          <Box
            component="img"
            sx={{
              height: "auto",
              width: "85%",
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
            }}
            alt="Bienestar"
            src={AsGirl}
          />
        </Grid>

        <Grid
          item
          container
          xs={12}
          md={6}
          mt={{ xs: 4, md: 10, lg: 10 }}
          mb={{ xs: 2, md: 3, lg: 3 }}
          pl={4}
          p={{ xs: 2, sm: 2, md: 4 }}
          flexDirection={"column"}
          sx={{
            minHeight: "60vh",
            [theme.breakpoints.down("lg")]: {
              minHeight: "30vh",
            },
          }}
        >
          <Typography
            sx={{ color: theme.palette.primary.main, fontWeight: "600" }}
          >
            ¿Pensando en Bienestar?
          </Typography>
          <Box
            sx={{
              width: "75%",
              [theme.breakpoints.down("lg")]: {
                width: "90%",
              },
            }}
            mt={1}
          >
            <Typography
              variant="h3"
              color={theme.palette.dark.main}
              sx={{
                fontWeight: "600",
                fontSize: 48,
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                  fontWeight: "600",
                  fontSize: 24,
                },
              }}
            >
              Logra equilibrio entre bienestar y salud, mientras disfrutas de tu
              actividad física
            </Typography>
          </Box>
          <Box
            sx={{
              width: "85%",
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
            }}
            mt={6}
          >
            <Typography
              variant="body1"
              color={theme.palette.dark.main}
              sx={{ fontWeight: "400" }}
            >
              Descubre cómo nuestros seguros pueden cubrir tus necesidades.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PlansPreview;
