import { db } from "../firebase/firebase-config";

export const registerRequest = async (data) => {
  await db
    .collection("contacts")
    .doc("register")
    .collection("requests")
    .add(data);
};
export const checkIfUserExists = async (brokerId) => {
  const querySnapshot = await db
    .collection("users")
    .where("brokerId", "==", brokerId)
    .get();
  return !querySnapshot.empty;
};
export const deleteAccount = async (data) => {
  try {
    // Assuming 'bcollection' is a typo and you meant 'firestore'
    await db
      .collection("contacts")
      .doc("deleteRequests")
      .collection("requests")
      .add(data);
  } catch (error) {
    console.error(error);
    // Handle the error here or rethrow it if needed
    throw error;
  }
};
