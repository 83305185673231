import ListGroup from "react-bootstrap/ListGroup";
import { useNavigate } from "react-router-dom";

const AboutUs = ({ theme }) => {
  const navigate = useNavigate();
  const navigateTo = (route) => {
    navigate(route);
  };
  return (
    <>
      <ListGroup>
        <ListGroup.Item
          className="border-0 text-white fw-bold ps-0"
          style={{ backgroundColor: theme.palette.dark.main }}
        >
          Nosotros
        </ListGroup.Item>
        <ListGroup.Item
          className="border-0 ps-0"
          style={{ backgroundColor: theme.palette.dark.main }}
        >
          <a
            href=""
            style={{ color: "#9b9ca8" }}
            onClick={() => navigateTo("/about-us")}
          >
            Somos
          </a>
        </ListGroup.Item>
        <ListGroup.Item
          className="border-0 ps-0"
          style={{ backgroundColor: theme.palette.dark.main }}
        >
          <a
            href=""
            style={{ color: "#9b9ca8" }}
            onClick={() => navigateTo("/contact")}
          >
            Contactos
          </a>
        </ListGroup.Item>
        <ListGroup.Item
          className="border-0 ps-0"
          style={{ backgroundColor: theme.palette.dark.main }}
          onClick={() => navigateTo("/plans")}
        >
          <a href="" style={{ color: "#9b9ca8" }}>
            Seguros
          </a>
        </ListGroup.Item>
        {/* <ListGroup.Item
          className="border-0 ps-0"
          style={{ backgroundColor: "#1f2131" }}
        >
          <a href="" style={{ color: "#9b9ca8" }}>
            Iniciar sesion
          </a>
        </ListGroup.Item>
        <ListGroup.Item
          className="border-0 ps-0"
          style={{ backgroundColor: "#1f2131" }}
        >
          <a href="" style={{ color: "#9b9ca8" }}>
            Registrarme
          </a>
        </ListGroup.Item> */}
        <ListGroup.Item
          className="border-0 ps-0"
          style={{ backgroundColor: theme.palette.dark.main }}
        >
          <a
            href=""
            style={{ color: "#9b9ca8" }}
            onClick={() => navigateTo("/faq")}
          >
            FAQ
          </a>
        </ListGroup.Item>
      </ListGroup>
    </>
  );
};

export default AboutUs;
