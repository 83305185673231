import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

// Import CloseIcon
import { MdClose } from "react-icons/md";
import image from "../assests/modal.png";
import playstore from "../assests/playstore.png";
import apple from "../assests/apple.png";
import { Container } from "react-bootstrap";

export const DownloadPage = () => {
  const theme = useTheme();
  const handleApple = () => {
    window.open(
      "https://apps.apple.com/us/app/all-sports/id1625764526",
      "_blank"
    );
  };
  const handleAndroid = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=plus.allsports.app",
      "_blank"
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, []);
  return (
    <Box mt={20}>
      <Container maxWidth="lg">
        <Grid container mb={10} justifyContent={"center"} alignItems={"center"}>
          <Grid
            item
            md={6}
            sx={{
              boxShadow: "none",
              background: "transparent",

              borderRadius: "18px",
              position: "relative",
              [theme.breakpoints.down("md")]: {
                minWidth: "90%",
              },
            }}
          >
            <Box sx={{ p: 0 }}>
              <Grid container>
                <Grid item container md={12}>
                  <Box
                    component="img"
                    sx={{
                      width: "100%",
                      cursor: "pointer",
                      [theme.breakpoints.down("sm")]: {
                        marginRight: "auto",
                        marginLeft: "auto",
                      },
                    }}
                    alt="descarga"
                    src={image}
                  />
                </Grid>
                <Grid
                  item
                  container
                  md={12}
                  justifyContent={"space-between"}
                  sx={{
                    backgroundColor: "#0F0F0F",
                    height: "110px",
                    pl: 2,
                    pt: 2,
                    pb: 4,
                  }}
                >
                  <Grid item md={3} container alignItems={"center"}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ fontSize: 16, color: "white" }}
                      >
                        www.allsports.plus
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: 14, color: "white" }}
                      >
                        info@allsports.plus
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: 14, color: "white" }}
                      >
                        WA: +506 8703-8008
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={8}
                    container
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                    justifyContent={{
                      sm: "flex-start",
                      md: "space-between",
                    }}
                    alignItems={"center"}
                  >
                    <Grid
                      item
                      container
                      md={12}
                      justifyContent={"center"}
                      mb={1}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 14,
                          fontWeight: "bold",
                          color: "white",
                        }}
                      >
                        ¡Activa tu bienestar y únete hoy mismo!
                      </Typography>
                    </Grid>

                    <Grid item container md={6} justifyContent={"center"}>
                      <Box
                        component="img"
                        sx={{
                          position: "relative",
                          width: "150px",
                          cursor: "pointer",

                          zIndex: 3,
                          [theme.breakpoints.down("md")]: {
                            width: "115px",
                          },
                          [theme.breakpoints.down("sm")]: {
                            width: "100px",
                          },
                        }}
                        alt="playstore"
                        onClick={handleAndroid}
                        src={playstore}
                      />
                    </Grid>
                    <Grid item md={6} container justifyContent={"center"}>
                      <Box
                        component="img"
                        sx={{
                          position: "relative",
                          cursor: "pointer",
                          zIndex: 3,
                          width: "150px",
                          [theme.breakpoints.down("md")]: {
                            width: "115px",
                          },
                          [theme.breakpoints.down("sm")]: {
                            width: "100px",
                          },
                        }}
                        alt="app store"
                        onClick={handleApple}
                        src={apple}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
