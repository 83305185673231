import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";

import "../benefits/benefits.scss";
import Arrow from "../arrow/Arrow";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useEffect, useState } from "react";

import "./banners.scss";

const BannersCarousel = ({ wellnessCarousel }) => {
  const [snapped, setSnapped] = useState(true);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [screenSize, setScreenSize] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = (e) => {
    setStartPosition({ x: e.clientX, y: e.clientY });
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // const handleClick = () => {
  //   // Open the specified link in a new window
  //   window.open("https://www.youtube.com/", "_blank");
  // };

  const responsive = {
    0: { items: 1 },
    1024: { items: 5 },
  };

  const handleClick = (link) => (e) => {
    if (!isDragging) {
      const { x: startX, y: startY } = startPosition;
      const { clientX: endX, clientY: endY } = e;

      const distance = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);
      const clickThreshold = 5;

      if (distance <= clickThreshold) {
        window.open(link, "_blank");
      }
    }
  };

  const handleSlideChanged = ({ item }) => {
    setSnapped(item === 0); // Enable snapping when on the first item
  };

  const handleDragStart = (e) => e.preventDefault();

  const items = wellnessCarousel.map((item) => {
    const isLarge = item.isLarge;
    return (
      <img
        src={item.imgURL}
        alt="Card image"
        onClick={handleClick(item.link)}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        className={`item ${isLarge && "benefits-mobile-large-img"}`}
        onDragStart={handleDragStart}
        style={
          isLarge
            ? {
                width: "650px",
                minWidth: "650px",
                height: "275px",
                maxHeight: "275px",
                minHeight: "275px",
                padding: "0 10px",
                cursor: "pointer",
              }
            : {
                width: "325px",
                maxHeight: "275px",
                height: "275px",
                minHeight: "275px",
                padding: "0 10px",
                cursor: "pointer",
              }
        }
        //style={{ width: "auto", height: "375px", padding: "0 10px" }}
        // style={
        //   isLarge
        //     ? { width: "850px", height: "375px", padding: "0 10px" }
        //     : { width: "450px", height: "375px", padding: "0 10px" }
        // }
      />
    );
  });

  return (
    <div
      className="position-relative"
      style={{ minHeight: "375px", display: "flex", justifyContent: "center" }}
    >
      <AliceCarousel
        autoWidth
        autoHeight
        disableDotsControls
        controlsStrategy={"responsive"}
        responsive={responsive}
        mouseTracking={true}
        infinite
        touchMoveDefaultEvents={false}
        items={items}
        renderPrevButton={() => {
          return (
            <span
              style={{
                position: "absolute",
                top: "45%",
                left: "20px", // Adjust the left position as needed
                zIndex: 1, // Ensure the arrows are above the carousel items
              }}
              className="d-none d-md-block"
            >
              <Arrow left />
            </span>
          );
        }}
        renderNextButton={() => {
          return (
            <span
              style={{
                position: "absolute",
                top: "45%",
                right: "20px", // Adjust the right position as needed
                zIndex: 1, // Ensure the arrows are above the carousel items
              }}
              className="d-none d-md-block"
            >
              <Arrow right />
            </span>
          );
        }}
      />
    </div>
  );

  // const [sliderRef, instanceRef] = useKeenSlider(
  //   {
  //     slides: { perView: "auto", spacing: 10 },
  //     loop: true,
  //     mode: "free",
  //     renderMode: "precision",
  //     breakpoints: {
  //       "(max-width: 350px)": {
  //         slides: { perView: 1, spacing: 1 },
  //       },
  //       "(min-width: 350px)": {
  //         slides: { perView: 2, spacing: 1 },
  //       },
  //       "(min-width: 768px)": {
  //         slides: { perView: 2, spacing: 1 },
  //       },
  //       "(min-width: 1000px)": {
  //         slides: { perView: 3, spacing: 1 },
  //       },
  //       "(min-width: 1300px)": {
  //         slides: { perView: "3", spacing: 0 },
  //       },
  //     },
  //   },
  //   [
  //     // add plugins here
  //   ]
  // );

  // return (
  //   <div ref={sliderRef} className="keen-slider">
  //     {wellnessCarousel.map((item, index) => {
  //       const isLarge = item.isLarge;
  //       const handleClick = () => {
  //         // Open the specified link in a new window
  //         window.open(item.link, "_blank");
  //       };

  //       return (
  //         <div>
  //           <div
  //             className={
  //               !isLarge
  //                 ? "keen-slider__slide headerSlide "
  //                 : "keen-slider__slide largeSlide "
  //             }
  //           >
  //             <div className="image-wrapper">
  //               <img src={item.imgURL} alt="Card image" onClick={handleClick} />
  //             </div>
  //           </div>
  //         </div>
  //       );
  //     })}
  //     {instanceRef.current && (
  //       <>
  //         <Arrow
  //           left
  //           onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
  //         />

  //         <Arrow
  //           onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
  //         />
  //       </>
  //     )}
  //   </div>
  // );
};

export default BannersCarousel;
