import { db } from "../../firebase/firebase-config";

export const getBannersImgs = async () => {
  try {
    const banners = [];
    const data = await db
      .collection("banners")
      .doc("wellness")
      .collection("wellnessBanners")
      .where("showOnWeb", "==", true)
      .orderBy("orderNumber", "asc") // Add orderBy for the orderNumber field
      .get();

    data.docs.forEach((doc) => {
      banners.push({ ...doc.data(), id: doc.id });
    });

    return banners;
  } catch (error) {
    console.log(error);
  }
};

export const getCarouselImgs = async () => {
  try {
    const carousel = [];
    const data = await db
      .collection("blogs")
      .where("showOnWeb", "==", true)
      .orderBy("orderNumber", "asc")
      .get();

    data.docs.forEach((doc) => {
      carousel.push({ ...doc.data(), id: doc.id });
    });

    return carousel;
  } catch (error) {
    console.log(error);
  }
};
