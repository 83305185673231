import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
  useTheme,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Divider,
} from "@mui/material";
import { styles } from "../../styles/styles";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import { ASbutton } from "../../components/buttons/ASbutton";
import { MdNotificationImportant } from "react-icons/md";

import { Provinces as provinces } from "../../utils/provinces";
import { Cantons as cantons } from "../../utils/cantons";
import { Districts as districts } from "../../utils/districts";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFormik } from "formik";
import * as Yup from "yup";

import imgDrop from "../../assests/allSports-logo.png";
import UploadImage from "../general/UploadImage";
import dayjs from "dayjs";
import { fsTimestampCreador } from "../../firebase/firebase-config";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { editUser } from "../../store/actions/user";
import { useDispatch, useSelector } from "react-redux";
import { getAllPlans } from "../../store/actions/plans";
import { useNavigate } from "react-router-dom";
import { handleWhatsapp } from "../../utils/Links";

const MySwal = withReactContent(Swal);

const Billing = ({}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const { userInfo } = useSelector((state) => state.user);
  const plans = useSelector((state) => state.plans.plans);
  const { supportPhone } = useSelector((state) => state.auth);
  const [apportionmentPrice, setApportionmentPrice] = useState(0);
  const [priceNoIVA, setPriceNoIva] = useState(0);
  const [priceToCharge, setPriceToCharge] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState("");

  useEffect(() => {
    if (userInfo) {
      setSelectedPlan(userInfo.userPlan);
      setLoading(false);
    }
  }, [userInfo]);

  useEffect(() => {
    const overallDate = new Date();
    const year = overallDate.getFullYear();
    const month = overallDate.getMonth() + 1;
    const date = overallDate.getDate();

    //Days in the month
    const apportionmentDays = new Date(year, month, 0).getDate();
    //Days left in the month
    const apportionment = apportionmentDays - date + 1;
    //SET Price without IVA
    setPriceNoIva(selectedPlan?.finalPrice - selectedPlan?.finalPrice * 0.13);

    setApportionmentPrice((priceNoIVA / apportionmentDays) * apportionment);

    setPriceToCharge(
      (apportionmentPrice + apportionmentPrice * 0.13).toFixed(2)
    );
  }, [apportionmentPrice, selectedPlan, priceNoIVA]);
  const navigateTo = (route) => {
    navigate(route);
  };

  const renderList = () => {
    return selectedPlan.data.map((data, index) => (
      <Grid container item xs={12} key={index}>
        <Grid item xs={8}>
          <Typography
            textAlign={"left"}
            variant="body1"
            sx={{ fontSize: "0.8rem !important" }}
            key={data.id}
          >
            {data.description}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" key={data.id} textAlign={"right"}>
            {data.value}
          </Typography>
        </Grid>
      </Grid>
    ));
  };

  if (loading) {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "450px",
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }
  if (!selectedPlan) {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "450px",
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box sx={styles.formContainer}>
      <Box>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Mi Plan
        </Typography>
      </Box>
      <Box mt={2} mb={4}>
        <Typography variant="body1" sx={{ fontWeight: "400" }}>
          Actualmente tu Plan Premuim disfrutas de coberturas y asistencias
          médicas 24/7, canjes y muchos más beneficios para tu bienestar
          integral.
        </Typography>
      </Box>
      <Grid container columnGap={2} justifyContent={"flex-start"}>
        <Grid item lg={4} md={5} container mb={3} sx={styles.summaryCard}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",

              flexDirection: "column",
            }}
          >
            <Typography variant="h6">{selectedPlan?.subTier}</Typography>
            <Typography variant="body1">Código: {selectedPlan.code}</Typography>

            <Grid
              container
              sx={{
                margin: "0 auto",
                mt: 2,

                pt: 1,
                pb: 1,
              }}
            >
              <Box mb={1}>
                <Typography variant="body1">Detalles del Plan</Typography>
              </Box>
              {renderList()}
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={4} md={5} container mb={3} sx={styles.summaryCard}>
          <Typography variant="h6" sx={{ fontSize: "16", fontWeight: "bold" }}>
            Resumen de Compra
          </Typography>
          <Grid container item xs={12}>
            <Grid item xs={8}>
              <Typography
                textAlign={"left"}
                variant="body1"
                sx={{ fontSize: "0.8rem !important" }}
              >
                Plan
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" textAlign={"right"}>
                {selectedPlan?.subTier}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={8}>
              <Typography
                textAlign={"left"}
                variant="body1"
                sx={{ fontSize: "0.8rem !important" }}
              >
                Monto
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" textAlign={"right"}>
                {priceNoIVA ? priceNoIVA : 0}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={8}>
              <Typography
                textAlign={"left"}
                variant="body1"
                sx={{ fontSize: "0.8rem !important" }}
              >
                Iva
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" textAlign={"right"}>
                {selectedPlan?.finalPrice * 0.13}
              </Typography>
            </Grid>
          </Grid>

          <Divider component="span" sx={{ width: "100%", mb: 2 }} />
          <Grid container item xs={12}>
            <Grid item xs={8}>
              <Typography
                textAlign={"left"}
                variant="body1"
                sx={{ fontSize: "0.8rem !important", fontWeight: "bold" }}
              >
                Total
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" textAlign={"right"}>
                {priceToCharge}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            mt: 2,
          }}
        >
          El pago de tu plan {selectedPlan?.subTier} vence{" "}
          {new Date().getDate()}/{new Date().getMonth() + 1}/
          {new Date().getFullYear()} .
        </Typography>
        <Typography variant="body2" sx={{}}>
          Al comprar este Plan Premium autorizas su recurrencia mensual cada
          primer día del mes en curso por un total de {selectedPlan?.finalPrice}{" "}
          I.V.A.I. por mes. Para más detalles sobre las condiciones de la compra
          consulta{" "}
          <a href="allsports.plus" style={{ color: "black" }}>
            aquí.
          </a>
        </Typography>
      </Box>
      <Grid
        container
        columnGap={0}
        justifyContent={"space-between"}
        alignItems={"center"}
        mt={3}
      >
        <Grid item md={6} container mb={3}>
          <Typography
            component={"a"}
            sx={{ color: theme.palette.primary.main }}
            onClick={() => handleWhatsapp(supportPhone)}
          >
            ¿Necesitas ayuda?
          </Typography>
        </Grid>
        {/* <Grid item md={6} container mb={3} justifyContent={"flex-end"}>
          <ASbutton
            buttonText={"Mejorar Plan"}
            onClick={() => navigateTo("/profile/plans")}
          />
        </Grid> */}
      </Grid>
    </Box>
  );
};
export default Billing;
