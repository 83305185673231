import React, { useEffect, useState } from "react";
import { Typography, Box, Button, Grid, Avatar, useTheme } from "@mui/material";
import { styles } from "../../styles/styles";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { addView } from "../../store/actions/blogs";

const BlogItem = ({ item, handleTagClick, handleOpenLoginModal }) => {
  const navigate = useNavigate();
  const { loggedIn, user } = useSelector((state) => state.auth);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [videoSource, setVideoSource] = useState("");
  const theme = useTheme();
  const firstParagraph = item.content.find(
    (paragraph) => typeof paragraph === "string"
  );
  const trimmedContent =
    firstParagraph &&
    firstParagraph.substring(0, 100) +
      (firstParagraph.length > 50 ? "..." : "");

  useEffect(() => {
    if (item.postType === "video") {
      // Determine video source (Vimeo or YouTube)
      const source = getVideoSource(item.videoURL);
      setVideoSource(source);
      // Fetch thumbnail based on video source
      if (source === "vimeo") {
        fetchVimeoThumbnail(item.videoURL);
      } else if (source === "youtube") {
        fetchYouTubeThumbnail(item.videoURL);
      }
    }
  }, [item.postType, item.videoUrl]);

  const handleViewMore = async () => {
    if (loggedIn) {
      try {
        const result = await addView(item.parentId, item.id, {
          userId: user?.uid,
        }); // Modify the parameters according to your context
        if (result.viewAdded) {
          navigate(`/blogs/${item.category}/posts/${item.id}`);
        } else {
          navigate(`/blogs/${item.category}/posts/${item.id}`);
        }
      } catch (error) {}
    } else {
      handleOpenLoginModal(true);
    }
  };

  const getVideoSource = (videoUrl) => {
    if (videoUrl.includes("vimeo.com")) {
      return "vimeo";
    } else if (
      videoUrl.includes("youtube.com") ||
      videoUrl.includes("youtu.be")
    ) {
      return "youtube";
    } else {
      return null; // Neither Vimeo nor YouTube
    }
  };

  const fetchVimeoThumbnail = async (videoURL) => {
    try {
      function getIdFromVimeoURL(videoURL) {
        return /http(s)?:\/\/(www\.)?vimeo.com\/(\d+)(\/)?(#.*)?/.exec(
          videoURL
        )[3];
      }

      const videoId = getIdFromVimeoURL(videoURL);
      const response = await fetch(
        `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${videoId}`
      );
      const data = await response.json();
      return data && data.thumbnail_url ? data.thumbnail_url : null;
    } catch (error) {
      console.error("Error fetching Vimeo thumbnail:", error);
      return null;
    }
  };

  const fetchYouTubeThumbnail = async (videoURL) => {
    try {
      const videoId = videoURL.substring(
        videoURL.lastIndexOf("v=") + 2,
        videoURL.indexOf("&") !== -1 ? videoURL.lastIndexOf("&") : undefined
      );
      const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;
      setThumbnailUrl(thumbnailUrl);
    } catch (error) {
      console.error("Error fetching YouTube thumbnail:", error);
    }
  };

  return (
    <Grid sx={styles.blogItem} container>
      <Grid item md={12} container alignItems={"center"} gap={2}>
        <Grid item>
          <Avatar
            alt={item.sponsorName ? item.sponsorName : "sponsor"}
            src="https://picsum.photos/200/300"
            sx={{ width: 24, height: 24 }}
          />
        </Grid>
        <Grid item>
          <Typography variant="body" sx={styles.blogAuthor} mr={2}>
            {item.sponsorName ? item.sponsorName : "sponsor"}
          </Typography>
          <Typography variant="body" sx={styles.blogAuthor}>
            {item?.cdate && new Date(item.cdate.toDate()).toLocaleDateString()}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        md={8}
        container
        alignContent={"flex-start"}
        sx={{
          //height: 75,
          //maxHeight: 50,
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <Grid item md={12} my={2}>
          <Typography
            variant="h6"
            sx={[
              styles.blogName,
              {
                cursor: "pointer",
                ":hover": { color: theme.palette.primary.main },
              },
            ]}
            onClick={handleViewMore}
          >
            {item.name}
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          {trimmedContent && (
            <Typography
              sx={{
                ...styles.blogContent,
                cursor: "pointer",
                ":hover": { color: theme.palette.primary.main },
              }}
              onClick={handleViewMore}
            >
              {"\t"}
              {trimmedContent}
            </Typography>
          )}
        </Grid>
      </Grid>
      {item.postType === "image" && (
        <Grid item md={4} xs={12} container justifyContent={"center"}>
          <img
            src={item.imageURLs[0] || "https://picsum.photos/200/300"}
            alt="Preview"
            style={{
              maxWidth: "100%",
              maxHeight: "250px",
              margin: "15px 0",
              "@media (min-width: 900px)": {
                maxHeight: "125px",
              },
              borderRadius: "8px",
            }}
          />
        </Grid>
      )}
      {item.postType === "video" && (
        <Grid item md={4} xs={12} container justifyContent="center">
          <img
            src={thumbnailUrl || "https://picsum.photos/200/300"}
            alt="Video Thumbnail"
            style={{
              maxWidth: "100%",
              maxHeight: "250px",
              margin: "15px 0",
              "@media (min-width: 900px)": {
                maxHeight: "125px",
              },
              borderRadius: "8px",
            }}
          />
        </Grid>
      )}
      {firstParagraph && firstParagraph.length > 10 && (
        <Box display="flex" width="100%">
          <Button onClick={handleViewMore} sx={styles.viewMore}>
            Ver Más
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default BlogItem;
