import Form from "react-bootstrap/Form";
import { Button, Row, Col } from "react-bootstrap";

import PurchaseRaceProgress from "../PurchaseRaceProgress/PurchaseRaceProgress";

import { useFormik } from "formik";

import {} from "react-icons/fi";

import { CircularProgress } from "@mui/material";

import biker from "../../assests/login.png";

import { useDispatch, useSelector } from "react-redux";

import { login, loginWithGoogle } from "../../store/actions/auth";

import * as yup from "yup";

import "./formStyles.scss";
import { useState } from "react";

import { BsGoogle } from "react-icons/bs";
import theme from "../../styles/theme";

const SignInForm = ({ handleClose, setOpenSignUp, handleOpenLogIn }) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const { errorMsg } = useSelector((state) => state.auth);

  const steps = [{ label: "Paso 1" }, { label: "Paso 2" }, { label: "Paso 3" }];
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setloading] = useState(false);
  const {
    setFieldValue,
    handleChange,
    touched,
    errors,
    values,
    isValid,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Formato no válido")
        .required("Email requerido"),
      password: yup
        .string()
        .typeError("Formato no válido")
        .required("Contraseña requerida"),
    }),
    onSubmit: async (values) => {
      try {
        setloading(true);
        dispatch(login(values.email, values.password, handleClose, setloading));
        // Clear any previous error message on successful login
      } catch (error) {
        // Handle the unsuccessful login here
        console.error("Login failed:", error);
        // Set the error message here
      }
    },
  });

  // useEffect(() => {
  //   console.log(errorMsg);
  // }, [errorMsg]);

  return (
    <div
      style={{
        backgroundColor: "#f5f6ff",
        padding: "20px",
        borderRadius: "55px",
      }}
    >
      <Row>
        <Col xl="7">
          <PurchaseRaceProgress progressPorcentage={10} />
          <h4 className="fw-bold my-3">Iniciar Sesión</h4>
          <form>
            <Form.Group className="mb-3" controlId="groupEmail" as={Col} xl="6">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                onChange={handleChange}
                name="email"
                isInvalid={!!errors.email && touched.email}
                onBlur={handleBlur}
                value={values.email}
              />

              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="groupPassword"
              as={Col}
              xl="6"
            >
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                placeholder="Contraseña"
                onChange={handleChange}
                name="password"
                isInvalid={!!errors.password && touched.password}
                onBlur={handleBlur}
                value={values.password}
              />

              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Button
                variant="link"
                onClick={() => {
                  setOpenSignUp(true);
                  handleOpenLogIn(false);
                }}
                style={{ color: "#168FCA", paddingLeft: 0 }}
              >
                Registrese aquí
              </Button>
            </Form.Group>

            <Row className="mt-4">
              <Col md="6" sm="12" className="mb-3">
                <Button
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    width: "100%",
                    padding: "10px 15px",
                    borderRadius: "15px",
                  }}
                  disabled={!isValid}
                  onClick={() => {
                    handleSubmit();
                  }}
                  variant="secondary"
                >
                  {loading ? (
                    <CircularProgress sx={{ maxHeight: 15, color: "white" }} />
                  ) : (
                    "Iniciar Sesión"
                  )}
                </Button>
                {errorMsg && ( // Display error message if it exists
                  <div className="text-danger mt-4" style={{ color: "red" }}>
                    {errorMsg}
                  </div>
                )}
              </Col>
              <Col md="6" sm="12">
                <Button
                  style={{
                    background: theme.palette.primary.main, //"#4285f4",
                    width: "100%",
                    color: "#fff",
                    padding: "10px 15px",
                    borderRadius: "15px",
                  }}
                  onClick={() => {
                    dispatch(loginWithGoogle(handleClose));
                  }}
                >
                  <span className="me-2">
                    <BsGoogle />
                  </span>
                  Iniciar con Google
                </Button>
              </Col>
            </Row>
            {/* </Stack> */}
          </form>
        </Col>
        <Col>
          <div
            className="position-absolute  d-none d-xl-block"
            style={{ width: "35%", top: "20px", right: "20px" }}
          >
            <img
              src={biker}
              alt=""
              style={{ borderRadius: "55px", width: "100%" }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SignInForm;
