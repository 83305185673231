import BillingForm from "../forms/BillingForm";
import { Col, Modal, Row } from "react-bootstrap";

import "./modal.scss";
import { useState } from "react";
import SelectedPlan from "../selectedPlan/SelectedPlan";
import PlanDescription from "../selectedPlan/PlanDescription";
import SelectedPlanAccordion from "../selectedPlanAccordion/SelectedPlanAccordion";
import ResponsiveAccordion from "../selectedPlanAccordion/responsive/ResponsiveAccordion";
import { useMediaQuery } from "@mui/material";

const SelectedPlanModal = ({ show, handleClose, plan, setOpenPayPlan }) => {
  const isDown1200 = useMediaQuery("(max-width:1200px)");
  return (
    <Modal
      centered
      show={show}
      onHide={() => {
        //formik.resetForm()
        handleClose(false);
      }}
      keyboard={false}
      style={{ zIndex: 1500, backgroundColor: "transparent" }}
      backdropClassName="backdrop-on-top"
      dialogClassName="selected-plan-dialog"
      contentClassName="selected-plan-content bg-transparent border-0"
    >
      <Modal.Body>
        {isDown1200 ? (
          <ResponsiveAccordion
            plan={plan}
            handleClose={handleClose}
            setOpenPayPlan={setOpenPayPlan}
          />
        ) : (
          <SelectedPlanAccordion
            plan={plan}
            plan65={plan.isElderPlan}
            handleClose={handleClose}
            setOpenPayPlan={setOpenPayPlan}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SelectedPlanModal;
