export const Cantons = [
  {
    provinceId: 1,
    cantonId: 101,
    label: "San José",
    value: 101,
    provinceName: "San José",
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 102,
    label: "Escazú",
    value: 102,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 103,
    label: "Desamparados",
    value: 103,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 104,
    label: "Puriscal",
    value: 104,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 105,
    label: "Tarrazú",
    value: 105,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 106,
    label: "Aserrí",
    value: 106,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 107,
    label: "Mora",
    value: 107,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 108,
    label: "Goicoechea",
    value: 108,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 109,
    label: "Santa Ana",
    value: 109,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 110,
    label: "Alajuelita",
    value: 110,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 111,
    label: "Vázquez de Coronado",
    value: 111,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 112,
    label: "Acosta",
    value: 112,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 113,
    label: "Tibás",
    value: 113,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 114,
    label: "Moravia",
    value: 114,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 115,
    label: "Montes de Oca",
    value: 115,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 116,
    label: "Turrubares",
    value: 116,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 117,
    label: "Dota",
    value: 117,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 118,
    label: "Curridabat",
    value: 118,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 119,
    label: "Pérez Zeledón",
    value: 119,
  },
  {
    provinceId: 1,
    provinceName: "San José",
    cantonId: 120,
    label: "León Cortés Castro",
    value: 120,
  },
  {
    provinceId: 2,
    cantonId: 201,
    label: "Alajuela",
    value: 201,
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 202,
    label: "San Ramón",
    value: 202,
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 203,
    label: "Grecia",
    value: 203,
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 204,
    label: "San Mateo",
    value: 204,
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 205,
    label: "Atenas",
    value: 205,
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 206,
    label: "Naranjo",
    value: 206,
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 207,
    label: "Palmares",
    value: 207,
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 208,
    label: "Poás",
    value: 208,
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 209,
    label: "Orotina",
    value: 209,
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 210,
    label: "San Carlos",
    value: 210,
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 211,
    label: "Zarcero",
    value: 211,
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 212,
    label: "Valverde Vega",
    value: 212,
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 213,
    label: "Upala",
    value: 213,
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 214,
    label: "Los Chiles",
    value: 214,
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 215,
    label: "Guatuso",
    value: 215,
    provinceName: "Alajuela",
  },
  {
    provinceId: 3,
    cantonId: 301,
    label: "Cartago",
    value: 301,
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 302,
    label: "Paraíso",
    value: 302,
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 303,
    label: "La Unión",
    value: 303,
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 304,
    label: "Jiménez",
    value: 304,
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 305,
    label: "Turrialba",
    value: 305,
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 306,
    label: "Alvarado",
    value: 306,
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 307,
    label: "Oreamuno",
    value: 307,
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 308,
    label: "El Guarco",
    value: 308,
    provinceName: "Cartago",
  },
  {
    provinceId: 4,
    cantonId: 401,
    label: "Heredia",
    value: 401,
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 402,
    label: "Barva",
    value: 402,
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 403,
    label: "Santo Domingo",
    value: 403,
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 404,
    label: "Santa Bárbara",
    value: 404,
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 405,
    label: "San Rafael",
    value: 405,
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 406,
    label: "San Isidro",
    value: 406,
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 407,
    label: "Belén",
    value: 407,
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 408,
    label: "Flores",
    value: 408,
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 409,
    label: "San Pablo",
    value: 409,
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 410,
    label: "Sarapiquí",
    value: 410,
    provinceName: "Heredia",
  },
  {
    provinceId: 5,
    cantonId: 501,
    label: "Liberia",
    value: 501,
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 502,
    label: "Nicoya",
    value: 502,
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 503,
    label: "Santa Cruz",
    value: 503,
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 504,
    label: "Bagaces",
    value: 504,
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 505,
    label: "Carrillo",
    value: 505,
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 506,
    label: "Cañas",
    value: 506,
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 507,
    label: "Abangares",
    value: 507,
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 508,
    label: "Tilarán",
    value: 508,
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 509,
    label: "Nandayure",
    value: 509,
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 510,
    label: "La Cruz",
    value: 510,
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 511,
    label: "Hojancha",
    value: 511,
    provinceName: "Guanacaste",
  },
  {
    provinceId: 6,
    cantonId: 601,
    label: "Puntarenas",
    value: 601,
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 602,
    label: "Esparza",
    value: 602,
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 603,
    label: "Buenos Aires",
    value: 603,
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 604,
    label: "Montes de Oro",
    value: 604,
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 605,
    label: "Osa",
    value: 605,
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 606,
    label: "Quepos",
    value: 606,
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 607,
    label: "Golfito",
    value: 607,
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 608,
    label: "Coto Brus",
    value: 608,
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 609,
    label: "Parrita",
    value: 609,
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 610,
    label: "Corredores",
    value: 610,
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 611,
    label: "Garabito",
    value: 611,
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 612,
    label: "Monte Verde",
    value: 612,
    provinceName: "Puntarenas",
  },
  {
    provinceId: 7,
    cantonId: 701,
    label: "Limón",
    value: 701,
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 702,
    label: "Pococí",
    value: 702,
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 703,
    label: "Siquirres",
    value: 703,
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 704,
    label: "Talamanca",
    value: 704,
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 705,
    label: "Matina",
    value: 705,
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 706,
    label: "Guácimo",
    value: 706,
    provinceName: "Limón",
  },
];
