import MedicalAssistance from "./MedicalAssistance";
import MedicalInsurance from "./MedicalInsurance";
import ExchangesPromotions from "./ExchangesPromotions";
import { useState } from "react";

import "./selectedPlanAccordion.scss";
import { Col, Container, Row } from "react-bootstrap";
import SelectedPlan from "../selectedPlan/SelectedPlan";
import { useMediaQuery } from "@mui/material";

const SelectedPlanAccordion = ({
  plan,
  plan65,
  handleClose,
  setOpenPayPlan,
}) => {
  const [activeTab, setActiveTab] = useState(null);

  const isDown1300 = useMediaQuery("(max-width:1300px)");

  const handleTabClick = (index) => {
    setActiveTab((prevActiveTab) => (prevActiveTab === index ? null : index));
  };

  const accordionData = [
    <MedicalAssistance plan65={plan65} productData={plan} />,
    <MedicalInsurance plan65={plan65} productData={plan} />,
    <ExchangesPromotions plan65={plan65} productData={plan} />,
  ];

  const getCurrentName = (index) => {
    if (index === 0) {
      return <p style={{ width: "200px", marginBottom: 0 }}>Seguro Medico</p>;
    } else if (index === 1) {
      return (
        <p style={{ width: "200px", marginBottom: 0 }}>Asistencia Medica</p>
      );
    } else {
      return (
        <p style={{ width: "200px", marginBottom: 0 }}>Canjes y Promociones</p>
      );
    }
  };

  return (
    <Container
      style={{ display: "flex", flexWrap: "nowrap", justifyContent: "right" }}
    >
      <SelectedPlan
        plan={plan}
        agedPlan={false}
        handleClose={handleClose}
        setOpenPayPlan={setOpenPayPlan}
      />
      <div className="accordion" style={{ position: "relative" }}>
        {accordionData.map((tab, index) => (
          <div
            style={
              index === 0
                ? {
                    backgroundColor: "#7265e3",
                    borderRadius: "0 55px 55px 0",
                    padding: "15px",
                    position: "relative",
                    left: -65,
                    zIndex: 300,
                    boxShadow: "9px 4px 34px 0px rgba(0, 0, 0, 0.25)",
                  }
                : index === 1
                ? {
                    backgroundColor: "#9488fb",
                    borderRadius: "0 55px 55px 0",
                    padding: "15px",
                    position: "relative",
                    left: -145,
                    zIndex: 200,
                    boxShadow: "8px 4px 27px 0px rgba(0, 0, 0, 0.25)",
                  }
                : {
                    backgroundColor: "#ada4ff",
                    borderRadius: "0 55px 55px 0",
                    padding: "15px",
                    position: "relative",
                    left: -225,
                    zIndex: 100,
                    boxShadow: "9px 4px 34px 0px rgba(0, 0, 0, 0.25)",
                  }
            }
            className={`tab d-flex align-items-center ${
              activeTab === index ? "active" : ""
            }`}
            key={index}
            onClick={() => handleTabClick(index)}
          >
            <div
              style={isDown1300 ? { width: "100px" } : { width: "225px" }}
            ></div>
            <div
              className={`text-white text-center ${
                activeTab === index ? "d-none" : ""
              }`}
              style={{
                transform: "rotate(-90deg)",
                width: "300px, marginBottom: 0",
              }}
            >
              {getCurrentName(index)}
            </div>
            {activeTab === index && (
              <>
                {tab}
                {/* <img src={tab.imageSrc} alt={`Image ${index + 1}`} />
              <p>{tab.text}</p> */}
              </>
            )}
          </div>
        ))}
      </div>
    </Container>
  );
};

export default SelectedPlanAccordion;
