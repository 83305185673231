import React, { useEffect } from "react";
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { styles } from "../styles/styles";
import apple from "../assests/table.png";
export const RulesPage = () => {
  const theme = useTheme();
  const navigateTo = (link) => {
    window.open(link, "_blank"); // "_blank" opens in a new window/tab
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, []);
  return (
    <Box>
      <Container maxWidth="lg">
        <Box
          sx={{
            width: "100%",
            minHeight: "75vh",
            [theme.breakpoints.down("md")]: {
              justifyContent: "center",
              minHeight: 0,
            },
          }}
          mt={20}
          mb={10}
        >
          <Grid container sx={styles.titleGridContainer}>
            <Box sx={styles.titleCard}>
              <div className="titleIcon">
                <Typography mr={"auto"} ml={2} sx={styles.boldTextLight}>
                  Contratos y reglamentos​
                </Typography>
              </div>
            </Box>
          </Grid>
          <Box mt={10} mb={2} id="section1">
            <Typography mr={"auto"} sx={[styles.boldTextTerms]}>
              Promociones vigentes:
            </Typography>
          </Box>

          <Box mt={2} mb={2} sx={{ display: "flex", flexDirection: "column" }}>
            <li>
              <a
                target="blank"
                onClick={() =>
                  navigateTo(
                    "https://firebasestorage.googleapis.com/v0/b/allsports-69231.appspot.com/o/landing%2FReglamento%20-%20Promocio%CC%81n%20Gana%202%20entradas%20al%20cine%20gratis%20-%20ASP-R0023%20(1).pdf?alt=media&token=2c36c0e5-712f-4349-b7bf-7256ad8eb890"
                  )
                }
              >
                Reglamento "Gana 1 entrada doble al cine"
              </a>
            </li>
            <li>
              <a component={"a"}>Reglamento "Actualiza y gana 200 Coins"</a>
            </li>
            <li>
              <a component={"a"}>Reglamento "Regístrate y gana 5000 Coins"</a>
            </li>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
