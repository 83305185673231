import { Stack } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";

import start from "../../assests/start.svg";
import checkpoint from "../../assests/checkpoint.svg";
import finish from "../../assests/finish.svg";

const PurchaseRaceProgress = ({ progressPorcentage }) => {
  return (
    <div className="position-relative">
      <Stack
        direction="horizontal"
        style={{
          justifyContent: "space-between",
          position: "relative",
          zIndex: 100,
        }}
      >
        <img src={start} alt="" />
        <img src={checkpoint} alt="" />
        <img src={finish} alt="" />
      </Stack>
      <div style={{ width: "99%", position: "absolute", bottom: "10px" }}>
        <ProgressBar
          color="#e0e0e0"
          now={progressPorcentage}
          variant="secondary"
        />
      </div>
    </div>
  );
};

export default PurchaseRaceProgress;
