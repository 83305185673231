import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

import { styles } from "../../styles/styles";
import Plus from "../../assests/svg/Plus";

const PromoCard = ({ theme, item }) => {
  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems={"center"}
      flexDirection={"column"}
      mt={10}
      sx={[styles.promoCard, { backgroundColor: item.bgColor }]}
    >
      <Grid item container justifyContent={"center"} xl={12}>
        <Box
          component="img"
          sx={{
            height: "auto",
            width: "225px",
          }}
          alt="feature"
          src={item.image}
        />
        <Box container sx={styles.innerCard}>
          <Box mt={2}>
            <Typography
              color={theme.palette.dark.main}
              variant="body1"
              sx={{ fontSize: 12 }}
            >
              {item.text}
            </Typography>
          </Box>
          <Box mt={2} sx={{ width: "75%" }}>
            <Typography variant="h4" sx={{ fontSize: 20, fontWeight: "bold" }}>
              {item.title}
            </Typography>
          </Box>
          <List sx={{ mt: 2 }}>
            {item.list.map((item) => (
              <ListItem key={item.id} sx={{ pl: 0, pr: 0, mb: 1 }}>
                <Box mr={1}>
                  <Plus />
                </Box>
                <ListItemText>
                  <Typography
                    color={theme.palette.dark.main}
                    variant="body1"
                    sx={{ fontSize: 12 }}
                  >
                    {item}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PromoCard;
