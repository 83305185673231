import {
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Switch,
  useTheme,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";

import { useDispatch, useSelector } from "react-redux";
import { styles } from "../../styles/styles";
import { Typography } from "@mui/material";
import hacker from "../../assests/hacker.png";
import { Md10K } from "react-icons/md";

const Settings = () => {
  const theme = useTheme();

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: green[600],
      "&:hover": {
        backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: green[600],
    },
  }));

  const settings = [
    {
      title: "Notificaciones en el Desktop",
      description: "Recibir en la pantalla las notificaciones",
    },
    {
      title: "Notificaciones en el Email",
      description: "Recibir notificaciones en el Email",
    },
  ];
  const renderSettings = () => {
    return (
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {settings.map((option, index) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "60%",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "18px",
              borderWidth: "1px !important",
              border: "solid",
              borderColor: "#ECECEE",
              mb: 2,
              [theme.breakpoints.down("lg")]: {
                width: "100%",
              },
            }}
          >
            <ListItem>
              <ListItemText
                sx={{ cursor: "pointer" }}
                primary={option.title}
                secondary={option.description}
              />
            </ListItem>
            <GreenSwitch />
          </Box>
        ))}
      </List>
    );
  };

  return (
    <Container maxWidth={"xxl"}>
      <Grid container mt={4}>
        <Grid item lg={10} md={12} sm={12}>
          {renderSettings()}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Settings;
