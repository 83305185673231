import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  IconButton,
  Box,
  ListItem,
  List,
  useTheme,
} from "@mui/material";
import { FiMoreHorizontal } from "react-icons/fi";
import { BsFillHeartFill } from "react-icons/bs";

import afSegurosLogo from "../../assests/afSegurosLogo.png";

import DialogWithSteps from "../modals/PurchaseInsurance";
import SelectedPlanModal from "../modals/SelectedPlanModal";
import SignInModal from "../modals/SignInModal";
import RegisterModal from "../modals/RegisterModal";
import { useSelector } from "react-redux";

const PlanCard = ({ plan, handleShowLogIn, index, agedPlan }) => {
  const theme = useTheme();
  const { userInfo } = useSelector((state) => state.user);
  const { supportPhone } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [openPayPlan, setOpenPayPlan] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleOpenLogIn = (val) => setShow(val);
  const whatsappLink = `https://wa.me/${supportPhone}`;
  const user = useSelector((state) => state.auth.user);

  const applyToPlan = () => {
    if (true) {
      setOpen(true);
    } else {
      handleShowLogIn(true);
    }
  };

  useEffect(() => {
    if (userInfo) {
      const isUserSubTierMatching =
        userInfo.planType.subTier == plan.subTier &&
        userInfo.planType.subTier !== null;
      setDisabled(isUserSubTierMatching);
      // console.log(userInfo.planType.subTier, plan.subTier);
    }
  }, [userInfo]);

  return (
    <Card
      sx={{
        boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px;",
        minHeight: "600px",
        width: "300px",
        borderRadius: "18px",
        mb: 3,
        mr: 1,
        ml: 1,
        "&:hover": {
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",

          "& .apply-button": {
            backgroundColor: disabled ? "grey" : theme.palette.primary.main,
            color: theme.palette.primary.secondary,
          },
        },
      }}
    >
      <CardContent sx={{ pt: 0, pb: 0 }}>
        <IconButton
          className="ms-auto"
          sx={{ visibility: "hidden", cursor: "pointer" }}
        >
          <FiMoreHorizontal />
        </IconButton>
      </CardContent>
      <CardContent className="d-flex flex-column " sx={{ pt: 0 }}>
        <Typography
          variant="h4"
          align="center"
          paragraph
          sx={{ fontSize: 38, mb: 0, fontWeight: "500" }}
        >
          {plan.tier}
        </Typography>
        <Typography
          variant="h4"
          align="center"
          paragraph
          sx={{ fontSize: 38, fontWeight: "800" }}
        >
          {plan.subTier}
        </Typography>

        <CardContent sx={{ p: 0 }}>
          {plan.data.map((datum, index) => (
            <List key={index}>
              <ListItem sx={{ p: 0 }}>
                <Typography variant="body1" fontSize={14} sx={{ width: "50%" }}>
                  {datum.description}
                </Typography>
                <Typography
                  variant="body1"
                  fontSize={14}
                  sx={{ marginLeft: "auto" }}
                >
                  {datum.value}
                </Typography>
              </ListItem>
            </List>
          ))}
        </CardContent>

        <div>
          <div className="w-50 mx-auto my-3">
            <img
              src={afSegurosLogo}
              alt="af seguros logo"
              className="d-block mx-auto logoimg"
            />
          </div>
          {!agedPlan ? (
            <Typography
              variant="body1"
              align="center"
              sx={{
                cursor: "auto",
                color: "#8778e4",
              }}
              onClick={() => window.open(whatsappLink, "_blank")}
            >
              {`₡${plan.finalPrice} por mes`}
            </Typography>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                component="a"
                align="center"
                sx={{
                  cursor: "pointer !important",
                  color: "#8778e4",
                }}
                onClick={() => window.open(whatsappLink, "_blank")}
              >
                Consulta aquí
              </Typography>
            </Box>
          )}

          <CardContent sx={{ position: "relative" }}>
            {/* {index === 1 ? (
              <BsFillHeartFill
                size={"15px"}
                sx={{
                  position: "absolute",
                  background: "#ff5353",
                  top: 0,
                  left: "20px",
                  borderRadius: "50%",
                  color: "white",
                  py: 2,
                  px: 3,
                }}
              />
            ) : null} */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Button
                variant={"contained"}
                //className="apply-button" // Add a class to the button
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  width: "100%",
                  mx: "auto",
                  //color: theme.palette.primary.main,
                  width: "100%",
                  borderRadius: "42px",
                  pt: 1.5,
                  pb: 1.5,
                  mx: "auto",
                  mt: 1,
                  "&:hover": {
                    // Define the styles for the button on hover here
                    // For example, you can change the background color or text color
                    backgroundColor: theme.palette.secondary.main,
                    //color: "#EFF2FB",
                    //opacity: 0.9,
                  },
                }}
                disabled={disabled} // Set the disabled prop here
                onClick={!agedPlan ? applyToPlan : () => {}}
              >
                {!agedPlan ? (
                  <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Aplicar Plan
                  </Typography>
                ) : (
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "600" }}
                    onClick={() => window.open(whatsappLink, "_blank")}
                  >
                    Aplicar Plan
                  </Typography>
                )}
              </Button>
            </Box>
          </CardContent>
        </div>
      </CardContent>

      <DialogWithSteps
        open={openPayPlan}
        setOpen={setOpenPayPlan}
        plan={plan}
        openLogIn={handleOpenLogIn}
      />
      <SelectedPlanModal
        show={open}
        handleClose={() => setOpen(false)}
        plan={plan}
        setOpenPayPlan={setOpenPayPlan}
      />

      <RegisterModal
        open={openSignUp}
        setOpen={setOpenSignUp}
        openLogIn={setShow}
      />

      <SignInModal
        handleClose={handleOpenLogIn}
        show={show}
        setOpenSignUp={setOpenSignUp}
      />
    </Card>
  );
};

export default PlanCard;
