import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  useTheme,
  CircularProgress,
  Chip,
  Pagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  getBlogPosts,
  getBlogsCategories,
  getTagPosts,
  setBlogs,
} from "../store/actions/blogs";
import { useDispatch, useSelector } from "react-redux";
import { ASbutton } from "../components/buttons/ASbutton";
import BlogItem from "../components/blogs/BlogItem";
import { styles } from "../styles/styles";
import SignInModal from "../components/modals/SignInModal";

const BlogsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { blogs, postsByCategory } = useSelector((state) => state.blogs);
  const [tagMode, setTagMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedTag, setSelectedTag] = useState();
  const [tagPosts, setTagPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);

  const postsPerPage = 5;
  const getBlogsFromFB = useCallback(async () => {
    const blogsDB = await getBlogsCategories();
    dispatch(setBlogs(blogsDB));
  }, [dispatch]);

  const getPostsForCategory = useCallback(
    async (category) => {
      try {
        await dispatch(getBlogPosts(category)); // Dispatch the action to fetch posts for the specified category
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );
  const handleOpenLoginModal = () => {
    setOpen(true);
  };
  const handleCloseLoginModal = () => {
    setOpen(false);
  };
  useEffect(() => {
    setIsLoading(true);
    getBlogsFromFB();
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (blogs) {
      setSelectedCategory("all");
      getPostsForCategory("all");
      setTimeout(() => {
        setIsLoading(false);
      }, 900);
    }
  }, [blogs, getPostsForCategory]);

  const handleCategoryClick = (category) => {
    setIsLoading(true);
    setTagMode(false);
    setSelectedTag();
    setTagPosts([]);
    setSelectedCategory(category);
    getPostsForCategory(category);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleTagClick = async (tag) => {
    setTagMode(true);
    setSelectedTag(tag);
    setIsLoading(true);
    const postFromTag = await getTagPosts(tag);
    setTagPosts(postFromTag);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const handleClearTag = () => {
    setSelectedTag(); // Clear selected tag
    setTagMode(false);
    setTagPosts([]);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Get the current posts based on pagination
  const getCurrentPosts = () => {
    const startIndex = (page - 1) * postsPerPage;
    const endIndex = startIndex + postsPerPage;
    return tagMode
      ? tagPosts.slice(startIndex, endIndex)
      : postsByCategory[selectedCategory]?.slice(startIndex, endIndex);
  };

  return (
    <Container maxWidth={"md"} py={4} sx={{ minHeight: "100vh" }}>
      <Grid
        container
        justifyContent={"center"}
        sx={{
          mt: 16,
          mb: 16,
          p: 2,
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          //border: "1px solid #CBCBCB",
          borderRadius: "18px",
        }}
      >
        <Grid
          container
          item
          md={12}
          alignContent={"flex-start"}
          p={4}
          sx={{ minHeight: "75vh" }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h3"
              gutterBottom
              sx={styles.blogsMainTitle}
              mb={6}
            >
              Blogs
            </Typography>

            <Grid container gap={2}>
              <Grid item>
                <ASbutton
                  outlined={selectedCategory !== "all"}
                  color="primary"
                  backgroundColor={
                    selectedCategory !== "all"
                      ? "grey"
                      : theme.palette.primary.main
                  }
                  buttonText="Todos"
                  onClick={() => handleCategoryClick("all")}
                />
              </Grid>

              {blogs?.map((blog) => (
                <Grid item key={blog.id}>
                  <ASbutton
                    outlined={selectedCategory !== blog.name}
                    color="primary"
                    backgroundColor={
                      selectedCategory !== blog.name
                        ? "grey"
                        : theme.palette.primary.main
                    }
                    buttonText={blog.name}
                    onClick={() => handleCategoryClick(blog.name)}
                  />
                </Grid>
              ))}
            </Grid>
            {selectedTag && (
              <Grid item mt={4} md={12}>
                <Chip label={selectedTag} onDelete={handleClearTag} />
              </Grid>
            )}
          </Grid>

          {isLoading ? (
            <Grid item xs={12} my={4}>
              <Grid
                container
                spacing={2}
                alignItems="flex-start"
                pt={8}
                justifyContent={"center"}
                sx={{ minHeight: "75vh" }}
              >
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <>
              {getCurrentPosts()?.map((post) => (
                <Grid item key={post.id} md={12} mt={8}>
                  <BlogItem
                    item={post}
                    handleTagClick={handleTagClick}
                    handleOpenLoginModal={handleOpenLoginModal}
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>
        <Grid
          container
          md={11}
          justifyContent={"flex-end"}
          alignContent={"center"}
          sx={{
            pt: 2,
          }}
        >
          <Pagination
            count={
              tagMode
                ? Math.ceil(tagPosts.length / postsPerPage)
                : Math.ceil(
                    postsByCategory[selectedCategory]?.length / postsPerPage
                  )
            }
            variant="outlined"
            shape="rounded"
            page={page}
            onChange={handlePageChange}
          />
        </Grid>
      </Grid>
      <SignInModal
        show={open}
        setOpenSignUp={handleOpenLoginModal}
        handleClose={handleCloseLoginModal}
      />
    </Container>
  );
};

export default BlogsPage;
