import { Dialog, Box, useMediaQuery } from "@mui/material";

import { BsGoogle } from "react-icons/bs";
import SignInForm from "../forms/SignInForm";

import "./modal.scss";

const SignInModal = ({ show, handleClose, setOpenSignUp }) => {
  const matches768 = useMediaQuery("(max-width:768px)");
  const matches1200 = useMediaQuery("(max-width:1200px)");

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      disableScrollLock
      PaperProps={{
        sx: matches768
          ? {
              overflowY: "scroll",
              "&::-webkit-scrollbar": { width: 0 }, // Chromium-based browsers
              scrollbarWidth: "none", // Firefox
              msOverflowStyle: "none", // IE and Edge
              bgcolor: "transparent",
              boxShadow: "none",
              borderRadius: "18px",
            }
          : {
              overflowY: "scroll",
              "&::-webkit-scrollbar": { width: 0 }, // Chromium-based browsers
              scrollbarWidth: "none", // Firefox
              msOverflowStyle: "none", // IE and Edge
              bgcolor: "transparent",
              boxShadow: "none",
              borderRadius: "18px",
              padding: "60px",
            },
      }}
      open={show}
      onClose={() => {
        handleClose();
      }}
    >
      <Box
        sx={
          matches1200
            ? {
                bgcolor: "#f5f6ff",
                borderRadius: "55px",
                width: "100%",
                height: "auto",
              }
            : {
                bgcolor: "#f5f6ff",
                padding: "2rem",
                borderRadius: "55px",
                width: "100%",
                height: "80vh",
                maxHeight: "758px",
              }
        }
      >
        <SignInForm
          handleClose={handleClose}
          setOpenSignUp={setOpenSignUp}
          handleOpenLogIn={handleClose}
        />
      </Box>
    </Dialog>
  );
};

export default SignInModal;
