import { Container } from "react-bootstrap";

import medicalAssistance from "../../assests/assistance.svg";
import { useEffect, useState } from "react";

const MedicalAssistance = ({ productData, plan65 }) => {
  const [item, setItem] = useState({ text: "", info: "" });

  useEffect(() => {
    if (productData && !plan65) {
      setItem({
        id: "2",
        info: "Hasta 3 consultas anuales (domicilio o consultorio) en médica general, fisioterapia y nutrición, ambulancia por accidente ,telemedicina, orientación telefónica ilimitada, apoyo hospitalario  y más.",
        text: "Asistencias Médicas",
      });
    } else {
      setItem({
        id: "2",
        info: "Incluye hasta 3 consultas anuales (domicilio o consultorio) en médica general, fisioterapia y nutrición, ambulancia por accidente,telemedicina, orientación telefónica ilimitada, apoyo hospitalario  y más.",
        text: "Asistencias Médicas",
      });
    }
  }, []);

  return (
    <Container>
      <img
        src={medicalAssistance}
        alt=""
        className="d-block mx-auto"
        width="200px"
      />
      <h4 className="fw-bold text-white">{item.text}</h4>
      <p className="text-white">{item.info}</p>
    </Container>
  );
};

export default MedicalAssistance;
