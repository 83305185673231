import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Container,
  Grid,
  Box,
  FormHelperText,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { deleteAccount } from "../utils/firebaseUtils";

const MySwal = withReactContent(Swal);

const DeletePage = () => {
  const validationSchema = yup.object().shape({
    name: yup.string().required("Campo obligatorio"),
    email: yup
      .string()
      .email("Introduce una dirección de correo electrónico válida")
      .required("Campo obligatorio"),
    reason: yup.string().required("Campo obligatorio"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      reason: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Show confirmation dialog
      const confirmResult = await MySwal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar cuenta",
        cancelButtonText: "Cancelar",
      });

      // If the user confirms, proceed with account deletion
      if (confirmResult.isConfirmed) {
        try {
          // Perform account deletion
          await deleteAccount({ ...values, cdate: new Date() });

          // Show success message
          MySwal.fire({
            title: "Éxito",
            text: "Solicitud enviada",
            icon: "success",
          });

          // Optionally, reset the form or navigate to another page
          formik.resetForm();
        } catch (error) {
          // Handle errors and show an error message
          MySwal.fire({
            title: "Error",
            text: "Hubo un error al eliminar la cuenta",
            icon: "error",
          });
        }
      }
    },
  });

  return (
    <Container maxWidth="lg">
      <Box
        component="form"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          minHeight: "100vh",
        }}
        onSubmit={formik.handleSubmit}
      >
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          mb={4}
          mt={10}
        >
          <Grid item md={10} mb={1} xs={11} sm={11}>
            <FormControl component="fieldset" sx={{ mt: 2, width: "100%" }}>
              <RadioGroup
                name="selectedAnswer"
                value={formik.values.selectedAnswer}
                onChange={formik.handleChange}
              >
                {/* ... radio options ... */}
              </RadioGroup>
              {formik.touched.selectedAnswer &&
                formik.errors.selectedAnswer && (
                  <FormHelperText error>
                    {formik.errors.selectedAnswer}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid container item justifyContent="center" alignItems="center" my={4}>
          <Grid
            container
            item
            md={10}
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              margin="dense"
              variant="outlined"
              fullWidth
              id="name"
              name="name"
              label="Nombre"
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />

            <TextField
              margin="dense"
              variant="outlined"
              fullWidth
              id="email"
              name="email"
              label="Correo Electrónico"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <TextField
              margin="dense"
              variant="outlined"
              fullWidth
              id="reason"
              name="reason"
              label="Razón"
              value={formik.values.reason}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.reason && Boolean(formik.errors.reason)}
              helperText={formik.touched.reason && formik.errors.reason}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!formik.isValid}
              sx={{ mt: 5 }}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default DeletePage;
