import React, { useEffect } from "react";
import { Grid, IconButton, Badge, Typography, useTheme } from "@mui/material";
import {
  CommentOutlined,
  Favorite,
  FavoriteBorderOutlined,
} from "@mui/icons-material";
import { styles } from "../../styles/styles";
import { MdRemoveRedEye, MdWatch } from "react-icons/md";

export const ActionBar = ({
  likes,
  comments,
  userLiked,
  views,
  handleShowComments,
  handleLikePost,
  displayComments,
}) => {
  const theme = useTheme();

  return (
    <Grid
      item
      container
      justifyContent={"flex-end"}
      xs={12}
      sx={styles.actionBar}
    >
      <Grid item>
        {displayComments && (
          <IconButton onClick={handleShowComments}>
            <Badge badgeContent={comments.length} color="primary">
              <CommentOutlined />
            </Badge>
          </IconButton>
        )}
        <IconButton>
          <Badge
            badgeContent={views.length ? views.length : 0}
            color="secondary"
          >
            <MdRemoveRedEye />
          </Badge>
        </IconButton>
        <IconButton onClick={handleLikePost}>
          <Badge badgeContent={likes.length} color="secondary">
            {userLiked ? (
              <Favorite sx={{ color: theme.palette.primary.main }} />
            ) : (
              <FavoriteBorderOutlined />
            )}
          </Badge>
        </IconButton>
      </Grid>
    </Grid>
  );
};
