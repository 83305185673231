import { useTheme } from "@emotion/react";
import ListGroup from "react-bootstrap/ListGroup";
import { useNavigate } from "react-router-dom";

const AllSportLegalInfo = () => {
  const navigateTo = (route) => {
    // navigate(route);
    window.open(route, "blank");
  };

  const theme = useTheme();

  return (
    <div style={{ color: "#9b9ca8" }}>
      <ListGroup>
        <ListGroup.Item
          className="border-0 text-white fw-bold ps-0"
          style={{ backgroundColor: theme.palette.dark.main }}
        >
          All Sports +
        </ListGroup.Item>
        <ListGroup.Item
          className="border-0 ps-0"
          style={{
            backgroundColor: theme.palette.dark.main,
            cursor: "pointer",
          }}
        >
          <a
            style={{ color: "#9b9ca8" }}
            onClick={() => navigateTo("/terminos-condiciones")}
          >
            Términos y condiciones
          </a>
        </ListGroup.Item>
        <ListGroup.Item
          className="border-0 ps-0"
          style={{
            backgroundColor: theme.palette.dark.main,
            cursor: "pointer",
          }}
        >
          <a
            onClick={() => navigateTo("/politica-privacidad")}
            style={{ color: "#9b9ca8" }}
          >
            Politica de privacidad
          </a>
        </ListGroup.Item>
        <ListGroup.Item
          className="border-0 ps-0"
          style={{
            backgroundColor: theme.palette.dark.main,
            cursor: "pointer",
          }}
        >
          <a
            onClick={() => navigateTo("/reglamentos-promociones")}
            style={{ color: "#9b9ca8" }}
          >
            Contratos y reglamentos
          </a>
        </ListGroup.Item>
        <ListGroup.Item
          className="border-0 ps-0"
          style={{
            backgroundColor: theme.palette.dark.main,
            cursor: "pointer",
          }}
        >
          <a
            onClick={() => navigateTo("/reglamento-coins")}
            style={{ color: "#9b9ca8" }}
          >
            Conversión y uso de coins
          </a>
        </ListGroup.Item>
        {/* <ListGroup.Item
          className="border-0 ps-0"
          style={{ backgroundColor: "#1f2131" }}
        >
          <a href="#plansSection" style={{ color: "#9b9ca8" }}>
            Planes
          </a>
        </ListGroup.Item> */}
      </ListGroup>
    </div>
  );
};

export default AllSportLegalInfo;
