import React from "react";
import {
  Container,
  Typography,
  Grid,
  useTheme,
  IconButton,
  Box,
} from "@mui/material";
import Hero from "../components/hero/Hero";
import aboutus from "../assests/aboutUsOne.png";
import aboutus2 from "../assests/aboutUsSecond.png";
import aboutus3 from "../assests/aboutUsThird.png";
import icon1 from "../assests/iconabout1.png";
import icon2 from "../assests/iconabout2.png";
import { Md10K } from "react-icons/md";
import Footer from "../shared/ASFooter";

const AboutUsPage = () => {
  const theme = useTheme();
  return (
    <>
      <Hero theme={theme} />
      <Container maxWidth={"lg"}>
        <Grid container spacing={2} alignItems="center" mt={4} mb={4}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h4"
              color={theme.palette.primary.main}
              sx={{ fontWeight: 500, fontSize: 38 }}
            >
              Nosotros
            </Typography>
            <Typography variant="body1" mt={2}>
              En ALL SPORTS +, estamos comprometidos con tu bienestar y tu
              estilo de vida activo. Somos una plataforma que te ayuda a
              transformar tus hábitos saludables en una experiencia
              gratificante.{" "}
            </Typography>
            <Typography variant="body1" mt={2}>
              <span style={{ fontWeight: "600" }}>Nuestra misión</span> es
              inspirarte a tomar el control de tu actividad física y celebrar
              cada logro.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent={"center"}>
            <Box
              component="img"
              sx={{
                width: "525px",
                height: "auto",
                cursor: "pointer",
                [theme.breakpoints.down("sm")]: {
                  width: "250px",
                },
              }}
              alt="about-us"
              src={aboutus}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography
              variant="body1"
              sx={{ fontSize: 12, fontWeight: 500, mb: 1 }}
            >
              Nuestro Compromiso:
            </Typography>
            <Typography
              variant="h3"
              color={theme.palette.primary.main}
              sx={{
                fontWeight: 500,
                [theme.breakpoints.down("lg")]: {
                  fontSize: 28,
                },
                [theme.breakpoints.down("md")]: {
                  fontSize: 24,
                },
                mb: 2,
              }}
            >
              En All Sports +, nos enorgullece nuestro compromiso con tu
              bienestar.
            </Typography>
            <Typography variant="body1">
              Nuestro equipo multidisciplinario de expertos en salud, tecnología
              y diseño trabaja incansablemente para brindarte las herramientas y
              recompensas que necesitas para mantener un estilo de vida activo y
              saludable.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} container justifyContent={"center"}>
            <Box
              component="img"
              sx={{
                width: "525px",
                height: "auto",
                cursor: "pointer",
                [theme.breakpoints.down("sm")]: {
                  width: "250px",

                  mb: 2,
                },
              }}
              alt="about-us"
              src={aboutus2}
            />
          </Grid>
        </Grid>

        <div>
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              container
              justifyContent={"center"}
              xs={12}
              sm={12}
              md={6}
              order={{ xs: 2, sm: 2, md: 1, lg: 1 }}
            >
              <Box
                component="img"
                sx={{
                  width: "525px",
                  height: "auto",
                  cursor: "pointer",
                  [theme.breakpoints.down("sm")]: {
                    width: "225px",
                  },
                }}
                alt="about-us"
                src={aboutus3}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
              sm={12}
              spacing={2}
              order={{ xs: 1, sm: 1, md: 2, lg: 2 }}
            >
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <IconButton>
                    <img
                      src={icon1}
                      alt="Company Image 3"
                      sx={{ maxWidth: "100%", height: "auto" }}
                    />
                  </IconButton>
                  <Typography variant="h4">Historia</Typography>
                </Box>
                <Typography variant="body1">
                  Fundada en 2018, All Sports + ha estado en el centro de la
                  revolución del bienestar digital. Comenzamos como un equipo de
                  apasionados por la salud y el fitness que encontramos la
                  necesidad de adaptarnos al mundo que se mueve de manera más
                  inteligente. Desde entonces, hemos evolucionado para ofrecer
                  una experiencia integral de bienestar.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <IconButton>
                    <img
                      src={icon2}
                      alt="Company Image 3"
                      sx={{
                        maxWidth: "100%",
                        height: "auto",
                      }}
                    />
                  </IconButton>
                  <Typography variant="h4">Valores</Typography>
                </Box>
                <Typography variant="body1">
                  En All Sports +, estamos arraigados en valores fundamentales
                  que incluyen la salud, el bienestar, la comunidad y la
                  innovación. Estos valores guían nuestras decisiones y acciones
                  en todo momento, asegurando que lo que hacemos tenga un
                  impacto positivo en tu día a día.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
};

export default AboutUsPage;
