import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Container,
  Tabs,
  Tab,
  Grid,
  Avatar,
  ListItemButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  IoCalendar,
  IoCalendarOutline,
  IoDocument,
  IoDocumentOutline,
  IoHelp,
  IoList,
  IoLogOutOutline,
  IoMail,
  IoPeopleOutline,
  IoPersonAddOutline,
  IoPersonOutline,
  IoPersonRemoveOutline,
  IoPricetag,
  IoPricetagOutline,
  IoReaderOutline,
  IoSettingsOutline,
  IoStarOutline,
} from "react-icons/io5";
import MenuButton from "./MenuButton";

export const Sidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const navigateTo = (link) => {
    navigate(link);
  };
  const horizontal = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      {horizontal ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <MenuButton
            item={{
              title: "Perfil",
              icon: <IoPersonOutline size={25} className="inactive-icon" />,
              route: "/profile/info",
            }}
          />
          <MenuButton
            item={{
              title: "Mejorar Plan",
              icon: <IoCalendarOutline size={25} className="inactive-icon" />,
              route: "/profile/plans",
            }}
          />
          <MenuButton
            item={{
              title: "Mi Plan",
              icon: <IoReaderOutline size={25} className="inactive-icon" />,
              route: "/profile/billing",
            }}
          />
          <MenuButton
            item={{
              title: "Beneficios",
              icon: <IoPricetagOutline size={25} className="inactive-icon" />,
              route: "/profile/benefits",
            }}
          />
          <MenuButton
            item={{
              title: "Consultas",
              icon: <IoDocumentOutline size={25} className="inactive-icon" />,
              route: "/profile/inquiries",
            }}
          />
          {/* <MenuButton
            item={{
              title: "Solicitar Reclamo",
              icon: (
                <IoPersonRemoveOutline size={25} className="inactive-icon" />
              ),
              route: "/profile/",
            }}
          /> */}
          {/* <MenuButton
            item={{
              title: "Configuración",
              icon: <IoSettingsOutline size={25} className="inactive-icon" />,
              route: "/profile/settings",
            }}
          /> */}
          <MenuButton
            item={{
              title: "Cerrar Sesión",
              icon: <IoLogOutOutline size={25} className="inactive-icon" />,
              route: "/profile/",
            }}
          />
        </div>
      ) : (
        <List sx={{ p: 0, width: "100%" }}>
          <MenuButton
            item={{
              title: "Perfil",
              icon: <IoPersonOutline size={25} className="inactive-icon" />,
              route: "/profile/info",
            }}
          />
          <MenuButton
            item={{
              title: "Mejorar Plan",
              icon: <IoCalendarOutline size={25} className="inactive-icon" />,
              route: "/profile/plans",
            }}
          />
          <MenuButton
            item={{
              title: "Mi Plan",
              icon: <IoReaderOutline size={25} className="inactive-icon" />,
              route: "/profile/billing",
            }}
          />
          <MenuButton
            item={{
              title: "Beneficios",
              icon: <IoPricetagOutline size={25} className="inactive-icon" />,
              route: "/profile/benefits",
            }}
          />
          <MenuButton
            item={{
              title: "Consultas",
              icon: <IoDocumentOutline size={25} className="inactive-icon" />,
              route: "/profile/inquiries",
            }}
          />
          {/* <MenuButton
          item={{
            title: "Solicitar Reclamo",
            icon: <IoPersonRemoveOutline size={25} className="inactive-icon" />,
            route: "/profile/",
          }}
        /> */}
          {/* <MenuButton
            item={{
              title: "Configuración",
              icon: <IoSettingsOutline size={25} className="inactive-icon" />,
              route: "/profile/settings",
            }}
          /> */}
          <MenuButton
            item={{
              title: "Cerrar Sesión",
              icon: <IoLogOutOutline size={25} className="inactive-icon" />,
              route: "/profile/",
            }}
          />
        </List>
      )}
    </Box>
  );
};
