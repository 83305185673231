import { types } from "../types/types";

 const initialState = {
    plans: []
  };

  export const plansReducer = (state = initialState, action) => {
    switch (action.type) {
      case types.getPlans:
        return {
          ...state,
          plans: action.payload.plans,
        };
  
      default:
        return state;
    }
  };