import Button from "react-bootstrap/Button";

import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";

import PlanCard from "./PlanCard";
import { useEffect, useRef, useState } from "react";
import { getAllPlans } from "../../store/actions/plans";
import { useDispatch, useSelector } from "react-redux";

import darkCoin from "../../assests/darkCoin.gif";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper"; // Import navigation and pagination modules
import circle from "../../assests/yellowCircle.png";
import "./plans.scss";
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ASbutton } from "../buttons/ASbutton";
import { useLocation } from "react-router-dom";

const Plans = ({ handleShow }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isFirstUse = useRef(true);
  const plans = useSelector((state) => state.plans.plans);
  const location = useLocation();

  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showAgedPeoplePlan, setShowAgedPeoplePlan] = useState(false);
  const slidesPerView = plans.length > 1 ? 3 : 1;

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      slides: {
        perView: slidesPerView,
      },

      loop: false,
      breakpoints: {
        "(min-width: 300px)": {
          slides: { perView: 1, spacing: 5 },
        },
        "(min-width: 768px)": {
          slides: { perView: 1, spacing: 5 },
        },
        "(min-width: 900px)": {
          slides: { perView: 1, spacing: 5 },
        },
        "(min-width: 1080px)": {
          slides: { perView: 2, spacing: 5 },
        },
        "(min-width: 1300px)": {
          slides: { perView: 3, spacing: 10 },
        },
      },
    },
    [
      // add plugins here
    ]
  );

  const getPlans = () => {
    dispatch(getAllPlans());
  };

  useEffect(() => {
    if (isFirstUse) {
      getPlans();
      isFirstUse.current = false;
    }
  }, []);

  const togglePlans = () => {
    setShowAgedPeoplePlan((prevValue) => !prevValue);
  };

  if (!plans.length) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <h3>Cargando...</h3>
        <img
          src={darkCoin}
          alt="Moneda girando"
          style={{ width: "150px", height: "150px" }}
        />
      </div>
    );
  }
  const maxWidth = location.pathname === "/profile/plans" ? "false" : "lg";

  return (
    <Container maxWidth={maxWidth}>
      {location.pathname === "/profile/plans" ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: location.pathname === "/profile/plans" ? 2 : 8,
            mb: location.pathname === "/profile/plans" ? 2 : 8,
            [theme.breakpoints.down("sm")]: {
              width: "50%",
              padding: 0,
            },
          }}
          p={{ xs: 2, sm: 2, md: 4 }}
        >
          <Typography
            variant="h3"
            color={theme.palette.dark.terciary}
            sx={{
              fontWeight: "bold",
              [theme.breakpoints.down("sm")]: {
                fontSize: 24,
              },
            }}
          >
            Planes
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "30%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: location.pathname === "/profile/plans" ? 2 : 8,
            mb: location.pathname === "/profile/plans" ? 2 : 8,
            [theme.breakpoints.down("sm")]: {
              width: "50%",
            },
          }}
          p={{ xs: 2, sm: 2, md: 4 }}
        >
          <Typography
            variant="h3"
            color={theme.palette.dark.terciary}
            sx={{
              fontWeight: "bold",
              [theme.breakpoints.down("sm")]: {
                fontSize: 24,
              },
            }}
          >
            Planes
          </Typography>
          <Box
            component="img"
            sx={{
              height: "48px",
              width: "48px",
            }}
            alt="The house from the offer."
            src={circle}
          />
        </Box>
      )}
      {!mobile ? (
        <div
          ref={sliderRef}
          className="keen-slider"
          style={{ marginBottom: "2em" }}
        >
          {plans.map((plan, index) => {
            return (
              <div
                key={index}
                className={`${
                  plan.isElderPlan !== showAgedPeoplePlan
                    ? "d-none"
                    : "keen-slider__slide"
                } d-flex justify-content-center py-3`}
              >
                <PlanCard
                  plan={plan}
                  index={index}
                  handleShowLogIn={handleShow}
                  agedPlan={showAgedPeoplePlan}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <Box
          ref={sliderRef}
          className="keen-slider"
          sx={{
            width: "100%",
            maxWidth: "365px",
            margin: "0 auto",
            padding: 0,
          }}
        >
          {plans.map((plan, index) => {
            return (
              <div
                key={index}
                className={`${
                  plan.isElderPlan !== showAgedPeoplePlan
                    ? "d-none"
                    : "keen-slider__slide"
                } d-flex justify-content-center py-3`}
              >
                <PlanCard
                  plan={plan}
                  index={index}
                  handleShowLogIn={handleShow}
                  agedPlan={showAgedPeoplePlan}
                />
              </div>
            );
          })}
        </Box>
      )}

      <Box
        sx={{
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          width: "100%",

          [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
            margin: "0 auto",
            width: "100%",
          },
        }}
      >
        <ASbutton
          backgroundColor={theme.palette.primary.main}
          height={85}
          responsiveHeight={60}
          width={"75%"}
          buttonText={
            showAgedPeoplePlan
              ? "Mostrar todos los planes"
              : "Mostrar planes para mayores de +60 años"
          }
          onClick={togglePlans}
        >
          {showAgedPeoplePlan
            ? "Mostrar todos los planes"
            : "Mostrar planes para mayores de +60 años"}
        </ASbutton>
      </Box>
    </Container>
  );
};

export default Plans;
