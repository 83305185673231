import { SET_SPONSORS } from "../actions/sponsors";

const initialState = {
  sponsors: [],
};

export const sponsorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SPONSORS:
      return {
        ...state,
        sponsors: action.payload,
      };

    default:
      return state;
  }
};
