import { Modal } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch } from "react-redux";

import RegisterForm from "../forms/RegisterForm";

import React, { useState } from "react";
import {
  Dialog,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  IconButton,
  useTheme,
} from "@mui/material";
import UpdateProfileData from "../forms/UpdateProfileData";
import SignUpForm from "../forms/SignUpForm";
import UpdateUserProfileForm from "../forms/UpdateUserProfleForm";
import { MdClose } from "react-icons/md";

const RegisterModal = ({ open, setOpen, openLogIn }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const matches480 = useMediaQuery("(max-width:480px)");
  const matches768 = useMediaQuery("(max-width:768px)");
  const matches1200 = useMediaQuery("(min-width:1200px)");
  const dispatch = useDispatch();

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const Step1Content = () => {
    return (
      <div>
        {/* Step 1 content */}
        {/* <RegisterForm handleNext={handleNext} /> */}
        <SignUpForm
          handleNext={handleNext}
          handleClose={setOpen}
          handleOpenLogIn={openLogIn}
        />
      </div>
    );
  };

  const Step2Content = () => {
    return (
      <div>
        {/* Step 2 content */}
        {/* <UpdateProfileData setOpen={setOpen} /> */}
        <UpdateUserProfileForm setOpen={setOpen} handleBack={handleBack} />
      </div>
    );
  };

  const Step3Content = () => {
    return <div>{/* Step 3 content */}</div>;
  };

  const steps = [
    { label: "Paso 1", content: <Step1Content /> },
    { label: "Paso 2", content: <Step2Content /> },
    // { label: "Paso 3", content: <Step3Content /> },
  ];
  const onClose = () => {
    setActiveStep(0);
    setOpen(false);
    openLogIn(false);
  };
  return (
    <Dialog
      fullWidth
      className="scroll-container"
      //disableScrollLock
      maxWidth="lg"
      PaperProps={{
        sx: matches768
          ? {
              overflowY: "scroll",
              "&::-webkit-scrollbar": { width: 0 }, // Chromium-based browsers
              scrollbarWidth: "none", // Firefox
              msOverflowStyle: "none", // IE and Edge
              bgcolor: "transparent",
              width: "100%",
              boxShadow: "none",
              borderRadius: "18px",
              overflow: "hidden",
            }
          : {
              overflowY: "scroll",
              "&::-webkit-scrollbar": { width: 0 }, // Chromium-based browsers
              scrollbarWidth: "none", // Firefox
              msOverflowStyle: "none", // IE and Edge
              bgcolor: "transparent",
              boxShadow: "none",
              borderRadius: "18px",
              padding: "60px",
            },
      }}
      open={open}
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 20,
          color: "white",
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <MdClose size={32} />
      </IconButton>
      <Box
        sx={
          matches768
            ? {
                bgcolor: "#f5f6ff",
                //padding: "2rem",
                borderRadius: "55px",
                width: "100%",
                height: "auto",
                p: 4,
                overflow: "auto",
                //maxHeight: "758px",
              }
            : {
                bgcolor: "#f5f6ff",
                padding: "2rem",
                borderRadius: "55px",
                width: "100%",
                height: "80vh",
                height: "auto",
                //maxHeight: "758px",
              }
        }
      >
        {/* <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper> */}

        {steps[activeStep].content}
      </Box>
    </Dialog>
  );

  // return (
  //   <Modal
  //     show={show}
  //     onHide={() => {
  //       handleClose();
  //     }}
  //     backdrop="static"
  //     keyboard={false}
  //   >
  //     <Modal.Header closeButton>
  //       <Modal.Title>Registro</Modal.Title>
  //     </Modal.Header>
  //     <Modal.Body>
  //       <RegisterForm />
  //     </Modal.Body>
  //   </Modal>
  // );
};

export default RegisterModal;
