import * as yup from "yup";

import valid from "card-validator";

export const cardAdministrationSchema = yup.object({
  cardNumber: yup
    .string()
    .test(
      "test-number",
      "Numero de Tarjeta Inválido",
      (value) => valid.number(value).isValid
    )
    .min(16)
    .max(19, "Numero de Tarjeta Inválido")
    .required("Campo Requerido!"),

  cardExpiration: yup
    .string()
    .test(
      "valid",
      "Formato de fecha no valido",
      (value) => valid.expirationDate(value).isValid
    )
    .required("Campo requerido"),

  cardName: yup
    .string()
    .test(
      "test-name",
      "Nombre Incorrecto",
      (value) => valid.cardholderName(value).isValid
    )
    .required("Campo Requerido!"),
  cardSecurityCode: yup
    .string()
    .matches(/^\d{3,4}$/, "CCV Invalido")
    .required("Campo Obligatorio"),
  issuerByCardNumber: yup.string(),
  issuer: yup
    .string()
    .oneOf(
      [yup.ref("issuerByCardNumber"), null],
      "El distribuidor no concuerda con el número de la tajeta"
    ),
});
