import { Container } from "react-bootstrap";

import exchangesPromotions from "../../assests/exchangesPromotionsCart.svg";
import { useEffect, useState } from "react";

const ExchangesPromotions = ({ productData, plan65 }) => {
  const [item, setItem] = useState({ text: "", info: "" });

  useEffect(() => {
    if (productData && !plan65) {
      setItem({
        id: "3",
        info: "Condiciones preferenciales de canje con hasta un 50% de descuentos del valor en Coins, cantidad de canje por día ilimitados y acceso a red de descuentos y promociones especiales",
        text: "Canjes y Promociones",
      });
    } else {
      setItem({
        id: "3",
        info: "Condiciones preferenciales de canje con hasta un 50% de descuentos del valor en Coins , cantidad de canje por día ilimitados y acceso a red de descuentos y promociones especiales",
        text: "Canjes y Promociones",
      });
    }
  }, []);

  return (
    <Container style={{ borderRadius: "55px" }}>
      <img
        src={exchangesPromotions}
        alt=""
        className="d-block mx-auto"
        width="200px"
      />
      <h4 className="fw-bold text-white">{item.text}</h4>
      <p className="text-white">{item.info}</p>
    </Container>
  );
};

export default ExchangesPromotions;
