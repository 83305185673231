import { red, yellow } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  MuiCssBaseline: {},
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1700,
    },
  },
  palette: {
    primary: {
      main: "#3E3188",
    },
    secondary: {
      main: "#625EA7",
    },

    terciary: {
      main: "#F1EEFE",
    },

    dark: {
      main: "#1E222A",
    },

    accent: {
      main: "#EF7817",
    },

    background: {
      default: "#fff",
    },
  },

  //}
  // palette: {
  //   primary: {
  //     //main: "#5F4BDB",
  //     main: "#3E3188",
  //     //secondary: "#E6E5F5",
  //     secondary: "#625EA7",
  //     light: "#EFF2FB",
  //   },
  //   secondary: {
  //     //main: "#FBAF3F",
  //     main: "#EF7817",
  //     secondary: "#0440FD",
  //   },
  //   dark: {
  //     main: "#292A30",
  //     secondary: "#9399A7",
  //     terciary: "#363848",
  //   },
  //   terciary: {
  //     main: "#F1EEFE",
  //   },
  //   success: {
  //     main: "#69D482",
  //   },
  //   error: {
  //     main: "#FF5445",
  //   },

  //   background: {
  //     default: "#fff",
  //     //white: "#fff",
  //   },
  // },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
          color: "white",
          cursor: "pointer",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        },
      },
    },

    MuiTable: {
      styleOverrides: {
        root: {
          width: "80%",
          margin: "auto",
        },
      },
    },

    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: "white",
        },
        "&.Mui-active": {
          color: "white !important",
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          boxShadow: "none",
        },
      },
    },

    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          //color: "#7265E3 !important",
          color: "yellow",
          // backgroundColor: "#7265E3 !important",
          minHeight: "40px",
          borderTopLeftRadius: 36,
          borderRadius: 20,
          borderTopRightRadius: 36,
        },
      },
    },

    "MuiTableRow-hover": {
      styleOverrides: {
        root: {
          // "&:hover": {
          backgroundColor: "#f4f4f5",
          borderRadius: 20,
          // },
        },
      },
    },

    MUIDataTableHeadCell: {
      styleOverrides: {
        data: {
          color: "#202020 !important",
          fontSize: 12,
          borderRadius: 20,
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#f4f4f5 !important",
          borderRadius: 20,
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        head: {
          justifyContent: "center",
          maxHeight: "100px !important",
          span: { justifyContent: "center", color: "#white" },
          button: {
            borderRadius: 0,
            boxShadow: "none",
            fontWeight: "800",
          },
        },
        root: {
          borderBottom: "none",
          textAlign: "center",
          maxHeight: "200px",
          paddingTop: "2px",
          fontSize: "0.8rem",
        },
        footer: {
          borderBottomLeftRadius: 18,

          borderBottomRightRadius: 18,
        },
        body: {
          backgroundColor: "#fff",

          // "&:hover": {
          //   backgroundColor: "#f4f4f5",
          //   borderRadius: 20,
          // },
        },
      },
    },
  },
});

export default theme;
