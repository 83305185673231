import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import { styled, alpha, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assests/logo.png";
import { logoutUser } from "../../store/actions/auth";
import RegisterModal from "../modals/RegisterModal";
import { Link, useNavigate } from "react-router-dom";
import "./header.scss";
import SignInModal from "../modals/SignInModal";
import MenuIcon from "@mui/icons-material/Menu";

import { getUserInfo, setUserInfo } from "../../store/actions/user";
import {
  AppBar,
  Avatar,
  Box,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Drawer,
  IconButton,
  List,
  Divider,
  ListItemButton,
  ListItemText,
  ListItem,
  CircularProgress,
} from "@mui/material";
import Swal from "sweetalert2"; // Import Swal
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NotificationDropdown from "../general/NotificationDropdown";
import { getAllPlans } from "../../store/actions/plans";
import { ASbutton } from "../buttons/ASbutton";

const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const isFirstUse = useRef(true);
  const openMenu = Boolean(anchorEl);
  const [openLogIn, setOpenLogIn] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { userInfo, isLoadingInfo } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const drawerWidth = 240;

  const [mobileOpen, setMobileOpen] = useState(false);

  const getUserInfoFromFb = useCallback(async () => {
    try {
      if (user) {
        dispatch(getUserInfo(user.uid));
        dispatch(getAllPlans());
      }
    } catch (error) {
      console.log(error);
    }
  }, [user, userInfo, dispatch]);
  useEffect(() => {
    if (isFirstUse.current) {
      getUserInfoFromFb();
      isFirstUse.current = false;
    } else if (!userInfo) {
      getUserInfoFromFb();
    } else {
      setLoading(false);
    }
  }, [getUserInfoFromFb, userInfo, user]);

  useEffect(() => {
    if (isLoadingInfo) {
      console.log("headerload", isLoadingInfo);
      getUserInfoFromFb();
    }
  }, [isLoadingInfo]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // useEffect(() => {
  //   if ("scrollRestoration" in window.history) {
  //     console.log("execution");
  //     window.history.scrollRestoration = "auto";
  //   }
  // }, []);

  const signOutUser = () => {
    Swal.fire({
      title: "Cerrar Sesión",
      text: "¿Estás seguro de que quieres cerrar sesión?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logoutUser());
        navigate("/home", { replace: true });
      }
    });
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/home", { replace: true });
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMobileOpen(false);
  };
  const navigateTo = (route) => {
    navigate(route);
    handleClose();
  };
  const pages = [
    { name: "Inicio", route: "/home" },
    { name: "Nosotros", route: "/about-us" },
    // { name: "Blogs", route: "/blogs" },
    { name: "FAQ", route: "/faq" },
    { name: "Contacto", route: "/contact" },
    // { name: "Inscribirme", route: "/" },
  ];

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      disableScrollLock
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      marginTop: theme.spacing(1),
      minWidth: 100,

      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],

      //borderRadius: "0 18px 18px 0",
      borderWidth: "2px !important",
      border: "solid",
      borderColor: "#ECECEE",
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const drawer = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
        borderRadius: "0 18px 18px 0",
        alignItems: "center",
        textAlign: "center",
        height: "100%",
      }}
    >
      <Box
        component={"img"}
        src={logo}
        sx={{
          width: "65%",
          height: "auto",
          cursor: "pointer",
          mt: 4,
          [theme.breakpoints.down("sm")]: {
            width: "65%",
            height: "auto",
          },
        }}
        onClick={() => navigateTo("/home")}
      />

      {user && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "auto",
            mt: 4,
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "110px",
              mt: 0.5,
              mb: 0.5,
              width: "100%",

              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "18px",
              borderWidth: "2px !important",
              border: "solid",
              borderColor: "#ECECEE",
              p: 1,
              mr: 1,
            }}
          >
            <Avatar
              onClick={handleMenu}
              alt="User Avatar"
              src={userInfo?.photoURL}
              sx={{ mr: 1 }}
            />
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.primary.main,
              }}
            >
              {userInfo?.name ?? ""} {userInfo?.lastname ?? ""}
            </Typography>
            <ExpandMoreIcon
              onClick={handleMenu}
              sx={{
                cursor: "pointer",
                color: "black",
              }}
            />
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem onClick={() => navigateTo("/profile/info")}>
                Perfil
              </MenuItem>
              {/* <MenuItem onClick={() => navigateTo("profile/settings")}>
                Configuración
              </MenuItem> */}
              <MenuItem onClick={handleClose}>
                <Link
                  onClick={signOutUser}
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Cerrar Sesión
                </Link>
              </MenuItem>
            </StyledMenu>
          </Box>
        </Box>
      )}
      <Divider />

      <List sx={{ mt: 2 }}>
        {pages.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => navigateTo(item.route)}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {!user && (
        <Box
          sx={{
            marginTop: "auto",
            flexDirection: "column",
            justifyContent: "center",
            display: "flex",
            mb: 3,
          }}
        >
          <Box
            sx={{
              mb: 3,
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <ASbutton
              onClick={() => setOpenLogIn(true)}
              buttonText={"Iniciar sesión"}
            />
          </Box>
          <Box
            sx={{
              mb: 3,
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <ASbutton
              backgroundColor={"#cecece"}
              buttonText={"    Inscribirme"}
              onClick={() => setOpenSignUp(true)}
            />
          </Box>
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#fff !important",
          borderRadius: 0,
          boxShadow: "none",
          justifyContent: "center",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
          borderWidth: "2px",
          border: "solid",
          zIndex: 1000,
          maxHeight: "85px",
          minHeight: "85px",
          pl: 2,
          pr: 2,
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon color="primary" />
            </IconButton>
            <Box
              component={"img"}
              src={logo}
              sx={{
                width: "250px",
                height: "auto",
                cursor: "pointer",

                [theme.breakpoints.down("sm")]: {
                  width: "175px",
                  height: "auto",
                },
              }}
              onClick={() => navigateTo("/home")}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "auto",
            }}
          >
            {user && userInfo ? (
              <>
                {loading ? (
                  <Box
                    container
                    spacing={2}
                    sx={{
                      position: "relative",
                      width: "110px",
                      mt: 0.5,
                      mb: 0.5,
                      width: "100%",
                      // maxHeight: "35px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: "18px",
                      borderWidth: "2px !important",
                      border: "solid",
                      borderColor: "#ECECEE",
                      p: 1,
                      mr: 1,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <NotificationDropdown />

                    <Box
                      sx={{
                        position: "relative",
                        width: "110px",
                        mt: 0.5,
                        mb: 0.5,
                        width: "100%",
                        // maxHeight: "35px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: "18px",
                        borderWidth: "2px !important",
                        border: "solid",
                        borderColor: "#ECECEE",
                        p: 1,
                        mr: 1,
                      }}
                    >
                      <Avatar
                        onClick={handleMenu}
                        alt="User Avatar"
                        src={userInfo?.photoURL}
                        sx={{ mr: 1 }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.primary.main,
                          mr: 2,
                          [theme.breakpoints.down("md")]: {
                            display: "none",
                          },
                        }}
                      >
                        {userInfo?.name ?? ""} {userInfo?.lastname ?? ""}
                      </Typography>
                      <ExpandMoreIcon
                        onClick={handleMenu}
                        sx={{
                          cursor: "pointer",
                          color: "black",
                        }}
                      />
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={() => navigateTo("/profile/info")}>
                          Perfil
                        </MenuItem>
                        {/* <MenuItem
                          onClick={() => navigateTo("profile/settings")}
                        >
                          Configuración
                        </MenuItem> */}
                        <MenuItem onClick={handleClose}>
                          <Link
                            onClick={signOutUser}
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            Cerrar Sesión
                          </Link>
                        </MenuItem>
                      </StyledMenu>
                    </Box>
                  </>
                )}
              </>
            ) : null}
            {!user ? (
              <>
                <Box mr={2}>
                  <Button
                    variant="secondary"
                    onClick={() => setOpenLogIn(true)}
                    className="d-none d-lg-block"
                  >
                    Iniciar sesión
                  </Button>
                </Box>
                <Button
                  variant="primary"
                  className="shadow d-none d-lg-block"
                  onClick={() => setOpenSignUp(true)}
                >
                  Inscribirme
                </Button>
              </>
            ) : null}
          </Box>
        </Toolbar>
        <RegisterModal
          open={openSignUp}
          setOpen={setOpenSignUp}
          openLogIn={setOpenLogIn}
        />
        <SignInModal
          handleClose={setOpenLogIn}
          show={openLogIn}
          setOpenSignUp={setOpenSignUp}
        />
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          disableScrollLock
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRadius: "0 18px 18px 0",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
};

export default Header;
