import Form from "react-bootstrap/Form";
import { Stack, Button, Row, Col } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Provinces as provinces } from "../../utils/provinces";
import { Cantons as cantons } from "../../utils/cantons";
import { Districts as districts } from "../../utils/districts";

import { useFormik } from "formik";

import { FiArrowRight } from "react-icons/fi";

import exerciseGirl from "../../assests/exercise-girl.jpg";

import { useDispatch, useSelector } from "react-redux";

import "./formStyles.scss";
import { useState, useEffect } from "react";
import { personalInformationFormSchema } from "../../schemas/personalInformation";
import PurchaseRaceProgress from "../PurchaseRaceProgress/PurchaseRaceProgress";
import { setUserProfile, updateUserData } from "../../store/actions/user";
import { fsTimestampCreador } from "../../firebase/firebase-config";
import { useTheme } from "@emotion/react";

export const idTypesData = [
  {
    value: "01",
    label: "Nacional",
    id: 0,
  },
  {
    value: "03",
    label: "Dimex",
    id: 1,
  },
  {
    value: "04",
    label: "Nite",
    id: 2,
  },
];

const UpdateUserProfileForm = ({ setOpen, userData }) => {
  const theme = useTheme();

  const [filteredCantons, setFilteredCantons] = useState([]);
  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const currentUser = useSelector((state) => state.auth.user);
  const errorCode = useSelector((state) => state.auth.errorCode);
  const userProfile = useSelector((state) => state.user.userProfile);

  const {
    setFieldValue,
    handleChange,
    touched,
    errors,
    values,
    isValid,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      idType: "Seleccione una opción",
      personalId: userData?.personalId ?? "",
      dateOfBirth: userData?.birthday ?? startDate,
      province: userData?.province ?? "",
      canton: userData?.canton ?? "",
      district: userData?.district ?? "",
      termsAndConditions: false,
      notificationPermission: userData?.notificationPermission ?? false,
    },
    validationSchema: personalInformationFormSchema,
    onSubmit: (values) => {
      const publicData = {
        cdate: fsTimestampCreador.fromDate(new Date()),
        name: userProfile?.name ? userProfile?.name : "",
        lastname: userProfile?.lastname ?? "",
        secondLastname: userProfile?.secondLastname ?? "",
        province: values.province,
        canton: values.canton,
        district: values.district,
        notificationPermission: values.notificationPermission,
        birthday: fsTimestampCreador.fromDate(values.dateOfBirth),
        skipIntro: false,
        isActiveMember: false,
        infoCompleted: false,
        planType: {
          code: "T-01",
          finalPrice: 0,
          subTier: "Training",
        },
      };

      const privateData = {
        personalId: values.personalId,
        idType: values.idType,
      };

      updateUserData(currentUser.uid, publicData, privateData, "data");
      setOpen();
    },
  });

  useEffect(() => {
    if (errorCode !== "" && errorCode !== undefined) {
      setOpen();
    }
  }, [errorCode]);

  useEffect(() => {
    const selectedProvince = values.province;

    if (selectedProvince) {
      const filterItems = cantons.filter(
        (canton) => canton.provinceName === selectedProvince
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });
      setFilteredCantons(filterItems);
    }
  }, [values.province]);

  useEffect(() => {
    const selectedCanton = values.canton;

    if (selectedCanton) {
      const filterItems = districts.filter(
        (district) => district.cantonName === selectedCanton
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });
      setFilteredDistricts(filterItems);
    }
  }, [values.canton]);

  return (
    <div
      style={{
        backgroundColor: "#f5f6ff",
        padding: "20px",
        borderRadius: "55px",
      }}
    >
      <Row>
        <Col xl="7" lg="12">
          <PurchaseRaceProgress progressPorcentage={90} />
          {/* <Stepper activeStep={activeStep} alternativeLabel variant="progress">
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper> */}
          <h4 className="fw-bold my-3">Información Personal</h4>
          <form>
            <Row>
              <Form.Group
                className="mb-3"
                controlId="groupIdType"
                as={Col}
                md="6"
              >
                <Form.Label>Tipo de documento</Form.Label>
                <Form.Select
                  className="mb-3"
                  aria-label="Tipo de documento"
                  value={values.idType}
                  onChange={handleChange}
                  name="idType"
                  onBlur={handleBlur}
                  isInvalid={!!errors.idType && touched.idType}
                >
                  <option>Seleccione una opcion</option>
                  {idTypesData.map((type) => {
                    return <option>{type.label}</option>;
                  })}
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  {errors.idType}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="groupId" as={Col} md="6">
                <Form.Label>Número de documento</Form.Label>
                <Form.Control
                  type="number"
                  className="mb-3"
                  placeholder="Número de documento"
                  onChange={handleChange}
                  name="personalId"
                  isInvalid={!!errors.personalId && touched.personalId}
                  onBlur={handleBlur}
                  value={values.personalId}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.personalId}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="mb-3"
                controlId="groupDate"
                as={Col}
                md="6"
              >
                <Form.Label>Fecha de nacimiento</Form.Label>
                <DatePicker
                  //selected={userData?.general.birthday.toDate() || startDate}
                  selected={values.dateOfBirth}
                  onChange={(val) => setFieldValue("dateOfBirth", val)}
                  name="dateOfBirth"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="form-control"
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="groupProvince"
                as={Col}
                md="6"
              >
                <Form.Label>Provincia</Form.Label>
                <Form.Select
                  aria-label="Provincia"
                  value={values.province}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.province && touched.province}
                  name="province"
                >
                  <option>Seleccione una opcion</option>
                  {provinces.map((province) => {
                    return <option>{province.label}</option>;
                  })}
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  {errors.province}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="mb-3"
                controlId="groupCanton"
                as={Col}
                md="6"
              >
                <Form.Label>Canton</Form.Label>
                <Form.Select
                  aria-label="Canton"
                  value={values.canton}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.canton && touched.canton}
                  disabled={!values.province}
                  name="canton"
                >
                  <option>Seleccione una opcion</option>
                  {filteredCantons.map((canton) => {
                    return <option>{canton.label}</option>;
                  })}
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  {errors.canton}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="groupCanton"
                as={Col}
                md="6"
              >
                <Form.Label>Distrito</Form.Label>
                <Form.Select
                  aria-label="Distrito"
                  value={values.district}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.district && touched.district}
                  disabled={!values.canton}
                  name="district"
                >
                  <option>Seleccione una opcion</option>
                  {filteredDistricts.map((district) => {
                    return <option>{district.label}</option>;
                  })}
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  {errors.district}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Form.Group className="mb-3">
              <Form.Check
                name="termsAndConditions"
                onChange={handleChange}
                isInvalid={!!errors.termsAndConditions}
                label={
                  <span>
                    He leido y acepto los{" "}
                    <a href="#" style={{ color: "#168FCA" }}>
                      Terminos y condiciones
                    </a>
                  </span>
                }
                feedback={errors.termsAndConditions}
                feedbackType="invalid"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                name="notificationPermission"
                onChange={handleChange}
                isInvalid={!!errors.notificationPermission}
                label={
                  <span>
                    Deseo recibir novedades exclusivas a mi correo electrónico
                  </span>
                }
                feedback={errors.notificationPermission}
                feedbackType="invalid"
              />
            </Form.Group>

            <Stack direction="horizontal" className="mt-4" gap={2}>
              <Button
                style={{
                  backgroundColor: theme.palette.primary.main,
                  padding: "10px 15px",
                  borderRadius: "15px",
                }}
                disabled={!isValid}
                onClick={() => {
                  handleSubmit();
                }}
                variant="secondary"
              >
                A la meta <FiArrowRight />
              </Button>
            </Stack>
          </form>
        </Col>
        <Col>
          <div
            className="position-absolute personal-info-form-exercise-girl d-none d-xl-block"
            style={{ width: "35%", top: "20px", right: "20px" }}
          >
            <img
              src={exerciseGirl}
              alt=""
              style={{ borderRadius: "55px", width: "100%" }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UpdateUserProfileForm;
