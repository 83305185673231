import { increment } from "firebase/firestore";
import { db } from "../../firebase/firebase-config";

export const SET_BLOGS = "SET_BLOGS";
export const SET_POSTS = "SET_POSTS";
export const SET_TAG_SELECTED = "SET_TAG_SELECTED";

export const getBlogPostById = async (parentId, id) => {
  try {
    const postDoc = await db
      .collection("blogs")
      .doc(parentId)
      .collection("posts")
      .doc(id)
      .get();
    if (postDoc.exists) {
      const postData = postDoc.data();

      return {
        id: postDoc.id,
        parentId: parentId,
        ...postData,
      };
    } else {
      console.error("Blog post not found");
      return null;
    }
  } catch (error) {
    console.error("Error getting blog post:", error);
    return null;
  }
};

export const getBlogPosts = (category) => {
  return async (dispatch) => {
    try {
      if (category === "all") {
        const allPosts = [];

        // Fetch posts from all categories
        const blogsQuerySnapshot = await db.collection("blogs").get();
        for (const blogDoc of blogsQuerySnapshot.docs) {
          const blogId = blogDoc.id;
          const postQuerySnapshot = await db
            .collection("blogs")
            .doc(blogId)
            .collection("posts")
            .where("status", "==", "accepted")
            .get();

          // Process each post document
          postQuerySnapshot.forEach((postDoc) => {
            const postData = postDoc.data();
            const post = {
              id: postDoc.id,
              parentId: blogId,
              ...postData,
            };
            allPosts.push(post);
          });
        }

        dispatch({
          type: SET_POSTS,
          postsByCategory: { [category]: allPosts },
        });
      } else {
        // Fetch posts for the specified category
        const blogQuerySnapshot = await db
          .collection("blogs")
          .where("name", "==", category)
          .get();

        blogQuerySnapshot.forEach(async (blogDoc) => {
          const blogId = blogDoc.id;
          const postQuerySnapshot = await db
            .collection("blogs")
            .doc(blogId)
            .collection("posts")
            .where("status", "==", "accepted")
            .get();

          // Process each post document
          const posts = [];
          postQuerySnapshot.forEach((postDoc) => {
            const postData = postDoc.data();
            const post = {
              id: postDoc.id,
              parentId: blogId,
              ...postData,
            };
            posts.push(post);
          });

          dispatch({
            type: SET_POSTS,
            postsByCategory: { [category]: posts },
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getTagPosts = async (tag) => {
  try {
    // Query for all blog documents that contain the specified tag
    const blogQuerySnapshot = await db
      .collectionGroup("posts") // Query across all subcollections named "posts"
      .where("tags", "array-contains", tag)
      .orderBy("cdate", "asc")
      .get();

    // Extract the posts from the query snapshot
    const posts = blogQuerySnapshot.docs.map((postDoc) => ({
      id: postDoc.id,
      parentId: postDoc.ref.parent.parent.id, // Get the parent blog ID
      ...postDoc.data(),
    }));

    return posts;
  } catch (error) {
    console.error(error);
    return []; // Return an empty array in case of error
  }
};

export const getBlogsCategories = async () => {
  const blogs = [];

  try {
    const blogsDB = await db
      .collection("blogs")
      .where("showOnApp", "==", true)
      .orderBy("orderNumber", "asc")
      .get();

    blogsDB.docs.forEach((doc) => {
      blogs.push({ id: doc.id, ...doc.data() });
    });
    return blogs;
  } catch (error) {
    console.log(error);
    return false;
  }
};

//COMMENTS

export const getCommentsFromPost = async (blogId, postId) => {
  try {
    const postRef = db
      .collection("blogs")
      .doc(blogId)
      .collection("posts")
      .doc(postId);

    const commentsSnapshot = await postRef
      .collection("comments")
      .orderBy("cdate")
      .get();

    const comments = [];
    commentsSnapshot.forEach((commentDoc) => {
      const commentData = commentDoc.data();
      comments.push({
        id: commentDoc.id,
        ...commentData,
      });
    });

    return comments;
  } catch (error) {
    console.error("Error getting comments:", error);
    throw error;
  }
};

export const getRepliesFromPost = async (blogId, postId, commentId) => {
  try {
    const postRef = db
      .collection("blogs")
      .doc(blogId)
      .collection("posts")
      .doc(postId)
      .collection("comments")
      .doc(commentId);

    const repliesSnapshot = await postRef.collection("replies").get();

    const replies = [];
    repliesSnapshot.forEach((replyDoc) => {
      const replyData = replyDoc.data();
      replies.push({
        id: replyDoc.id,
        ...replyData,
      });
    });

    return replies;
  } catch (error) {
    console.error("Error getting replies:", error);
    throw error;
  }
};

export const addComment = async (blogId, postId, commentData) => {
  try {
    const postRef = db
      .collection("blogs")
      .doc(blogId)
      .collection("posts")
      .doc(postId);

    const updatedData = {
      ...commentData,
      cdate: new Date(),
    };

    // Add the comment to the 'comments' subcollection
    const docRef = await postRef.collection("comments").add(updatedData);

    // Update the commentCount on the post document
    await postRef.update({
      commentCount: increment(1),
    });

    // Return the ID of the newly added comment
    return docRef.id;
  } catch (error) {
    console.error("Error adding comment:", error);
    throw error;
  }
};

export const replyToComment = async (
  blogId,
  postId,
  commentData,
  commentId
) => {
  try {
    const postRef = db
      .collection("blogs")
      .doc(blogId)
      .collection("posts")
      .doc(postId)
      .collection("comments")
      .doc(commentId);

    const updatedData = {
      ...commentData,
      cdate: new Date(),
    };
    await postRef.update({
      replyCount: increment(1),
    });
    await postRef.collection("replies").add(updatedData);
  } catch (error) {
    console.error("Error adding comment:", error);
    throw error;
  }
};

//LIKES

export const addLike = async (blogId, postId, likedData) => {
  try {
    const postRef = db
      .collection("blogs")
      .doc(blogId)
      .collection("posts")
      .doc(postId);

    // Check if the user has already liked the post
    const likeSnapshot = await postRef
      .collection("likes")
      .where("userId", "==", likedData.userId)
      .get();

    if (!likeSnapshot.empty) {
      // User has already liked the post, decrement the like count and remove the like document
      await postRef.set(
        {
          likesCount: increment(-1), // Decrement like count
        },
        { merge: true }
      );

      // Remove the like document
      likeSnapshot.forEach(async (likeDoc) => {
        await likeDoc.ref.delete();
      });

      // Return that the user has unliked the post
      return { userLiked: false };
    } else {
      // User has not liked the post, increment the like count and add the like document
      await postRef.set(
        {
          likesCount: increment(1), // Increment like count
        },
        { merge: true }
      );

      // Add like document to the "likes" subcollection of the post
      await postRef.collection("likes").add(likedData);

      // Return that the user has liked the post
      return { userLiked: true };
    }
  } catch (error) {
    console.error("Error adding like:", error);
    throw error;
  }
};

export const getLikesFromPost = async (blogId, postId) => {
  try {
    const postRef = db
      .collection("blogs")
      .doc(blogId)
      .collection("posts")
      .doc(postId);

    const likesSnapshot = await postRef.collection("likes").get();

    const likes = [];
    likesSnapshot.forEach((likeDoc) => {
      const likeData = likeDoc.data();
      likes.push({
        id: likeDoc.id,
        ...likeData,
      });
    });

    return likes;
  } catch (error) {
    console.error("Error getting likes:", error);
    throw error;
  }
};

export const getViewsFromPost = async (blogId, postId) => {
  try {
    const postRef = db
      .collection("blogs")
      .doc(blogId)
      .collection("posts")
      .doc(postId);

    const viewsSnapshot = await postRef.collection("views").get();

    const views = [];
    viewsSnapshot.forEach((viewsDoc) => {
      const viewsData = viewsDoc.data();
      views.push({
        id: viewsDoc.id,
        ...viewsData,
      });
    });

    return views;
  } catch (error) {
    console.error("Error getting views:", error);
    throw error;
  }
};
export const addView = async (blogId, postId, viewData) => {
  try {
    const postRef = db
      .collection("blogs")
      .doc(blogId)
      .collection("posts")
      .doc(postId);

    // Check if the post already has views from the same user
    const viewSnapshot = await postRef
      .collection("views")
      .where("userId", "==", viewData.userId)
      .get();

    if (viewSnapshot.empty) {
      // Add the view document to the "views" subcollection of the post
      await postRef.collection("views").add(viewData);

      // Increment the views count
      await postRef.set(
        {
          viewsCount: increment(1), // Increment views count
        },
        { merge: true }
      );

      // Return that the view has been added
      return { viewAdded: true };
    } else {
      // Return that the view already exists
      return { viewAdded: false };
    }
  } catch (error) {
    console.error("Error adding view:", error);
    throw error;
  }
};

export const setBlogs = (blogs) => {
  return (dispatch) => {
    dispatch({
      type: SET_BLOGS,
      blogs,
    });
  };
};
export const setTagSelected = (tagSelected) => {
  return (dispatch) => {
    dispatch({
      type: SET_TAG_SELECTED,
      tagSelected,
    });
  };
};
