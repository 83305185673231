import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import { MdClose, MdSend } from "react-icons/md";
import { ReplyOutlined } from "@mui/icons-material";

import {
  addComment,
  getRepliesFromPost,
  replyToComment,
} from "../../store/actions/blogs";
import { useFormik } from "formik";
import { styles } from "../../styles/styles";

export const CommentsSection = ({
  post,
  user,
  userInfo,
  comments,
  setComments,
}) => {
  const theme = useTheme();
  const [replyIndex, setReplyIndex] = useState(null);
  const [replyText, setReplyText] = useState("");
  const [showReplies, setShowReplies] = useState({});
  const [replies, setReplies] = useState();
  const initialValues = {
    comment: "",
  };
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      await handleAddComment(values.comment);
    },
  });

  const handleAddComment = async (commentText) => {
    const blogId = post.parentId;
    const postId = post.id;

    try {
      const commentData = {
        text: commentText,
        userId: user?.uid,
        username: userInfo?.name + " " + userInfo?.lastname,
      };

      // Add the comment and capture the ID of the newly added comment
      const newCommentId = await addComment(blogId, postId, commentData);

      // Update the comments state with the new comment and its ID
      setComments((prevComments) => [
        ...prevComments,
        { id: newCommentId, text: commentText },
      ]);
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleReply = async (index) => {
    setReplyIndex(index);
  };

  const handleCancelReply = () => {
    setReplyIndex(null);
    setReplyText(""); // Reset reply text when canceling reply
  };

  const handleSubmitReply = async (commentId, comment) => {
    const blogId = post.parentId;
    const postId = post.id;

    try {
      const commentData = {
        text: replyText,
        username: userInfo?.name + " " + userInfo?.lastname,
        userId: user?.uid,
      };

      await replyToComment(blogId, postId, commentData, commentId);

      setReplyIndex(null);
      setReplyText("");
    } catch (error) {
      console.error("Error replying to comment:", error);
    }
  };
  const toggleReplies = async (index, commentId) => {
    if (!showReplies[index]) {
      const postId = post.id;
      const blogId = post.parentId;

      const fetchedComments = await getRepliesFromPost(
        blogId,
        postId,
        commentId
      );

      setReplies((prevReplies) => ({
        ...prevReplies,
        [commentId]: fetchedComments,
      }));
    }
    setShowReplies((prevShowReplies) => ({
      ...prevShowReplies,
      [index]: !prevShowReplies[index],
    }));
  };

  return (
    <Grid container mb={4}>
      <Grid item container xs={12} alignContent={"center"}>
        <Typography mr={2} mb={2}>
          Comentarios{" "}
        </Typography>
        <Box
          sx={{
            display: "flex",
            backgroundColor: "grey",
            borderRadius: 50,
            width: 25,
            height: 25,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "black", fontSize: "0.9rem" }}>
            {comments?.length}
          </Typography>
        </Box>
      </Grid>
      <Grid item container xs={12} gap={2}>
        {comments.map((comment, index) => (
          <Grid item md={12} key={index} container justifyContent={"flex-end"}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                alignItems: "center",
                mr: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: 12, color: theme.palette.primary.main }}
              >
                {comment.username}
              </Typography>
              <Typography variant="body1" sx={{ fontSize: 12 }}>
                {comment?.cdate &&
                  new Date(comment.cdate.toDate()).toLocaleDateString()}
              </Typography>
            </Box>
            <Box sx={styles.commentBox}>
              <Typography sx={styles.commentText}>{comment.text}</Typography>
            </Box>
            {/* Render the reply button only if not currently replying */}
            {replyIndex !== index && (
              <IconButton onClick={() => handleReply(index)} color="primary">
                <Typography variant="caption" sx={{ color: "black" }}>
                  Contestar
                </Typography>
                <ReplyOutlined />
              </IconButton>
            )}
            {/* Render the reply form or modal when replying to a comment */}
            {replyIndex === index && (
              <Grid
                item
                xs={12}
                sm={12}
                md={11}
                container
                my={4}
                justifyContent={"flex-end"}
                sx={{ position: "relative" }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  placeholder="Responder..."
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                      fontSize: 14,
                    },
                  }}
                />

                <IconButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    console.log("comment.id:", comment.id);
                    console.log("comment:", comment);
                    handleSubmitReply(comment.id, comment);
                  }}
                >
                  <MdSend />
                </IconButton>
                <IconButton
                  onClick={handleCancelReply}
                  sx={{ position: "absolute", top: 0 }}
                >
                  <MdClose />
                </IconButton>
              </Grid>
            )}

            <IconButton onClick={() => toggleReplies(index, comment.id)}>
              <Typography variant="caption" sx={{ color: "black" }}>
                {showReplies[index]
                  ? "Ocultar Respuestas"
                  : "Mostrar Respuestas"}
              </Typography>
            </IconButton>

            {showReplies[index] && (
              <Grid
                item
                xs={12}
                sm={12}
                md={11}
                container
                my={2}
                justifyContent={"flex-end"}
              >
                {/* Render replies here */}
                {replies[comment.id]?.map((reply, replyIndex) => (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        alignItems: "center",
                        mr: 2,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ fontSize: 12, color: theme.palette.primary.main }}
                      >
                        {comment.username}
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: 12 }}>
                        {comment?.cdate &&
                          new Date(comment.cdate.toDate()).toLocaleDateString()}
                      </Typography>
                    </Box>
                    <Box
                      key={replyIndex}
                      sx={{
                        p: 2,
                        border: "1px solid #CBCBCB",
                        borderRadius: "12px",
                        minHeight: 100,
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        backgroundColor: "#fafafa",
                      }}
                    >
                      <Typography sx={styles.commentText}>
                        {reply.text}
                      </Typography>
                    </Box>
                  </>
                ))}
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
      <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <Grid item container xs={12} alignContent={"center"}>
          <Typography mr={2} mb={2}>
            Escribe un comentario
          </Typography>
        </Grid>
        <Grid item container sx={styles.commentBox}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              fullWidth
              id="comment"
              name="comment"
              label=""
              variant="outlined"
              multiline
              rows={4}
              value={formik.values.comment}
              onChange={formik.handleChange}
              InputProps={{
                style: {
                  borderRadius: "12px",
                  fontSize: 14,
                },
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            container
            justifyContent={"flex-end"}
            mt={4}
          >
            <IconButton
              variant="contained"
              color="primary"
              onClick={formik.handleSubmit}
            >
              {/* <Typography variant="caption" sx={{ color: "black" }}>
                Enviar
              </Typography> */}
              <MdSend />
            </IconButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
