import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  Container,
  Typography,
  Grid,
  useTheme,
  CircularProgress,
  Chip,
  Pagination,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { getTagPosts, setBlogs } from "../store/actions/blogs";
import { useDispatch, useSelector } from "react-redux";
import { ASbutton } from "../components/buttons/ASbutton";
import BlogItem from "../components/blogs/BlogItem";
import { styles } from "../styles/styles";
import SignInModal from "../components/modals/SignInModal";
import { MdArrowBack } from "react-icons/md";

const TagsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { tag } = useParams();
  //const { blogs } = useSelector((state) => state.blogs);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTag, setSelectedTag] = useState(tag);
  const [tagPosts, setTagPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);

  const postsPerPage = 5;

  const getPostsForTag = useCallback(async () => {
    try {
      const postFromTag = await getTagPosts(tag);
      setTagPosts(postFromTag);
    } catch (error) {
      console.error(error);
    }
  }, [selectedTag]);

  const handleOpenLoginModal = () => {
    setOpen(true);
  };

  const handleCloseLoginModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setSelectedTag(tag);
    const fetchData = async () => {
      await getPostsForTag();
      setIsLoading(false);
    };
    fetchData();
  }, [selectedTag, getPostsForTag, tag]);

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);

  const handleTagClick = (clickedTag) => {
    setSelectedTag(clickedTag);
    setPage(1); // Reset pagination to page 1 when tag changes
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleGotoBlogs = () => {
    navigate(-1);
  };

  // Get the current posts based on pagination
  const getCurrentPosts = () => {
    const startIndex = (page - 1) * postsPerPage;
    const endIndex = startIndex + postsPerPage;
    return tagPosts.slice(startIndex, endIndex);
  };

  return (
    <Container maxWidth={"md"} py={4} sx={{ minHeight: "100vh" }}>
      <Grid
        container
        justifyContent={"center"}
        sx={{
          mt: 16,
          mb: 16,
          p: 2,
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          //border: "1px solid #CBCBCB",
          borderRadius: "18px",
        }}
      >
        <Grid
          item
          container
          md={12}
          pb={2}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <IconButton onClick={handleGotoBlogs}>
            <MdArrowBack color={theme.palette.primary.main} />
          </IconButton>
        </Grid>
        <Grid
          container
          item
          md={12}
          alignContent={"flex-start"}
          p={4}
          sx={{ minHeight: "75vh" }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h3"
              gutterBottom
              sx={styles.blogsMainTitle}
              mb={6}
            >
              {selectedTag}
            </Typography>

            {/* {selectedTag && (
              <Grid item mt={4} md={12}>
                <Chip
                  label={selectedTag}
                  onDelete={() => handleTagClick(null)}
                />
              </Grid>
            )} */}
          </Grid>

          {isLoading ? (
            <Grid item xs={12} my={4}>
              <Grid
                container
                spacing={2}
                alignItems="flex-start"
                pt={8}
                justifyContent={"center"}
                sx={{ minHeight: "75vh" }}
              >
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <>
              {getCurrentPosts()?.map((post) => (
                <Grid item key={post.id} md={12} mt={8}>
                  <BlogItem
                    item={post}
                    handleTagClick={handleTagClick}
                    handleOpenLoginModal={handleOpenLoginModal}
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>
        <Grid
          container
          md={11}
          justifyContent={"flex-end"}
          alignContent={"center"}
          sx={{
            pt: 2,
          }}
        >
          <Pagination
            count={Math.ceil(tagPosts.length / postsPerPage)}
            variant="outlined"
            shape="rounded"
            page={page}
            onChange={handlePageChange}
          />
        </Grid>
      </Grid>
      <SignInModal
        show={open}
        setOpenSignUp={handleOpenLoginModal}
        handleClose={handleCloseLoginModal}
      />
    </Container>
  );
};
export default TagsPage;
