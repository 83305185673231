import { Col, Container } from "react-bootstrap";

import BenefitsCard from "../cards/BenefitsCard";
import { useEffect, useState, useRef } from "react";
import { getBannersImgs, getCarouselImgs } from "../../store/actions/benefits";

import "keen-slider/keen-slider.min.css";

import darkCoin from "../../assests/darkCoin.gif";

import "./benefits.scss";
import BannersCarousel from "../bannerCarousel/BannersCarousel";
import BottomCarousel from "../bannerCarousel/BottomCarousel";
import { Box, Typography, useTheme } from "@mui/material";

const Benefits = () => {
  const theme = useTheme();
  const [wellnessBanners, setWellnessBanners] = useState(null);
  const [wellnessCarousel, setWellnessCarousel] = useState(null);

  useEffect(() => {
    const getImages = async () => {
      const wellnessBanners = await getBannersImgs();
      const wellnessCarousel = await getCarouselImgs();

      setWellnessBanners(wellnessBanners);
      setWellnessCarousel(wellnessCarousel);
    };

    getImages();
  }, []);

  if (!wellnessBanners && !wellnessCarousel) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <h3>Cargando...</h3>
        <img
          src={darkCoin}
          alt="Moneda girando"
          style={{ width: "150px", height: "150px" }}
        />
      </div>
    );
  }

  return (
    <>
      <Box sx={{ width: "100%", mb: 2, p: 4 }}>
        <Typography
          variant="h3"
          color={theme.palette.primary.main}
          sx={{
            width: "85%",
            fontWeight: "600",
            fontSize: 28,
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              fontWeight: "600",
              fontSize: 24,
            },
          }}
        >
          Descarga gratis nuestra APP y disfruta de todos los beneficios
        </Typography>
      </Box>
      <Container
        fluid
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <div className=" keen-carousel small mb-5"> */}
        <div>
          <BannersCarousel wellnessCarousel={wellnessBanners} />
        </div>

        <div className=" keen-carousel large mb-5">
          <BottomCarousel wellnessCarousel={wellnessCarousel} />
        </div>
      </Container>
    </>
  );
};

export default Benefits;
