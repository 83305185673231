import React, { useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { ASbutton } from "../buttons/ASbutton";
import { styles } from "../../styles/styles";
import FileUpload from "../general/FilesUpload";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { uploadFiles } from "../../store/actions/user";

const MySwal = withReactContent(Swal);
const Documentation = ({ handleNext, id }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [files, setFiles] = useState("");
  const [loading, setloading] = useState(false);

  const handleFileUpload = async () => {
    MySwal.fire({
      title: "Continuar? ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      const loadingSwal = MySwal.fire({
        title: "Guardando...",
        html: '<div class="swal2-spinner"></div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        // didOpen: () => {
        //   MySwal.showLoading();
        // },
      });
      if (result.isConfirmed) {
        try {
          if (files) {
            await uploadFiles(id, files);
            loadingSwal.close();
            MySwal.fire("Envío completo!", "", "success");
          } else {
          }
        } catch (error) {
          console.log(error);
          loadingSwal.close();
          MySwal.fire("Error", "", "error");
        }

        setloading(false);
      }
    });
  };

  const renderGrid = () => {
    // Render the FileUploadComponent here
    return <FileUpload selectedFiles={files} setSelectedFiles={setFiles} />;
  };

  return (
    <Box sx={styles.formContainer}>
      <Box sx={styles.plansCard}>
        <Box sx={styles.formTitle} mb={4}>
          <Typography
            variant="h7"
            sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
          >
            Selecciona la documentación (opcional)
          </Typography>
        </Box>
        {renderGrid()}
        <Grid
          container
          sx={styles.buttonsContainer}
          justifyContent={"flex-end"}
          direction="row"
        >
          <Grid item>
            <Box ml={2}>
              <ASbutton
                onClick={() => handleFileUpload()}
                backgroundColor={theme.palette.primary.main}
                color={"white"}
                buttonText={"Actualizar"}
                disabled={!files}
              >
                Submit
              </ASbutton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Documentation;
