import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Grid,
  useTheme,
  CircularProgress,
  Box,
  Avatar,
  IconButton,
} from "@mui/material";

import {
  addLike,
  getBlogPostById,
  getBlogsCategories,
  getCommentsFromPost,
  getLikesFromPost,
  getViewsFromPost,
  setBlogs,
} from "../store/actions/blogs";
import { useDispatch, useSelector } from "react-redux";
import { ASbutton } from "../components/buttons/ASbutton";
import { useNavigate } from "react-router-dom";
import { styles } from "../styles/styles";
import ReactPlayer from "react-player";
import { ImageCarousel } from "../components/blogs/ImageCarousel";
import { ActionBar } from "../components/blogs/ActionBar";
import { CommentsSection } from "../components/blogs/CommentsSection";
import { db } from "../firebase/firebase-config";
import { MdArrowBack } from "react-icons/md";

import watermarkImage from "../assests/logo.png"; // Import your watermark image

const PostsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { postId, cat } = useParams();
  const user = useSelector((state) => state.auth.user);
  const { userInfo } = useSelector((state) => state.user);
  const [views, setViews] = useState([]);
  const [open, setOpen] = useState(false);

  const { blogs, postsByCategory } = useSelector((state) => state.blogs);
  const [post, setPost] = useState();

  const [comments, setComments] = useState([]);
  const [likes, setLikes] = useState([]);

  const [userLiked, setUserLiked] = useState(false);
  const [videoId, setVideoId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showComments, setShowComments] = useState(false);
  const [displayComments, setDisplayComments] = useState(false);

  function getIdFromVimeoURL(videoURL) {
    return /http(s)?:\/\/(www\.)?vimeo.com\/(\d+)(\/)?(#.*)?/.exec(videoURL)[3];
  }

  useEffect(() => {
    const dpCommentsDocRef = db.collection("utils").doc("blog");

    const unsubscribe = dpCommentsDocRef.onSnapshot((docSnapshot) => {
      if (docSnapshot.exists) {
        const dpCommentsData = docSnapshot.data();
        const displayComments = dpCommentsData.displayComments || false;
        // Set the displayComments state
        setDisplayComments(displayComments);
      } else {
        // If the document doesn't exist, you may want to handle this case accordingly
        console.error("Document doesn't exist in the firestore collection.");
      }
    });

    // Return the unsubscribe function in case you need to unsubscribe later
    return unsubscribe;
  }, []);

  const getPostComments = useCallback(
    async (postId, parentId) => {
      const postCommentsDB = await getCommentsFromPost(parentId, postId);
      const postLikesDB = await getLikesFromPost(parentId, postId);
      const postViewsDB = await getViewsFromPost(parentId, postId);
      return {
        comments: postCommentsDB,
        likes: postLikesDB,
        views: postViewsDB,
      };
    },
    [likes, comments, views]
  );

  const fetchPostAndComments = useCallback(async () => {
    try {
      if (!blogs) {
        return;
      }

      const parentId = blogs.find((blog) => blog.name === cat)?.id;

      // console.log("cat:", cat);
      // console.log("blogs:", blogs);
      // console.log("parentId:", parentId);
      if (parentId && postId) {
        const postDB = await getBlogPostById(parentId, postId);

        setPost(postDB);
        const {
          comments: postComments,
          likes: postLikes,
          views: postViews,
        } = await getPostComments(postId, parentId);
        setComments(postComments);
        setLikes(postLikes);
        setViews(postViews);
        setUserLiked(postLikes.some((like) => like.userId === user.uid));
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching post and comments:", error);
      // Handle error if necessary
      setIsLoading(false); // Ensure to set isLoading to false in case of error
    }
  }, [
    dispatch,
    postsByCategory,
    getPostComments,
    blogs,
    postId,
    cat,
    userLiked,
    views,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogsDB = await getBlogsCategories();
        dispatch(setBlogs(blogsDB));
        // console.log("blogsDB:", blogsDB);
        // Call fetchPostAndComments after getBlogsCategories() completes
      } catch (error) {
        console.error("Error fetching blogs:", error);
        // Handle error if necessary
      }
    };

    fetchData(); // Call fetchData() to start fetching data
  }, []);

  useEffect(() => {
    if (blogs) {
      fetchPostAndComments(); // Call fetchPostAndComments when blogs data is available
    }
  }, [blogs]);

  useEffect(() => {
    if (post && post.postType === "video") {
      if (post.videoURL !== "") {
        if (post.videoURL.includes("youtube")) {
          setVideoId(
            post.videoURL.substring(
              post.videoURL.lastIndexOf("v=") + 2,
              post.videoURL.lastIndexOf("&")
            )
          );
        } else {
          setVideoId(getIdFromVimeoURL(post.videoURL));
        }
      }
    }
  }, [post]);

  useEffect(() => {
    // Check if the user has liked the post
    if (post) {
      setUserLiked(likes.some((like) => like.userId === user.uid));
    } else {
      setUserLiked(false);
    }
  }, [post, likes, user, userLiked]);

  const handleShowComments = () => {
    setShowComments((prevShowComments) => !prevShowComments);
  };
  const handleLikePost = async () => {
    const blogId = post.parentId;
    const postId = post.id;

    try {
      // Check if the user has already liked the post
      const alreadyLiked = likes.some((like) => like.userId === user.uid);

      if (alreadyLiked) {
        // If the user has already liked the post, remove their like
        // You can filter out the like with the user's ID
        const updatedLikes = likes.filter((like) => like.userId !== user.uid);
        setLikes(updatedLikes);
      } else {
        // If the user has not liked the post, add their like
        // You can append a new like object with the user's ID
        const liked = await addLike(blogId, postId, { userId: user.uid });
        setLikes((prevLikes) => [...prevLikes, { userId: user.uid }]);
      }

      // Toggle the userLiked state based on whether the user has already liked the post
      setUserLiked(!alreadyLiked);
    } catch (error) {
      console.error("Error liking post:", error);
    }
  };

  const renderContent = (content) => {
    // Render each paragraph as a separate <Text> component with a space between them
    return content?.map((paragraph, index) => (
      <React.Fragment key={index}>
        <Typography sx={styles.contentParagraph}>
          {"\t"}
          {paragraph}
        </Typography>

        {index !== content.length - 1 && <Typography> </Typography>}
      </React.Fragment>
    ));
  };

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };
  const handleGotoBlogs = () => {
    navigate(-1);
  };
  const handleTagClick = (tag) => {
    navigate(`/blogs/tags/${tag}`);
  };

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);

  if (isLoading || !postId) {
    return (
      <Container maxWidth={"md"}>
        <Grid
          container
          alignItems="center"
          justifyContent={"center"}
          mt={15}
          mb={4}
          sx={{
            // backgroundColor: "red",
            p: 4,
            minHeight: "100vh",
            border: "1px solid #CBCBCB",
            borderRadius: "18px",
          }}
        >
          <Box>
            <CircularProgress />
          </Box>
        </Grid>
      </Container>
    );
  }

  const Watermark = () => (
    <Grid
      container
      alignItems="center"
      sx={{
        position: "absolute",

        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "transparent", // Set background color to transparent
        opacity: 0.5, // Adjust the opacity as needed
        pointerEvents: "none", // Ensure the watermark doesn't interfere with user interaction
      }}
    >
      <Grid
        item
        md={12}
        container
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          component="img"
          src={watermarkImage}
          alt="Watermark"
          sx={{
            width: "100%",
            maxWidth: "650px",
            opacity: 0.4, // Set opacity as a number between 0 and 1
            filter: "saturate(0.5)", // Apply saturate filter
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Container maxWidth={"md"} sx={{ minHeight: "100vh" }}>
        <Grid
          container
          alignItems="center"
          justifyContent={"center"}
          mt={15}
          mb={4}
          sx={{
            // backgroundColor: "red",
            // overlay
            p: 4,

            //border: "1px solid #CBCBCB",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            borderRadius: "18px",
            position: "relative",
          }}
        >
          <Grid
            item
            container
            md={12}
            pb={2}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <IconButton onClick={handleGotoBlogs}>
              <MdArrowBack color={theme.palette.primary.main} />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Box
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Avatar
                alt={"sponsor"}
                src={
                  post?.sponsorImageURL
                    ? post?.sponsorImageURL
                    : "https://picsum.photos/200/300"
                }
                sx={{ width: 32, height: 32, mr: 2 }}
              />
              <Typography
                variant="h4"
                sx={[styles.title, { marginBottom: 0 }]}
                mr={2}
              >
                {post?.sponsorName ? post.sponsorName : "Sponsor"}
              </Typography>
              <Typography variant="h4" sx={styles.dateText}>
                {post?.cdate &&
                  new Date(post.cdate.toDate()).toLocaleDateString()}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} mt={4}>
            <Typography
              variant="h3"
              sx={[styles.blogsMainTitle, { marginBottom: 0 }]}
              mr={2}
            >
              {post?.name ? post.name : "Sin Título"}
            </Typography>
          </Grid>
          {post?.postType === "image" ? (
            post?.imageURLs?.length > 1 ? (
              <ImageCarousel
                items={post?.imageURLs}
                handleOpenModal={handleOpenModal}
              />
            ) : (
              <Grid
                container
                item
                md={10}
                my={4}
                justifyContent={"center"}
                alignContent={"center"}
              >
                <img
                  src={post?.imageURLs[0]}
                  style={styles.postImage}
                  resizeMode="cover"
                />
              </Grid>
            )
          ) : (
            <Grid
              container
              width="100%"
              md={12}
              my={4}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <div className="player-wrapper">
                <ReactPlayer
                  width="100%"
                  height="100%"
                  className="post-player"
                  url={post?.videoURL}
                />
              </div>
            </Grid>
          )}

          <Grid item xs={12} sx={{ position: "relative" }}>
            <Watermark />
            <Box sx={styles.textContainer}>{renderContent(post?.content)}</Box>
          </Grid>

          <Grid
            item
            container
            xs={12}
            sx={styles.tagsContainer}
            direction={"row"}
            gap={4}
          >
            {/* Display tags */}
            {post?.tags &&
              post?.tags.map((tag, index) => (
                <ASbutton
                  key={index}
                  variant="outlined"
                  color="primary"
                  buttonText={tag}
                  onClick={() => handleTagClick(tag)}
                />
              ))}
          </Grid>
          {/* <Grid
        item
        md={12}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          maxHeight: 30,
        }}
      >
        {item.tags.map((tag, index) => (
          <Button
            key={index}
            variant="outlined"
            sx={styles.tag}
            onClick={() => handleTagClick(tag)}
          >
            {tag}
          </Button>
        ))}
      </Grid> */}
          <ActionBar
            likes={likes}
            views={views}
            comments={comments}
            userLiked={userLiked}
            handleShowComments={handleShowComments}
            handleLikePost={handleLikePost}
            displayComments={displayComments}
          />
        </Grid>
        <Grid container alignItems="center" mt={3} mb={4}>
          {showComments && displayComments && (
            <CommentsSection
              comments={comments}
              setComments={setComments}
              post={post}
              user={user}
              userInfo={userInfo}
            />
          )}
        </Grid>
      </Container>
    </>
  );
};

export default PostsPage;
