import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../shared/ASFooter";
// Include your footer component

const HomeLayout = ({ children }) => {
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default HomeLayout;
