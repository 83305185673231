import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import { alpha, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/actions/auth";
import Swal from "sweetalert2"; // Import Swal
function MenuButton({ item, open, disabled }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const horizontal = useMediaQuery(theme.breakpoints.down("md"));
  const navigateTo = (link) => {
    navigate(link);
  };
  const location = useLocation();

  const activeStyle = {
    color: theme.palette.primary.main,
  };
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleLogout = () => {
    Swal.fire({
      title: "Cerrar Sesión",
      text: "¿Estás seguro de que quieres cerrar sesión?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logoutUser());
        navigate("/home", { replace: true });
      }
    });
  };
  return (
    <ListItemButton
      key={item.title}
      disabled={disabled}
      onClick={
        item.title === "Cerrar Sesión"
          ? handleLogout
          : () => navigateTo(item.route)
      }
      className={item.route === `${location.pathname}` ? "isActive" : ""}
      sx={{
        minHeight: 48,
        width: "100%",
        alignItems: "center",
        justifyContent: open ? "initial" : "center",
        px: 2.5,
        borderRadius: "18px",
        borderBottomRightRadius: item.title === "Perfil" ? 0 : "18px",
        borderBottomLeftRadius: item.title === "Perfil" ? 0 : "18px",
        mb:0.5,
        backgroundColor:
          item.route === `${location.pathname}` && theme.palette.primary.main,

        color: item.route === `${location.pathname}` ? "white" : "grey",
        "&:hover": {
          backgroundColor: alpha(theme.palette.primary.main, 0.65),
          color: "white",
        },
      }}
    >
      {item.icon && (
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: mobile ? 0 : 3,
            color: item.route === `${location.pathname}` && "white",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {item.icon}
        </ListItemIcon>
      )}

      {!horizontal && <ListItemText primary={item.title} />}
    </ListItemButton>
  );
}

export default MenuButton;
