import { Routes, Route, Navigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { PrivateRoute } from "./PrivateRoute";
import {
  analytics,
  auth,
  initFirebaseAnalytics,
} from "../firebase/firebase-config";
import { HomePage } from "../pages/HomePage";
import { useEffect } from "react";

import darkCoin from "../assests/darkCoin.gif";

import {
  setIsLoadingAuth,
  logoutUser,
  signInWithEmail,
} from "../store/actions/auth";
import { ProfilePage } from "../pages/ProfilePage";
import ProfileLayout from "../components/profile/ProfileLayout";
import ActivePlans from "../components/profile/ActivePlans";
import Billing from "../components/profile/Billing";
import UserBenefits from "../components/profile/UserBenefits";
import Inquiries from "../components/profile/Inquiries";
import AboutUsPage from "../pages/AboutUsPage";
import ContactPage from "../pages/ContactPage";
import FAQPage from "../pages/FAQPage";
import Settings from "../components/profile/Settings";
import HomeLayout from "./HomeLayout";
import { TermsPage } from "../pages/Terms";
import { PrivacyPage } from "../pages/PrivacyPage";
import { InfoCoinsPage } from "../pages/InfoCoinsPage";
import { RulesPage } from "../pages/RulesPage";
import PlansPage from "../pages/PlansPage";
import { DownloadPage } from "../pages/DownloadPage";
import DeletePage from "../pages/DeletePage";
import BlogsPage from "../pages/BlogsPage";
import PostsPage from "../pages/PostsPage";
import CategoryBlogPage from "../pages/CategoryBlogPage";
import TagsPage from "../pages/TagsPage";
import PaymentHistory from "../components/profile/paymetHistory/PaymentHistory";

const Router = () => {
  const { user, isLoadingAuth, loggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let location = useLocation();
  useEffect(() => {
    // Initialize Firebase Analytics
    initFirebaseAnalytics()
      .then((analytics) => {
        // Track a custom event for page view
        analytics.logEvent("page_view", { page_name: "ASLANDING" });
      })
      .catch((error) => {
        console.error("Error initializing Firebase Analytics", error);
      });

    // Track the "landing_user_navigatedTo_[screenName]" event for each page
    const screenName = location.pathname.replace("/", ""); // Get the screen name from the route
    const event = `landing_user_navigatedTo_${screenName}`;

    initFirebaseAnalytics()
      .then((analytics) => {
        analytics.logEvent(event, { page_name: screenName });
      })
      .catch((error) => {
        console.error(`Error tracking event ${event}`, error);
      });
  }, [location]);

  useEffect(() => {
    auth.onAuthStateChanged(async (userFromAuth) => {
      if (userFromAuth) {
        dispatch(setIsLoadingAuth(true));
        const idToken = await userFromAuth.getIdToken();

        dispatch(signInWithEmail(userFromAuth.toJSON(), true, idToken));
        sessionStorage.setItem("user", JSON.stringify(userFromAuth.toJSON()));
        dispatch(setIsLoadingAuth(false));
      } else {
        sessionStorage.removeItem("user");
        dispatch(logoutUser());
        dispatch(setIsLoadingAuth(false));
        console.log("notloggedin");
      }
    });
  }, [dispatch]);

  if (isLoadingAuth) {
    return (
      <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
        {loggedIn ? (
          <>
            <h3>Cargando...</h3>
            <img
              src={darkCoin}
              alt="Moneda girando"
              style={{ width: "150px", height: "150px" }}
            />
          </>
        ) : null}
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<HomeLayout />}>
        <Route path="/" element={<Navigate to="home" replace />} />
        <Route path="home" element={<HomePage />} />
        <Route path="blogs" element={<BlogsPage />} />
        <Route path="/blogs/:cat/" element={<CategoryBlogPage />} />
        <Route path="/blogs/tags/:tag/" element={<TagsPage />} />
        <Route path="about-us" element={<AboutUsPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="faq" element={<FAQPage />} />
        <Route path="terminos-condiciones" element={<TermsPage />} />
        <Route path="politica-privacidad" element={<PrivacyPage />} />
        <Route path="reglamento-coins" element={<InfoCoinsPage />} />
        <Route path="reglamentos-promociones" element={<RulesPage />} />
        <Route path="plans" element={<PlansPage />} />
        <Route path="download" element={<DownloadPage />} />
        <Route path="delete" element={<DeletePage />} />
        <Route
          path="/blogs/:cat/posts/:postId"
          element={
            <PrivateRoute isLoggedIn={loggedIn}>
              <PostsPage />
            </PrivateRoute>
          }
        />
        <Route path="/profile" element={<ProfileLayout />}>
          <Route
            index
            element={
              loggedIn ? (
                <Navigate replace to="/profile/info" />
              ) : (
                <Navigate replace to="/home" />
              )
            }
          />
          <Route
            path="info"
            element={
              <PrivateRoute isLoggedIn={loggedIn}>
                <ProfilePage />
              </PrivateRoute>
            }
          />

          <Route
            path="plans"
            element={
              <PrivateRoute isLoggedIn={loggedIn}>
                <ActivePlans />
              </PrivateRoute>
            }
          />
          <Route
            path="billing"
            element={
              <PrivateRoute isLoggedIn={loggedIn}>
                <Billing />
                <PaymentHistory />
              </PrivateRoute>
            }
          />
          <Route
            path="benefits"
            element={
              <PrivateRoute isLoggedIn={loggedIn}>
                <UserBenefits />
              </PrivateRoute>
            }
          />

          <Route
            path="inquiries"
            element={
              <PrivateRoute isLoggedIn={loggedIn}>
                <Inquiries />
              </PrivateRoute>
            }
          />
          <Route
            path="settings"
            element={
              <PrivateRoute isLoggedIn={loggedIn}>
                <Settings />
              </PrivateRoute>
            }
          />
        </Route>
      </Route>
      {/* <Route path="terms" element={<Terms />} />
        <Route path="privacy" element={<Privacy />} /> */}
    </Routes>
  );
};

export default Router;
