import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
  useTheme,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { styles } from "../../styles/styles";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import { ASbutton } from "../../components/buttons/ASbutton";
import { MdNotificationImportant } from "react-icons/md";

import { Provinces as provinces } from "../../utils/provinces";
import { Cantons as cantons } from "../../utils/cantons";
import { Districts as districts } from "../../utils/districts";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFormik } from "formik";
import * as Yup from "yup";

import imgDrop from "../../assests/allSports-logo.png";
import UploadImage from "../general/UploadImage";
import dayjs from "dayjs";
import { fsTimestampCreador } from "../../firebase/firebase-config";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { editUser, setIsLoadingInfo } from "../../store/actions/user";
import { uploadPhoto, uploadUserImageToAuth } from "../../store/actions/auth";
import { useDispatch } from "react-redux";

const MySwal = withReactContent(Swal);

export const EditProfileForm = ({
  handleRefresh,
  data,

  loading,
  setLoading,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [filteredCantons, setFilteredCantons] = useState([]);
  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [parsedDate, setParsedDate] = useState(dayjs("2005-01-01"));
  const [imageUpload, setImageUpload] = useState("");
  const [image, setImage] = useState("");
  const [fileUrl, setFileUrl] = useState(null);

  const formatDate = (date) =>
    !date.seconds
      ? date // Already a javascript date object
      : date.toDate();

  useEffect(() => {
    if (data?.birthday) {
      // Convert the user's birthday (timestamp or string) to a Dayjs object
      const birthdayDayjs = formatDate(data.birthday);
      const parsed = dayjs(birthdayDayjs);
      setParsedDate(parsed);
      // console.log([parsed]);
    }
  }, [data]);

  useEffect(() => {
    // Update formik initial values and the image state when data prop changes
    formik.setValues({
      name: data?.name,
      lastname: data?.lastname,
      secondLastname: data?.secondLastname ?? "",
      email: data?.email ?? "",
      birthday: parsedDate,
      civilState: data?.civilState ?? "",
      gender: data?.gender ?? "",
      idType: data?.nationality ?? "",
      address: data?.address ?? "",
      province: data?.province ?? "",
      canton: data?.canton ?? "",
      district: data?.district ?? "",
      zipCode: data?.zipCode ?? "",
      device: data?.device ?? "",
      personalId: data?.personalId ?? "",
      phone: data?.phone ?? "",
    });
    setImage(data?.photoURL ?? "");
  }, [data]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
    lastname: Yup.string().required("Campo requerido"),
    // lastname2: Yup.string().required("Campo requerido"),
    phone: Yup.string().required("Campo requerido"),
    // email: Yup.string().required("Campo requerido").email("Email no válido"),

    civilState: Yup.mixed().required("Campo requerido"),
    gender: Yup.mixed().required("Campo requerido"),
    nationality: Yup.mixed().required("Campo requerido"),
    personalId: Yup.mixed().required("Campo requerido"),
    address: Yup.mixed().required("Campo requerido"),
    province: Yup.mixed().required("Campo requerido"),
    canton: Yup.mixed().required("Campo requerido"),
    district: Yup.mixed().required("Campo requerido"),
    zipCode: Yup.mixed().required("Campo requerido"),
  });

  const formik = useFormik({
    initialValues: {
      name: data?.name,
      lastname: data?.lastname,
      secondLastname: data?.secondLastname ?? "",
      email: data?.email ?? "",
      birthday: parsedDate,
      civilState: data?.civilState ?? "",
      gender: data?.gender ?? "",
      idType: data?.nationality ?? "",
      address: data?.address ?? "",
      province: data?.province ?? "",
      canton: data?.canton ?? "",
      district: data?.district ?? "",
      zipCode: data?.zipCode ?? "",
      device: data?.device ?? "",
      personalId: data?.personalId ?? "",
      phone: data?.phone ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try {
        handleRegister(values);
      } catch (error) {
        console.log(error);
      }
    },
  });
  const handleRegister = async (values) => {
    const timestampBirthday = fsTimestampCreador.fromDate(
      new Date(formik.values.birthday)
    );

    const updatedData = {
      name: values.name,
      lastname: values.lastname,
      secondLastname: values.secondLastname ?? "",

      birthday: timestampBirthday,
      civilState: values.civilState ?? "",
      gender: values.gender ?? "",
      idType: values.nationality ?? "",
      address: values.address ?? "",
      province: values.province ?? "",
      canton: values.canton ?? "",
      district: values.district ?? "",
      zipCode: data.zipCode ?? "",
      device: values.device ?? "",
      personalId: values.personalId ?? "",
      phone: values.phone ?? "",
    };

    const confirmResult = await MySwal.fire({
      title: "Confirmar edición",
      text: "¿Estás seguro de que deseas editar tu perfil?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, editar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    });

    if (confirmResult.isConfirmed) {
      setLoading(true);
      dispatch(setIsLoadingInfo(true));
      try {
        console.log(imageUpload);
        if (imageUpload !== "") {
          await uploadPhoto(data.userId, imageUpload);
        }

        await editUser(data.userId, updatedData);
        handleRefresh();

        MySwal.fire("Guardado!", "", "success");
      } catch (error) {
        MySwal.fire("Error", "Hubo un error al editar tu perfil", "error");
      }

      handleRefresh();
      setLoading(false);
      dispatch(setIsLoadingInfo(false));
    }
  };

  useEffect(() => {
    const selectedProvince = formik.values.province;

    if (selectedProvince) {
      const filterItems = cantons.filter(
        (canton) => canton.provinceName === selectedProvince
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });
      setFilteredCantons(filterItems);
    }
  }, [formik.values.province]);

  useEffect(() => {
    const selectedCanton = formik.values.canton;

    if (selectedCanton) {
      const filterItems = districts.filter(
        (district) => district.cantonName === selectedCanton
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });
      setFilteredDistricts(filterItems);
    }
  }, [formik.values.canton]);

  const civilState = [
    {
      value: "single",
      label: "Soltero",
    },
    {
      value: "married",
      label: "Casado",
    },
  ];
  const gender = [
    {
      value: "male",
      label: "Masculino",
    },
    {
      value: "female",
      label: "Femenino",
    },
    {
      value: "other",
      label: "Otro",
    },
  ];
  const nationality = [
    {
      value: "01",
      label: "Costarricense",
    },
    {
      value: "03",
      label: "Dimex",
    },
    {
      value: "04",
      label: "Nite",
    },
  ];
  const platform = [
    {
      value: "android",
      label: "Android",
    },
    {
      value: "ios",
      label: "IOS",
    },
  ];

  if (loading) {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "450px",
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box sx={styles.formContainer}>
      <form style={{ padding: 30 }}>
        <UploadImage
          loadUrl={setFileUrl}
          imageUpload={imageUpload}
          setImageUpload={setImageUpload}
          setImage={setImage}
          image={image}
        />
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                label="Nombre"
                type="name"
                id="name"
                name="name"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                label="Apellido"
                type="lastname"
                id="lastname"
                name="lastname"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastname}
                error={
                  formik.touched.lastname && Boolean(formik.errors.lastname)
                }
                helperText={formik.touched.lastname && formik.errors.lastname}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                label="Segundo Apellido"
                type="secondLastname"
                id="secondLastname"
                name="secondLastname"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondLastname}
                error={
                  formik.touched.secondLastname &&
                  Boolean(formik.errors.secondLastname)
                }
                helperText={
                  formik.touched.secondLastname && formik.errors.secondLastname
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Teléfono"
                name="phone"
                id="phone"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Email"
                type="email"
                id="email"
                name="email"
                variant="outlined"
                value={data?.email}
              />
            </FormControl>
          </Grid>
        </Grid>

        {/* DOB CIVIL */}

        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <DatePicker
                label="Fecha de Nacimiento"
                id="birthday"
                name="birthday"
                value={formik.values.birthday}
                onChange={(newValue) => {
                  formik.setFieldValue("birthday", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    error={
                      formik.touched.birthday && Boolean(formik.errors.birthday)
                    }
                    helperText={
                      formik.touched.birthday && formik.errors.birthday
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Seleccione"
                id="civilState"
                name="civilState"
                select
                value={formik.values.civilState}
                onChange={(e) =>
                  formik.setFieldValue("civilState", e.target.value)
                }
                error={
                  formik.touched.civilState && Boolean(formik.errors.civilState)
                }
                helperText="Seleccione el estado civil"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdNotificationImportant />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              >
                {civilState.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Seleccione"
                id="gender"
                name="gender"
                select
                value={formik.values.gender}
                onChange={(e) => formik.setFieldValue("gender", e.target.value)}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                helperText="Seleccione el género"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdNotificationImportant />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              >
                {gender.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>

        {/* NATIONALITY */}

        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Seleccione"
                id="nationality"
                name="nationality"
                select
                value={formik.values.nationality}
                onChange={(e) =>
                  formik.setFieldValue("nationality", e.target.value)
                }
                error={
                  formik.touched.nationality &&
                  Boolean(formik.errors.nationality)
                }
                helperText="Seleccione la nacionalidad"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdNotificationImportant />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              >
                {nationality.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <FormControl fullWidth>
              <TextField
                label={
                  formik.values.nationality === "01"
                    ? "Cédula Nacional"
                    : formik.values.nationality === "03"
                    ? "Dimex "
                    : formik.values.nationality === "03"
                    ? "Nite"
                    : ""
                }
                type="number"
                id="personalId"
                variant="outlined"
                onChange={(e) =>
                  formik.setFieldValue("personalId", e.target.value)
                }
                onBlur={formik.handleBlur}
                value={formik.values.personalId}
                error={
                  formik.touched.personalId && Boolean(formik.errors.personalId)
                }
                helperText={
                  formik.touched.personalId && formik.errors.personalId
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        {/* PROVINCIAS */}

        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Provincia"
                id="province"
                name="province"
                select
                value={formik.values.province}
                error={
                  formik.touched.province && Boolean(formik.errors.province)
                }
                onChange={(e) =>
                  formik.setFieldValue("province", e.target.value)
                }
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdNotificationImportant />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              >
                {provinces.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Cantón"
                id="canton"
                name="canton"
                select
                value={formik.values.canton}
                onChange={formik.handleChange}
                // error={formik.touched.canton && Boolean(formik.errors.canton)}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdNotificationImportant />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              >
                {filteredCantons.map((option) => (
                  <MenuItem key={option.label} value={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Distrito"
                id="district"
                name="district"
                select
                value={formik.values.district}
                onChange={formik.handleChange}
                error={
                  formik.touched.district && Boolean(formik.errors.district)
                }
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdNotificationImportant />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              >
                {filteredDistricts.map((option) => (
                  <MenuItem key={option.label} value={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
        {/* ADDRESS */}

        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={12} md={8}>
            <FormControl fullWidth>
              <TextField
                label="Dirección"
                type="address"
                id="address"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                label="Código Postal"
                id="zipCode"
                name="zipCode"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.zipCode}
                error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
              />
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Seleccione Sistema Operativo de el Dispositvo"
                id="device"
                name="device"
                select
                value={formik.values.device}
                onChange={(e) => formik.setFieldValue("device", e.target.value)}
                helperText={formik.touched.device && formik.errors.device}
                error={Boolean(formik.errors.device)}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdNotificationImportant />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              >
                {platform.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid> */}
        </Grid>
      </form>
      <Grid
        container
        sx={styles.buttonsContainer}
        justifyContent={"flex-end"}
        direction="row"
      >
        {/* <Grid item>
          <ASbutton
            type="submit"
            backgroundColor={"grey"}
            color={"white"}
            disabled={activeStep === 0}
            buttonText={"Cancelar"}
          />
        </Grid> */}
        <Grid item>
          <Box ml={2}>
            <ASbutton
              onClick={formik.handleSubmit}
              backgroundColor={theme.palette.primary.main}
              color={"white"}
              buttonText={"Guardar"}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
