import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { useNavigate } from "react-router-dom";
import "../benefits/benefits.scss";
import Arrow from "../arrow/Arrow";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useEffect, useState } from "react";

const BottomCarousel = ({ wellnessCarousel }) => {
  const navigate = useNavigate();
  const [snapped, setSnapped] = useState(true);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [screenSize, setScreenSize] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const responsive = {
    items: 3,
    itemsFit: "contain",
  };

  const handleMouseDown = (e) => {
    setStartPosition({ x: e.clientX, y: e.clientY });
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleClick = (link) => (e) => {
    if (!isDragging) {
      console.log("click", link);
      const { x: startX, y: startY } = startPosition;
      const { clientX: endX, clientY: endY } = e;

      const distance = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);
      const clickThreshold = 5;

      if (distance <= clickThreshold) {
        navigate(`/blogs/${link.name}`);
      }
    }
  };
  const handleSlideChanged = ({ item }) => {
    setSnapped(item === 0);
  };

  const handleDragStart = (e) => {
    setIsDragging(true);
    e.preventDefault();
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const items = wellnessCarousel.map((item) => {
    const isLarge = item.isLarge;
    return (
      <img
        src={item.imageURL}
        alt="Card image"
        onClick={handleClick(item)}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        className={`item benefits-mobile-bottom-img`}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        style={
          isLarge
            ? {
                width: "650px",
                height: "275px",
                padding: "0 10px",
                cursor: "pointer",
              }
            : {
                width: "245px",
                height: "300px",
                padding: "0 10px",
                cursor: "pointer",
                minWidth: "200px",
              }
        }
      />
    );
  });

  return (
    <div className="position-relative">
      <AliceCarousel
        autoWidth
        autoHeight
        disableDotsControls
        controlsStrategy={"responsive"}
        responsive={responsive}
        mouseTracking={true}
        touchMoveDefaultEvents={false}
        items={items}
        infinite
        renderPrevButton={() => {
          return (
            <span
              style={{
                position: "absolute",
                top: "45%",
                left: "20px", // Adjust the left position as needed
                zIndex: 1, // Ensure the arrows are above the carousel items
              }}
              className="d-none d-md-block"
            >
              <Arrow left />
            </span>
          );
        }}
        renderNextButton={() => {
          return (
            <span
              style={{
                position: "absolute",
                top: "45%",
                right: "20px", // Adjust the right position as needed
                zIndex: 1, // Ensure the arrows are above the carousel items
              }}
              className="d-none d-md-block"
            >
              <Arrow right />
            </span>
          );
        }}
      />
    </div>
  );
};

export default BottomCarousel;
