import { Container } from "react-bootstrap";

import medicalInsurance from "../../assests/insurance.svg";
import { useEffect, useState } from "react";

const MedicalInsurance = ({ productData, plan65 }) => {
  const [item, setItem] = useState({ text: "", info: "" });

  useEffect(() => {
    if (productData && !plan65) {
      setItem({
        id: "1",
        info: `Cubre el 100% de los Gastos Médicos derivados de un accidente hasta ${productData.data[0].value} Incluye cobertura por incapacidad permanente o perdida de extremidad ${productData.data[1].value} o muerte accidental ${productData.data[2].value}.`,
        text: "Seguro Médico 24/7 ",
      });
    } else {
      setItem({
        id: "1",
        info: `Cubre el 100% de los Gastos Médicos derivados de un accidente hasta ₡2.500.000. Incluye cobertura por incapacidad total y permanente hasta ₡15.000.000 y muerte accidental hasta ₡15.000.000. El monto final de las coberturas depende del plan seleccionado en la modalidades Bronce, Plata y Oro.`,
        text: "Seguro Médico 24/7 ",
      });
    }
  }, []);

  return (
    <Container>
      <img
        src={medicalInsurance}
        alt=""
        className="d-block mx-auto"
        width="200px"
      />
      <h4 className="fw-bold text-white">{item.text}</h4>
      <p className="text-white">{item.info}</p>
    </Container>
  );
};

export default MedicalInsurance;
