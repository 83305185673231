const authErrors = {
  "auth/user-not-found": {
    msg: "El email es incorrecto o el usuario no existe",
    isValidationError: true,
  },
  "auth/wrong-password": {
    msg: "Contraseña incorrecta",
    isValidationError: true,
  },
  "auth/too-many-requests": {
    msg: "Acceso a esta cuenta desactivado debido a demasiados intentos",
    isValidationError: true,
  },
  "auth/email-already-in-use": {
    msg: "Ya existe un usuario asociado al correo proporcionado",
    isValidationError: true,
  },
  "no-error": {
    msg: "",
    isValidationError: false,
  },
};

export default authErrors;
