import * as React from "react";
const Plus = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#FBB040"}
      d="M16.57 8.429c-.162-1.152-.854-1.547-1.217-1.668-.574-.212-1.419-.217-2.69-.025-.255.025-.805.09-1.429.172.226-.773.418-1.405.565-1.89.756-2.471.805-3.204.741-3.628-.083-.571-.348-.9-.555-1.071-.22-.182-.608-.385-1.178-.299C9.555.207 8.613 1.178 8.01 2.896 7.562 4.144 7.07 5.7 6.546 7.524l-.093.015c-3.25.561-4.055.768-4.418.9-1.277.49-1.91 1.475-1.733 2.708.063.455.245.854.55 1.193.525.576 1.197.823 1.934.712.329-.05.736-.156 1.237-.333.176-.06.53-.162 1.237-.319-1.021 4.235-1.085 5.412-1.007 5.978a1.87 1.87 0 0 0 .855 1.33 1.83 1.83 0 0 0 1.33.282c.53-.08 1.276-.414 1.802-1.546.275-.591.554-1.607.854-3.113.26-1.303.545-2.562.84-3.754l.289-.036c2.891-.434 3.451-.47 3.588-.47.482-.015.81-.035 1.031-.07.806-.122 1.228-.546 1.439-.885.28-.444.373-1.01.28-1.682l.01-.005Z"
    />
  </svg>
);
export default Plus;
