import * as yup from "yup";

import { Districts as districts } from "../utils/districts";

const idTypesData = ["Nacional", "Dimex", "Nite"];

export const personalInformationFormSchema = yup.object({
  province: yup.string().required("Campo Obligatorio"),
  canton: yup.string().required("Campo Obligatorio"),
  district: yup.string().required("Campo Obligatorio"),
  idType: yup
    .string()
    .oneOf(idTypesData, "Debe seleccionar una de las opciones")
    .required("Campo Obligatorio"),
  personalId: yup.number().required("Campo Obligatorio"),
  termsAndConditions: yup
    .boolean()
    .oneOf([true], "Debe aceptar antes de continuar"),
});
