import { SET_BLOGS, SET_POSTS, SET_TAG_SELECTED } from "../actions/blogs";

const initialState = {
  postsByCategory: {}, // Object to store posts by category
  blogs: null,
  tagSelected: "",
};

export const blogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POSTS:
      return {
        ...state,
        postsByCategory: {
          ...state.postsByCategory,
          ...action.postsByCategory, // Merge new posts into existing posts by category
        },
      };

    case SET_BLOGS:
      return {
        ...state,
        blogs: action.blogs,
      };
    case SET_TAG_SELECTED:
      return {
        ...state,
        tagSelected: action.tagSelected,
      };

    default:
      return state;
  }
};
