import { Stack, Button, Row, Col } from "react-bootstrap";

import { signUpFormFormSchema } from "../../schemas/signupSchema";
import { useDispatch } from "react-redux";
import { signUpWithEmailAndPassword } from "../../store/actions/auth";

import runner from "../../assests/runner-girl.jpg";

import "./podium.scss";
import InsuranceSummary from "../InsuranceSummary/InsuranceSummary";

const Podium = ({ handleNext, plan }) => {
  const dispatch = useDispatch();

  return (
    <div
      style={{
        backgroundColor: "#f5f6ff",
        borderRadius: "55px",
        height: "100%",
      }}
    >
      <Row style={{ height: "100%" }}>
        <Col md="7">
          <img
            src={plan.imgURL}
            alt="Medalla"
            width={"300px"}
            style={{ transform: "translateY(50%)" }}
          />
        </Col>
        <Col>
          <div
            className="position-absolute podium"
            style={{ width: "35%", top: "20px", right: "20px" }}
          >
            <img
              src={runner}
              alt=""
              style={{ borderRadius: "55px", width: "100%" }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Podium;
