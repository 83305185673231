export const Districts = [
  {
    provinceId: 1,
    cantonId: 101,
    districtId: 10101,
    label: "Carmen",
    value: 10101,
    cantonName: "San José",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 101,
    districtId: 10102,
    label: "Merced",
    value: 10102,
    cantonName: "San José",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 101,
    districtId: 10103,
    label: "Hospital",
    value: 10103,
    cantonName: "San José",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 101,
    districtId: 10104,
    label: "Catedral",
    value: 10104,
    cantonName: "San José",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 101,
    districtId: 10105,
    label: "Zapote",
    value: 10105,
    cantonName: "San José",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 101,
    districtId: 10106,
    label: "San Francisco de Dos Ríos",
    value: 10106,
    cantonName: "San José",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 101,
    districtId: 10107,
    label: "Uruca",
    value: 10107,
    cantonName: "San José",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 101,
    districtId: 10108,
    label: "Mata Redonda",
    value: 10108,
    cantonName: "San José",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 101,
    districtId: 10109,
    label: "Pavas",
    value: 10109,
    cantonName: "San José",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 101,
    districtId: 10110,
    label: "Hatillo",
    value: 10110,
    cantonName: "San José",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 101,
    districtId: 10111,
    label: "San Sebastián",
    value: 10111,
    cantonName: "San José",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 102,
    districtId: 10201,
    label: "Escazú",
    value: 10201,
    cantonName: "Escazú",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 102,
    districtId: 10202,
    label: "San Antonio",
    value: 10202,
    cantonName: "Escazú",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 102,
    districtId: 10203,
    label: "San Rafael",
    value: 10203,
    cantonName: "Escazú",
    provinceName: "San José",
    latitude: "9.9287° N",
    longitude: "84.1479° W",
  },
  {
    provinceId: 1,
    cantonId: 103,
    districtId: 10301,
    label: "Desamparados",
    value: 10301,
    cantonName: "Desamparados",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 103,
    districtId: 10302,
    label: "San Miguel",
    value: 10302,
    cantonName: "Desamparados",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 103,
    districtId: 10303,
    label: "San Juan de Dios",
    value: 10303,
    cantonName: "Desamparados",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 103,
    districtId: 10304,
    label: "San Rafael Arriba",
    value: 10304,
    cantonName: "Desamparados",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 103,
    districtId: 10305,
    label: "San Antonio",
    value: 10305,
    cantonName: "Desamparados",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 103,
    districtId: 10306,
    label: "Frailes",
    value: 10306,
    cantonName: "Desamparados",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 103,
    districtId: 10307,
    label: "Patarrá",
    value: 10307,
    cantonName: "Desamparados",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 103,
    districtId: 10308,
    label: "San Cristóbal",
    value: 10308,
    cantonName: "Desamparados",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 103,
    districtId: 10309,
    label: "Rosario",
    value: 10309,
    cantonName: "Desamparados",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 103,
    districtId: 10310,
    label: "Damas",
    value: 10310,
    cantonName: "Desamparados",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 103,
    districtId: 10311,
    label: "San Rafael Abajo",
    value: 10311,
    cantonName: "Desamparados",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 103,
    districtId: 10312,
    label: "Gravilias",
    value: 10312,
    cantonName: "Desamparados",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 103,
    districtId: 10313,
    label: "Los Guido",
    value: 10313,
    cantonName: "Desamparados",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 104,
    districtId: 10401,
    label: "Santiago",
    value: 10401,
    cantonName: "Puriscal",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 104,
    districtId: 10402,
    label: "Mercedes Sur",
    value: 10402,
    cantonName: "Puriscal",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 104,
    districtId: 10403,
    label: "Barbacoas",
    value: 10403,
    cantonName: "Puriscal",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 104,
    districtId: 10404,
    label: "Grifo Alto",
    value: 10404,
    cantonName: "Puriscal",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 104,
    districtId: 10405,
    label: "San Rafael",
    value: 10405,
    cantonName: "Puriscal",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 104,
    districtId: 10406,
    label: "Candelarita",
    value: 10406,
    cantonName: "Puriscal",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 104,
    districtId: 10407,
    label: "Desamparaditos",
    value: 10407,
    cantonName: "Puriscal",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 104,
    districtId: 10408,
    label: "San Antonio",
    value: 10408,
    cantonName: "Puriscal",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 104,
    districtId: 10409,
    label: "Chires",
    value: 10409,
    cantonName: "Puriscal",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 105,
    districtId: 10501,
    label: "San Marcos",
    value: 10501,
    cantonName: "Tarrazú",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 105,
    districtId: 10502,
    label: "San Lorenzo",
    value: 10502,
    cantonName: "Tarrazú",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 105,
    districtId: 10503,
    label: "San Carlos",
    value: 10503,
    cantonName: "Tarrazú",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 106,
    districtId: 10601,
    label: "Aserrí",
    value: 10601,
    cantonName: "Aserrí",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 106,
    districtId: 10602,
    label: "Tarbaca",
    value: 10602,
    cantonName: "Aserrí",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 106,
    districtId: 10603,
    label: "Vuelta de Jorco",
    value: 10603,
    cantonName: "Aserrí",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 106,
    districtId: 10604,
    label: "San Gabriel",
    value: 10604,
    cantonName: "Aserrí",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 106,
    districtId: 10605,
    label: "Legua",
    value: 10605,
    cantonName: "Aserrí",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 106,
    districtId: 10606,
    label: "Monterrey",
    value: 10606,
    cantonName: "Aserrí",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 106,
    districtId: 10607,
    label: "Salitrillos",
    value: 10607,
    cantonName: "Aserrí",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 107,
    districtId: 10701,
    label: "Colón",
    value: 10701,
    cantonName: "Mora",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 107,
    districtId: 10702,
    label: "Guayabo",
    value: 10702,
    cantonName: "Mora",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 107,
    districtId: 10703,
    label: "Tabarcia",
    value: 10703,
    cantonName: "Mora",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 107,
    districtId: 10704,
    label: "Piedras Negras",
    value: 10704,
    cantonName: "Mora",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 107,
    districtId: 10705,
    label: "Picagres",
    value: 10705,
    cantonName: "Mora",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 107,
    districtId: 10706,
    label: "Jaris",
    value: 10706,
    cantonName: "Mora",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 108,
    districtId: 10801,
    label: "Guadalupe",
    value: 10801,
    cantonName: "Goicoechea",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 108,
    districtId: 10802,
    label: "San Francisco",
    value: 10802,
    cantonName: "Goicoechea",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 108,
    districtId: 10803,
    label: "Calle Blancos",
    value: 10803,
    cantonName: "Goicoechea",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 108,
    districtId: 10804,
    label: "Mata de Plátano",
    value: 10804,
    cantonName: "Goicoechea",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 108,
    districtId: 10805,
    label: "Ipís",
    value: 10805,
    cantonName: "Goicoechea",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 108,
    districtId: 10806,
    label: "Rancho Redondo",
    value: 10806,
    cantonName: "Goicoechea",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 108,
    districtId: 10807,
    label: "Purral",
    value: 10807,
    cantonName: "Goicoechea",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 109,
    districtId: 10901,
    label: "Santa Ana",
    value: 10901,
    cantonName: "Santa Ana",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 109,
    districtId: 10902,
    label: "Salitral",
    value: 10902,
    cantonName: "Santa Ana",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 109,
    districtId: 10903,
    label: "Pozos",
    value: 10903,
    cantonName: "Santa Ana",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 109,
    districtId: 10904,
    label: "Uruca",
    value: 10904,
    cantonName: "Santa Ana",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 109,
    districtId: 10905,
    label: "Piedades",
    value: 10905,
    cantonName: "Santa Ana",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 109,
    districtId: 10906,
    label: "Brasil",
    value: 10906,
    cantonName: "Santa Ana",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 110,
    districtId: 11001,
    label: "Alajuelita",
    value: 11001,
    cantonName: "Alajuelita",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 110,
    districtId: 11002,
    label: "San Josecito",
    value: 11002,
    cantonName: "Alajuelita",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 110,
    districtId: 11003,
    label: "San Antonio",
    value: 11003,
    cantonName: "Alajuelita",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 110,
    districtId: 11004,
    label: "Concepción",
    value: 11004,
    cantonName: "Alajuelita",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 110,
    districtId: 11005,
    label: "San Felipe",
    value: 11005,
    cantonName: "Alajuelita",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 111,
    districtId: 11101,
    label: "San Isidro",
    value: 11101,
    cantonName: "Vázquez de Coronado",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 111,
    districtId: 11102,
    label: "San Rafael",
    value: 11102,
    cantonName: "Vázquez de Coronado",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 111,
    districtId: 11103,
    label: "Dulce Nombre de Jesús",
    value: 11103,
    cantonName: "Vázquez de Coronado",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 111,
    districtId: 11104,
    label: "Patalillo",
    value: 11104,
    cantonName: "Vázquez de Coronado",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 111,
    districtId: 11105,
    label: "Cascajal",
    value: 11105,
    cantonName: "Vázquez de Coronado",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 112,
    districtId: 11201,
    label: "San Ignacio",
    value: 11201,
    cantonName: "Acosta",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 112,
    districtId: 11202,
    label: "Guaitil",
    value: 11202,
    cantonName: "Acosta",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 112,
    districtId: 11203,
    label: "Palmichal",
    value: 11203,
    cantonName: "Acosta",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 112,
    districtId: 11204,
    label: "Cangrejal",
    value: 11204,
    cantonName: "Acosta",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 112,
    districtId: 11205,
    label: "Sabanillas",
    value: 11205,
    cantonName: "Acosta",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 113,
    districtId: 11301,
    label: "San Juan",
    value: 11301,
    cantonName: "Tibás",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 113,
    districtId: 11302,
    label: "Cinco Esquinas",
    value: 11302,
    cantonName: "Tibás",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 113,
    districtId: 11303,
    label: "Anselmo Llorente",
    value: 11303,
    cantonName: "Tibás",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 113,
    districtId: 11304,
    label: "León XIII",
    value: 11304,
    cantonName: "Tibás",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 113,
    districtId: 11305,
    label: "Colima",
    value: 11305,
    cantonName: "Tibás",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 114,
    districtId: 11401,
    label: "San Vicente",
    value: 11401,
    cantonName: "Moravia",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 114,
    districtId: 11402,
    label: "San Jerónimo",
    value: 11402,
    cantonName: "Moravia",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 114,
    districtId: 11403,
    label: "La Trinidad",
    value: 11403,
    cantonName: "Moravia",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 115,
    districtId: 11501,
    label: "San Pedro",
    value: 11501,
    cantonName: "Montes de Oca",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 115,
    districtId: 11502,
    label: "Sabanilla",
    value: 11502,
    cantonName: "Montes de Oca",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 115,
    districtId: 11503,
    label: "Mercedes",
    value: 11503,
    cantonName: "Montes de Oca",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 115,
    districtId: 11504,
    label: "San Rafael",
    value: 11504,
    cantonName: "Montes de Oca",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 116,
    districtId: 11601,
    label: "San Pablo",
    value: 11601,
    cantonName: "Turrubares",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 116,
    districtId: 11602,
    label: "San Pedro",
    value: 11602,
    cantonName: "Turrubares",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 116,
    districtId: 11603,
    label: "San Juan de Mata",
    value: 11603,
    cantonName: "Turrubares",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 116,
    districtId: 11604,
    label: "San Luis",
    value: 11604,
    cantonName: "Turrubares",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 116,
    districtId: 11605,
    label: "Carara",
    value: 11605,
    cantonName: "Turrubares",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 117,
    districtId: 11701,
    label: "Santa María",
    value: 11701,
    cantonName: "Dota",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 117,
    districtId: 11702,
    label: "Jardín",
    value: 11702,
    cantonName: "Dota",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 117,
    districtId: 11703,
    label: "Copey",
    value: 11703,
    cantonName: "Dota",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 118,
    districtId: 11801,
    label: "Curridabat",
    value: 11801,
    cantonName: "Curridabat",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 118,
    districtId: 11802,
    label: "Granadilla",
    value: 11802,
    cantonName: "Curridabat",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 118,
    districtId: 11803,
    label: "Sánchez",
    value: 11803,
    cantonName: "Curridabat",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 118,
    districtId: 11804,
    label: "Tirrases",
    value: 11804,
    cantonName: "Curridabat",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 119,
    districtId: 11901,
    label: "San Isidro de El General",
    value: 11901,
    cantonName: "Pérez Zeledón",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 119,
    districtId: 11902,
    label: "General",
    value: 11902,
    cantonName: "Pérez Zeledón",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 119,
    districtId: 11903,
    label: "Daniel Flores",
    value: 11903,
    cantonName: "Pérez Zeledón",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 119,
    districtId: 11904,
    label: "Rivas",
    value: 11904,
    cantonName: "Pérez Zeledón",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 119,
    districtId: 11905,
    label: "San Pedro",
    value: 11905,
    cantonName: "Pérez Zeledón",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 119,
    districtId: 11906,
    label: "Platanares",
    value: 11906,
    cantonName: "Pérez Zeledón",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 119,
    districtId: 11907,
    label: "Pejibaye",
    value: 11907,
    cantonName: "Pérez Zeledón",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 119,
    districtId: 11908,
    label: "Cajón",
    value: 11908,
    cantonName: "Pérez Zeledón",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 119,
    districtId: 11909,
    label: "Barú",
    value: 11909,
    cantonName: "Pérez Zeledón",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 119,
    districtId: 11910,
    label: "Río Nuevo",
    value: 11910,
    cantonName: "Pérez Zeledón",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 119,
    districtId: 11911,
    label: "Páramo",
    value: 11911,
    cantonName: "Pérez Zeledón",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 120,
    districtId: 12001,
    label: "San Pablo",
    value: 12001,
    cantonName: "León Cortés Castro",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 120,
    districtId: 12002,
    label: "San Andrés",
    value: 12002,
    cantonName: "León Cortés Castro",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 120,
    districtId: 12003,
    label: "Llano Bonito",
    value: 12003,
    cantonName: "León Cortés Castro",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 120,
    districtId: 12004,
    label: "San Isidro",
    value: 12004,
    cantonName: "León Cortés Castro",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 120,
    districtId: 12005,
    label: "Santa Cruz",
    value: 12005,
    cantonName: "León Cortés Castro",
    provinceName: "San José",
  },
  {
    provinceId: 1,
    cantonId: 120,
    districtId: 12006,
    label: "San Antonio",
    value: 12006,
    cantonName: "León Cortés Castro",
    provinceName: "San José",
  },
  {
    provinceId: 2,
    cantonId: 201,
    districtId: 20101,
    label: "Alajuela",
    value: 20101,
    cantonName: "Alajuela",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 201,
    districtId: 20102,
    label: "San José",
    value: 20102,
    cantonName: "Alajuela",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 201,
    districtId: 20103,
    label: "Carrizal",
    value: 20103,
    cantonName: "Alajuela",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 201,
    districtId: 20104,
    label: "San Antonio",
    value: 20104,
    cantonName: "Alajuela",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 201,
    districtId: 20105,
    label: "Guácima",
    value: 20105,
    cantonName: "Alajuela",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 201,
    districtId: 20106,
    label: "San Isidro",
    value: 20106,
    cantonName: "Alajuela",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 201,
    districtId: 20107,
    label: "Sabanilla",
    value: 20107,
    cantonName: "Alajuela",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 201,
    districtId: 20108,
    label: "San Rafael",
    value: 20108,
    cantonName: "Alajuela",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 201,
    districtId: 20109,
    label: "Río Segundo",
    value: 20109,
    cantonName: "Alajuela",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 201,
    districtId: 20110,
    label: "Desamparados",
    value: 20110,
    cantonName: "Alajuela",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 201,
    districtId: 20111,
    label: "Turrúcares",
    value: 20111,
    cantonName: "Alajuela",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 201,
    districtId: 20112,
    label: "Tambor",
    value: 20112,
    cantonName: "Alajuela",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 201,
    districtId: 20113,
    label: "Garita",
    value: 20113,
    cantonName: "Alajuela",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 201,
    districtId: 20114,
    label: "Sarapiquí",
    value: 20114,
    cantonName: "Alajuela",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 202,
    districtId: 20201,
    label: "San Ramón",
    value: 20201,
    cantonName: "San Ramón",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 202,
    districtId: 20202,
    label: "Santiago",
    value: 20202,
    cantonName: "San Ramón",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 202,
    districtId: 20203,
    label: "San Juan",
    value: 20203,
    cantonName: "San Ramón",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 202,
    districtId: 20204,
    label: "Piedades Norte",
    value: 20204,
    cantonName: "San Ramón",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 202,
    districtId: 20205,
    label: "Piedades Sur",
    value: 20205,
    cantonName: "San Ramón",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 202,
    districtId: 20206,
    label: "San Rafael",
    value: 20206,
    cantonName: "San Ramón",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 202,
    districtId: 20207,
    label: "San Isidro",
    value: 20207,
    cantonName: "San Ramón",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 202,
    districtId: 20208,
    label: "Los Ángeles",
    value: 20208,
    cantonName: "San Ramón",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 202,
    districtId: 20209,
    label: "Alfaro",
    value: 20209,
    cantonName: "San Ramón",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 202,
    districtId: 20210,
    label: "Volio",
    value: 20210,
    cantonName: "San Ramón",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 202,
    districtId: 20211,
    label: "Concepción",
    value: 20211,
    cantonName: "San Ramón",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 202,
    districtId: 20212,
    label: "Zapotal",
    value: 20212,
    cantonName: "San Ramón",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 202,
    districtId: 20213,
    label: "Peñas Blancas",
    value: 20213,
    cantonName: "San Ramón",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 203,
    districtId: 20301,
    label: "Grecia",
    value: 20301,
    cantonName: "Grecia",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 203,
    districtId: 20302,
    label: "San Isidro",
    value: 20302,
    cantonName: "Grecia",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 203,
    districtId: 20303,
    label: "San José",
    value: 20303,
    cantonName: "Grecia",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 203,
    districtId: 20304,
    label: "San Roque",
    value: 20304,
    cantonName: "Grecia",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 203,
    districtId: 20305,
    label: "Tacares",
    value: 20305,
    cantonName: "Grecia",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 203,
    districtId: 20306,
    label: "Río Cuarto",
    value: 20306,
    cantonName: "Grecia",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 203,
    districtId: 20307,
    label: "Puente de Piedra",
    value: 20307,
    cantonName: "Grecia",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 203,
    districtId: 20308,
    label: "Bolívar",
    value: 20308,
    cantonName: "Grecia",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 204,
    districtId: 20401,
    label: "San Mateo",
    value: 20401,
    cantonName: "San Mateo",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 204,
    districtId: 20402,
    label: "Desmonte",
    value: 20402,
    cantonName: "San Mateo",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 204,
    districtId: 20403,
    label: "Jesús María",
    value: 20403,
    cantonName: "San Mateo",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 205,
    districtId: 20501,
    label: "Atenas",
    value: 20501,
    cantonName: "Atenas",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 205,
    districtId: 20502,
    label: "Jesús",
    value: 20502,
    cantonName: "Atenas",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 205,
    districtId: 20503,
    label: "Mercedes",
    value: 20503,
    cantonName: "Atenas",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 205,
    districtId: 20504,
    label: "San Isidro",
    value: 20504,
    cantonName: "Atenas",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 205,
    districtId: 20505,
    label: "Concepción",
    value: 20505,
    cantonName: "Atenas",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 205,
    districtId: 20506,
    label: "San José",
    value: 20506,
    cantonName: "Atenas",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 205,
    districtId: 20507,
    label: "Santa Eulalia",
    value: 20507,
    cantonName: "Atenas",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 205,
    districtId: 20508,
    label: "Escobal",
    value: 20508,
    cantonName: "Atenas",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 206,
    districtId: 20601,
    label: "Naranjo",
    value: 20601,
    cantonName: "Naranjo",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 206,
    districtId: 20602,
    label: "San Miguel",
    value: 20602,
    cantonName: "Naranjo",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 206,
    districtId: 20603,
    label: "San José",
    value: 20603,
    cantonName: "Naranjo",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 206,
    districtId: 20604,
    label: "Cirrí Sur",
    value: 20604,
    cantonName: "Naranjo",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 206,
    districtId: 20605,
    label: "San Jerónimo",
    value: 20605,
    cantonName: "Naranjo",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 206,
    districtId: 20606,
    label: "San Juan",
    value: 20606,
    cantonName: "Naranjo",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 206,
    districtId: 20607,
    label: "El Rosario",
    value: 20607,
    cantonName: "Naranjo",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 206,
    districtId: 20608,
    label: "Palmitos",
    value: 20608,
    cantonName: "Naranjo",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 207,
    districtId: 20701,
    label: "Palmares",
    value: 20701,
    cantonName: "Palmares",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 207,
    districtId: 20702,
    label: "Zaragoza",
    value: 20702,
    cantonName: "Palmares",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 207,
    districtId: 20703,
    label: "Buenos Aires",
    value: 20703,
    cantonName: "Palmares",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 207,
    districtId: 20704,
    label: "Santiago",
    value: 20704,
    cantonName: "Palmares",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 207,
    districtId: 20705,
    label: "Candelaria",
    value: 20705,
    cantonName: "Palmares",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 207,
    districtId: 20706,
    label: "Esquipulas",
    value: 20706,
    cantonName: "Palmares",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 207,
    districtId: 20707,
    label: "La Granja",
    value: 20707,
    cantonName: "Palmares",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 208,
    districtId: 20801,
    label: "San Pedro",
    value: 20801,
    cantonName: "Poás",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 208,
    districtId: 20802,
    label: "San Juan",
    value: 20802,
    cantonName: "Poás",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 208,
    districtId: 20803,
    label: "San Rafael",
    value: 20803,
    cantonName: "Poás",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 208,
    districtId: 20804,
    label: "Carrillos",
    value: 20804,
    cantonName: "Poás",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 208,
    districtId: 20805,
    label: "Sabana Redonda",
    value: 20805,
    cantonName: "Poás",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 209,
    districtId: 20901,
    label: "Orotina",
    value: 20901,
    cantonName: "Orotina",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 209,
    districtId: 20902,
    label: "El Mastate",
    value: 20902,
    cantonName: "Orotina",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 209,
    districtId: 20903,
    label: "Hacienda Vieja",
    value: 20903,
    cantonName: "Orotina",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 209,
    districtId: 20904,
    label: "Coyolar",
    value: 20904,
    cantonName: "Orotina",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 209,
    districtId: 20905,
    label: "La Ceiba",
    value: 20905,
    cantonName: "Orotina",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 210,
    districtId: 21001,
    label: "Quesada",
    value: 21001,
    cantonName: "San Carlos",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 210,
    districtId: 21002,
    label: "Florencia",
    value: 21002,
    cantonName: "San Carlos",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 210,
    districtId: 21003,
    label: "Buenavista",
    value: 21003,
    cantonName: "San Carlos",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 210,
    districtId: 21004,
    label: "Aguas Zarcas",
    value: 21004,
    cantonName: "San Carlos",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 210,
    districtId: 21005,
    label: "Venecia",
    value: 21005,
    cantonName: "San Carlos",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 210,
    districtId: 21006,
    label: "Pital",
    value: 21006,
    cantonName: "San Carlos",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 210,
    districtId: 21007,
    label: "La Fortuna",
    value: 21007,
    cantonName: "San Carlos",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 210,
    districtId: 21008,
    label: "La Tigra",
    value: 21008,
    cantonName: "San Carlos",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 210,
    districtId: 21009,
    label: "La Palmera",
    value: 21009,
    cantonName: "San Carlos",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 210,
    districtId: 21010,
    label: "Venado",
    value: 21010,
    cantonName: "San Carlos",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 210,
    districtId: 21011,
    label: "Cutris",
    value: 21011,
    cantonName: "San Carlos",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 210,
    districtId: 21012,
    label: "Monterrey",
    value: 21012,
    cantonName: "San Carlos",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 210,
    districtId: 21013,
    label: "Pocosol",
    value: 21013,
    cantonName: "San Carlos",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 211,
    districtId: 21101,
    label: "Zarcero",
    value: 21101,
    cantonName: "Zarcero",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 211,
    districtId: 21102,
    label: "Laguna",
    value: 21102,
    cantonName: "Zarcero",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 211,
    districtId: 21103,
    label: "Tapesco",
    value: 21103,
    cantonName: "Zarcero",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 211,
    districtId: 21104,
    label: "Guadalupe",
    value: 21104,
    cantonName: "Zarcero",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 211,
    districtId: 21105,
    label: "Palmira",
    value: 21105,
    cantonName: "Zarcero",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 211,
    districtId: 21106,
    label: "Zapote",
    value: 21106,
    cantonName: "Zarcero",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 211,
    districtId: 21107,
    label: "Brisas",
    value: 21107,
    cantonName: "Zarcero",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 212,
    districtId: 21201,
    label: "Sarchí Norte",
    value: 21201,
    cantonName: "Valverde Vega",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 212,
    districtId: 21202,
    label: "Sarchí Sur",
    value: 21202,
    cantonName: "Valverde Vega",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 212,
    districtId: 21203,
    label: "Toro Amarillo",
    value: 21203,
    cantonName: "Valverde Vega",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 212,
    districtId: 21204,
    label: "San Pedro",
    value: 21204,
    cantonName: "Valverde Vega",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 212,
    districtId: 21205,
    label: "Rodríguez",
    value: 21205,
    cantonName: "Valverde Vega",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 213,
    districtId: 21301,
    label: "Upala",
    value: 21301,
    cantonName: "Upala",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 213,
    districtId: 21302,
    label: "Aguas Claras",
    value: 21302,
    cantonName: "Upala",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 213,
    districtId: 21303,
    label: "San José (Pizote)",
    value: 21303,
    cantonName: "Upala",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 213,
    districtId: 21304,
    label: "Bijagua",
    value: 21304,
    cantonName: "Upala",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 213,
    districtId: 21305,
    label: "Delicias",
    value: 21305,
    cantonName: "Upala",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 213,
    districtId: 21306,
    label: "Dos Ríos",
    value: 21306,
    cantonName: "Upala",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 213,
    districtId: 21307,
    label: "Yoliyllal",
    value: 21307,
    cantonName: "Upala",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 214,
    districtId: 21401,
    label: "Los Chiles",
    value: 21401,
    cantonName: "Los Chiles",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 214,
    districtId: 21402,
    label: "Caño Negro",
    value: 21402,
    cantonName: "Los Chiles",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 214,
    districtId: 21403,
    label: "El Amparo",
    value: 21403,
    cantonName: "Los Chiles",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 214,
    districtId: 21404,
    label: "San Jorge",
    value: 21404,
    cantonName: "Los Chiles",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 215,
    districtId: 21501,
    label: "San Rafael",
    value: 21501,
    cantonName: "Guatuso",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 215,
    districtId: 21502,
    label: "Buenavista",
    value: 21502,
    cantonName: "Guatuso",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 215,
    districtId: 21503,
    label: "Cote",
    value: 21503,
    cantonName: "Guatuso",
    provinceName: "Alajuela",
  },
  {
    provinceId: 2,
    cantonId: 215,
    districtId: 21504,
    label: "Katira",
    value: 21504,
    cantonName: "Guatuso",
    provinceName: "Alajuela",
  },
  {
    provinceId: 3,
    cantonId: 301,
    districtId: 30101,
    label: "Oriental",
    value: 30101,
    cantonName: "Cartago",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 301,
    districtId: 30102,
    label: "Occidental",
    value: 30102,
    cantonName: "Cartago",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 301,
    districtId: 30103,
    label: "Carmen",
    value: 30103,
    cantonName: "Cartago",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 301,
    districtId: 30104,
    label: "San Nicolás",
    value: 30104,
    cantonName: "Cartago",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 301,
    districtId: 30105,
    label: "Aguacaliente (San Francisco)",
    value: 30105,
    cantonName: "Cartago",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 301,
    districtId: 30106,
    label: "Guadalupe (Arenilla)",
    value: 30106,
    cantonName: "Cartago",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 301,
    districtId: 30107,
    label: "Corralillo",
    value: 30107,
    cantonName: "Cartago",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 301,
    districtId: 30108,
    label: "Tierra Blanca",
    value: 30108,
    cantonName: "Cartago",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 301,
    districtId: 30109,
    label: "Dulce Nombre",
    value: 30109,
    cantonName: "Cartago",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 301,
    districtId: 30110,
    label: "Llano Grande",
    value: 30110,
    cantonName: "Cartago",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 301,
    districtId: 30111,
    label: "Quebradilla",
    value: 30111,
    cantonName: "Cartago",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 302,
    districtId: 30201,
    label: "Paraíso",
    value: 30201,
    cantonName: "Paraíso",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 302,
    districtId: 30202,
    label: "Santiago",
    value: 30202,
    cantonName: "Paraíso",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 302,
    districtId: 30203,
    label: "Orosi",
    value: 30203,
    cantonName: "Paraíso",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 302,
    districtId: 30204,
    label: "Cachí",
    value: 30204,
    cantonName: "Paraíso",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 302,
    districtId: 30205,
    label: "Llanos de Santa Lucía",
    value: 30205,
    cantonName: "Paraíso",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 303,
    districtId: 30301,
    label: "Tres Ríos",
    value: 30301,
    cantonName: "La Unión",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 303,
    districtId: 30302,
    label: "San Diego",
    value: 30302,
    cantonName: "La Unión",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 303,
    districtId: 30303,
    label: "San Juan",
    value: 30303,
    cantonName: "La Unión",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 303,
    districtId: 30304,
    label: "San Rafael",
    value: 30304,
    cantonName: "La Unión",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 303,
    districtId: 30305,
    label: "Concepción",
    value: 30305,
    cantonName: "La Unión",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 303,
    districtId: 30306,
    label: "Dulce Nombre",
    value: 30306,
    cantonName: "La Unión",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 303,
    districtId: 30307,
    label: "San Ramón",
    value: 30307,
    cantonName: "La Unión",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 303,
    districtId: 30308,
    label: "Río Azul",
    value: 30308,
    cantonName: "La Unión",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 304,
    districtId: 30401,
    label: "Juan Viñas",
    value: 30401,
    cantonName: "Jiménez",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 304,
    districtId: 30402,
    label: "Tucurrique",
    value: 30402,
    cantonName: "Jiménez",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 304,
    districtId: 30403,
    label: "Pejibaye",
    value: 30403,
    cantonName: "Jiménez",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 305,
    districtId: 30501,
    label: "Turrialba",
    value: 30501,
    cantonName: "Turrialba",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 305,
    districtId: 30502,
    label: "La Suiza",
    value: 30502,
    cantonName: "Turrialba",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 305,
    districtId: 30503,
    label: "Peralta",
    value: 30503,
    cantonName: "Turrialba",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 305,
    districtId: 30504,
    label: "Santa Cruz",
    value: 30504,
    cantonName: "Turrialba",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 305,
    districtId: 30505,
    label: "Santa Teresita",
    value: 30505,
    cantonName: "Turrialba",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 305,
    districtId: 30506,
    label: "Pavones",
    value: 30506,
    cantonName: "Turrialba",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 305,
    districtId: 30507,
    label: "Tuis",
    value: 30507,
    cantonName: "Turrialba",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 305,
    districtId: 30508,
    label: "Tayutic",
    value: 30508,
    cantonName: "Turrialba",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 305,
    districtId: 30509,
    label: "Santa Rosa",
    value: 30509,
    cantonName: "Turrialba",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 305,
    districtId: 30510,
    label: "Tres Equis",
    value: 30510,
    cantonName: "Turrialba",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 305,
    districtId: 30511,
    label: "La Isabel",
    value: 30511,
    cantonName: "Turrialba",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 305,
    districtId: 30512,
    label: "Chirripó",
    value: 30512,
    cantonName: "Turrialba",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 306,
    districtId: 30601,
    label: "Pacayas",
    value: 30601,
    cantonName: "Alvarado",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 306,
    districtId: 30602,
    label: "Cervantes",
    value: 30602,
    cantonName: "Alvarado",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 306,
    districtId: 30603,
    label: "Capellades",
    value: 30603,
    cantonName: "Alvarado",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 307,
    districtId: 30701,
    label: "San Rafael",
    value: 30701,
    cantonName: "Oreamuno",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 307,
    districtId: 30702,
    label: "Cot",
    value: 30702,
    cantonName: "Oreamuno",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 307,
    districtId: 30703,
    label: "Potrero Cerrado",
    value: 30703,
    cantonName: "Oreamuno",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 307,
    districtId: 30704,
    label: "Cipreses",
    value: 30704,
    cantonName: "Oreamuno",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 307,
    districtId: 30705,
    label: "Santa Rosa",
    value: 30705,
    cantonName: "Oreamuno",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 308,
    districtId: 30801,
    label: "El Tejar",
    value: 30801,
    cantonName: "El Guarco",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 308,
    districtId: 30802,
    label: "San Isidro",
    value: 30802,
    cantonName: "El Guarco",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 308,
    districtId: 30803,
    label: "Tobosi",
    value: 30803,
    cantonName: "El Guarco",
    provinceName: "Cartago",
  },
  {
    provinceId: 3,
    cantonId: 308,
    districtId: 30804,
    label: "Patio de Agua",
    value: 30804,
    cantonName: "El Guarco",
    provinceName: "Cartago",
  },
  {
    provinceId: 4,
    cantonId: 401,
    districtId: 40101,
    label: "Heredia",
    value: 40101,
    cantonName: "Heredia",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 401,
    districtId: 40102,
    label: "Mercedes",
    value: 40102,
    cantonName: "Heredia",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 401,
    districtId: 40103,
    label: "San Francisco",
    value: 40103,
    cantonName: "Heredia",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 401,
    districtId: 40104,
    label: "Ulloa",
    value: 40104,
    cantonName: "Heredia",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 401,
    districtId: 40105,
    label: "Varablanca",
    value: 40105,
    cantonName: "Heredia",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 402,
    districtId: 40201,
    label: "Barva",
    value: 40201,
    cantonName: "Barva",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 402,
    districtId: 40202,
    label: "San Pedro",
    value: 40202,
    cantonName: "Barva",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 402,
    districtId: 40203,
    label: "San Pablo",
    value: 40203,
    cantonName: "Barva",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 402,
    districtId: 40204,
    label: "San Roque",
    value: 40204,
    cantonName: "Barva",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 402,
    districtId: 40205,
    label: "Santa Lucía",
    value: 40205,
    cantonName: "Barva",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 402,
    districtId: 40206,
    label: "San José de la Montaña",
    value: 40206,
    cantonName: "Barva",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 403,
    districtId: 40301,
    label: "Santo Domingo",
    value: 40301,
    cantonName: "Santo Domingo",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 403,
    districtId: 40302,
    label: "San Vicente",
    value: 40302,
    cantonName: "Santo Domingo",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 403,
    districtId: 40303,
    label: "San Miguel",
    value: 40303,
    cantonName: "Santo Domingo",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 403,
    districtId: 40304,
    label: "Paracito",
    value: 40304,
    cantonName: "Santo Domingo",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 403,
    districtId: 40305,
    label: "Santo Tomás",
    value: 40305,
    cantonName: "Santo Domingo",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 403,
    districtId: 40306,
    label: "Santa Rosa",
    value: 40306,
    cantonName: "Santo Domingo",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 403,
    districtId: 40307,
    label: "Tures",
    value: 40307,
    cantonName: "Santo Domingo",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 403,
    districtId: 40308,
    label: "Para",
    value: 40308,
    cantonName: "Santo Domingo",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 404,
    districtId: 40401,
    label: "Santa Bárbara",
    value: 40401,
    cantonName: "Santa Bárbara",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 404,
    districtId: 40402,
    label: "San Pedro",
    value: 40402,
    cantonName: "Santa Bárbara",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 404,
    districtId: 40403,
    label: "San Juan",
    value: 40403,
    cantonName: "Santa Bárbara",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 404,
    districtId: 40404,
    label: "Jesús",
    value: 40404,
    cantonName: "Santa Bárbara",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 404,
    districtId: 40405,
    label: "Santo Domingo",
    value: 40405,
    cantonName: "Santa Bárbara",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 404,
    districtId: 40406,
    label: "Puraba",
    value: 40406,
    cantonName: "Santa Bárbara",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 405,
    districtId: 40501,
    label: "San Rafael",
    value: 40501,
    cantonName: "San Rafael",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 405,
    districtId: 40502,
    label: "San Josécito",
    value: 40502,
    cantonName: "San Rafael",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 405,
    districtId: 40503,
    label: "Santiago",
    value: 40503,
    cantonName: "San Rafael",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 405,
    districtId: 40504,
    label: "Los Ángeles",
    value: 40504,
    cantonName: "San Rafael",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 405,
    districtId: 40505,
    label: "Concepción",
    value: 40505,
    cantonName: "San Rafael",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 406,
    districtId: 40601,
    label: "San Isidro",
    value: 40601,
    cantonName: "San Isidro",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 406,
    districtId: 40602,
    label: "San José",
    value: 40602,
    cantonName: "San Isidro",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 406,
    districtId: 40603,
    label: "Concepción",
    value: 40603,
    cantonName: "San Isidro",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 406,
    districtId: 40604,
    label: "San Francisco",
    value: 40604,
    cantonName: "San Isidro",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 407,
    districtId: 40701,
    label: "San Antonio",
    value: 40701,
    cantonName: "Belén",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 407,
    districtId: 40702,
    label: "La Ribera",
    value: 40702,
    cantonName: "Belén",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 407,
    districtId: 40703,
    label: "La Asunción",
    value: 40703,
    cantonName: "Belén",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 408,
    districtId: 40801,
    label: "San Joaquín de Flores",
    value: 40801,
    cantonName: "Flores",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 408,
    districtId: 40802,
    label: "Barrantes",
    value: 40802,
    cantonName: "Flores",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 408,
    districtId: 40803,
    label: "Llorente",
    value: 40803,
    cantonName: "Flores",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 409,
    districtId: 40901,
    label: "San Pablo",
    value: 40901,
    cantonName: "San Pablo",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 409,
    districtId: 40902,
    label: "Rincón de Sabanilla",
    value: 40902,
    cantonName: "San Pablo",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 410,
    districtId: 41001,
    label: "Puerto Viejo",
    value: 41001,
    cantonName: "Sarapiquí",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 410,
    districtId: 41002,
    label: "La Virgen",
    value: 41002,
    cantonName: "Sarapiquí",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 410,
    districtId: 41003,
    label: "Horquetas",
    value: 41003,
    cantonName: "Sarapiquí",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 410,
    districtId: 41004,
    label: "Llanuras del Gaspar",
    value: 41004,
    cantonName: "Sarapiquí",
    provinceName: "Heredia",
  },
  {
    provinceId: 4,
    cantonId: 410,
    districtId: 41005,
    label: "Cureña",
    value: 41005,
    cantonName: "Sarapiquí",
    provinceName: "Heredia",
  },
  {
    provinceId: 5,
    cantonId: 501,
    districtId: 50101,
    label: "Liberia",
    value: 50101,
    cantonName: "Liberia",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 501,
    districtId: 50102,
    label: "Cañas Dulces",
    value: 50102,
    cantonName: "Liberia",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 501,
    districtId: 50103,
    label: "Mayorga",
    value: 50103,
    cantonName: "Liberia",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 501,
    districtId: 50104,
    label: "Nacascolo",
    value: 50104,
    cantonName: "Liberia",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 501,
    districtId: 50105,
    label: "Curubande",
    value: 50105,
    cantonName: "Liberia",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 502,
    districtId: 50201,
    label: "Nicoya",
    value: 50201,
    cantonName: "Nicoya",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 502,
    districtId: 50202,
    label: "Mansion",
    value: 50202,
    cantonName: "Nicoya",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 502,
    districtId: 50203,
    label: "San Antonio",
    value: 50203,
    cantonName: "Nicoya",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 502,
    districtId: 50204,
    label: "Quebrada Honda",
    value: 50204,
    cantonName: "Nicoya",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 502,
    districtId: 50205,
    label: "Samara",
    value: 50205,
    cantonName: "Nicoya",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 502,
    districtId: 50206,
    label: "Nosara",
    value: 50206,
    cantonName: "Nicoya",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 502,
    districtId: 50207,
    label: "Belén de Nosarita",
    value: 50207,
    cantonName: "Santa Cruz",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 503,
    districtId: 50301,
    label: "Santa Cruz",
    value: 50301,
    cantonName: "Santa Cruz",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 503,
    districtId: 50302,
    label: "Bolson",
    value: 50302,
    cantonName: "Santa Cruz",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 503,
    districtId: 50303,
    label: "Veintisiete de Abril",
    value: 50303,
    cantonName: "Santa Cruz",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 503,
    districtId: 50304,
    label: "Tempate",
    value: 50304,
    cantonName: "Santa Cruz",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 503,
    districtId: 50305,
    label: "Cartagena",
    value: 50305,
    cantonName: "Santa Cruz",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 503,
    districtId: 50306,
    label: "Cuajiniquil",
    value: 50306,
    cantonName: "Santa Cruz",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 503,
    districtId: 50307,
    label: "Diria",
    value: 50307,
    cantonName: "Santa Cruz",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 503,
    districtId: 50308,
    label: "Cabo Velas",
    value: 50308,
    cantonName: "Santa Cruz",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 503,
    districtId: 50309,
    label: "Tamarindo",
    value: 50309,
    cantonName: "Santa Cruz",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 504,
    districtId: 50401,
    label: "Bagaces",
    value: 50401,
    cantonName: "Bagaces",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 504,
    districtId: 50402,
    label: "Fortuna",
    value: 50402,
    cantonName: "Bagaces",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 504,
    districtId: 50403,
    label: "Mogote",
    value: 50403,
    cantonName: "Bagaces",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 504,
    districtId: 50404,
    label: "Río Naranjo",
    value: 50404,
    cantonName: "Bagaces",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 505,
    districtId: 50501,
    label: "Filadelfia",
    value: 50501,
    cantonName: "Carrillo",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 505,
    districtId: 50502,
    label: "Palmira",
    value: 50502,
    cantonName: "Carrillo",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 505,
    districtId: 50503,
    label: "Sardinal",
    value: 50503,
    cantonName: "Carrillo",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 505,
    districtId: 50504,
    label: "Belén",
    value: 50504,
    cantonName: "Carrillo",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 506,
    districtId: 50601,
    label: "Cañas",
    value: 50601,
    cantonName: "Cañas",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 506,
    districtId: 50602,
    label: "Palmira",
    value: 50602,
    cantonName: "Cañas",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 506,
    districtId: 50603,
    label: "San Miguel",
    value: 50603,
    cantonName: "Cañas",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 506,
    districtId: 50604,
    label: "Bebedero",
    value: 50604,
    cantonName: "Cañas",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 506,
    districtId: 50605,
    label: "Porozal",
    value: 50605,
    cantonName: "Cañas",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 507,
    districtId: 50701,
    label: "Juntas",
    value: 50701,
    cantonName: "Abangares",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 507,
    districtId: 50702,
    label: "Sierra",
    value: 50702,
    cantonName: "Abangares",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 507,
    districtId: 50703,
    label: "San Juan",
    value: 50703,
    cantonName: "Abangares",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 507,
    districtId: 50704,
    label: "Colorado",
    value: 50704,
    cantonName: "Abangares",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 508,
    districtId: 50801,
    label: "Tilarán",
    value: 50801,
    cantonName: "Tilarán",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 508,
    districtId: 50802,
    label: "Quebrada Grande",
    value: 50802,
    cantonName: "Tilarán",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 508,
    districtId: 50803,
    label: "Tronadora",
    value: 50803,
    cantonName: "Tilarán",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 508,
    districtId: 50804,
    label: "Santa Rosa",
    value: 50804,
    cantonName: "Tilarán",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 508,
    districtId: 50805,
    label: "Líbano",
    value: 50805,
    cantonName: "Tilarán",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 508,
    districtId: 50806,
    label: "Tierras Morenas",
    value: 50806,
    cantonName: "Tilarán",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 508,
    districtId: 50807,
    label: "Arenal",
    value: 50807,
    cantonName: "Tilarán",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 509,
    districtId: 50901,
    label: "Carmona",
    value: 50901,
    cantonName: "Nandayure",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 509,
    districtId: 50902,
    label: "Santa Rita",
    value: 50902,
    cantonName: "Nandayure",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 509,
    districtId: 50903,
    label: "Zapotal",
    value: 50903,
    cantonName: "Nandayure",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 509,
    districtId: 50904,
    label: "San Pablo",
    value: 50904,
    cantonName: "Nandayure",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 509,
    districtId: 50905,
    label: "Porvenir",
    value: 50905,
    cantonName: "Nandayure",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 509,
    districtId: 50906,
    label: "Bejuco",
    value: 50906,
    cantonName: "Nandayure",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 510,
    districtId: 51001,
    label: "La Cruz",
    value: 51001,
    cantonName: "La Cruz",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 510,
    districtId: 51002,
    label: "Santa Cecilia",
    value: 51002,
    cantonName: "La Cruz",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 510,
    districtId: 51003,
    label: "Garita",
    value: 51003,
    cantonName: "La Cruz",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 510,
    districtId: 51004,
    label: "Santa Elena",
    value: 51004,
    cantonName: "La Cruz",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 511,
    districtId: 51101,
    label: "Hojancha",
    value: 51101,
    cantonName: "Hojancha",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 511,
    districtId: 51102,
    label: "Monte Romo",
    value: 51102,
    cantonName: "Hojancha",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 511,
    districtId: 51103,
    label: "Puerto Carrillo",
    value: 51103,
    cantonName: "Hojancha",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 5,
    cantonId: 511,
    districtId: 51104,
    label: "Huacas",
    value: 51104,
    cantonName: "Hojancha",
    provinceName: "Guanacaste",
  },
  {
    provinceId: 6,
    cantonId: 601,
    districtId: 60101,
    label: "Puntarenas",
    value: 60101,
    cantonName: "Puntarenas",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 601,
    districtId: 60102,
    label: "Pitahaya",
    value: 60102,
    cantonName: "Puntarenas",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 601,
    districtId: 60103,
    label: "Chomes",
    value: 60103,
    cantonName: "Puntarenas",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 601,
    districtId: 60104,
    label: "Lepanto",
    value: 60104,
    cantonName: "Puntarenas",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 601,
    districtId: 60105,
    label: "Paquera",
    value: 60105,
    cantonName: "Puntarenas",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 601,
    districtId: 60106,
    label: "Manzanillo",
    value: 60106,
    cantonName: "Puntarenas",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 601,
    districtId: 60107,
    label: "Guacimal",
    value: 60107,
    cantonName: "Puntarenas",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 601,
    districtId: 60108,
    label: "Barranca",
    value: 60108,
    cantonName: "Puntarenas",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 601,
    districtId: 60109,
    label: "Monte Verde",
    value: 60109,
    cantonName: "Puntarenas",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 601,
    districtId: 60110,
    label: "Isla del Coco",
    value: 60110,
    cantonName: "Puntarenas",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 601,
    districtId: 60111,
    label: "Cobano",
    value: 60111,
    cantonName: "Puntarenas",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 601,
    districtId: 60112,
    label: "Chacarita",
    value: 60112,
    cantonName: "Puntarenas",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 601,
    districtId: 60113,
    label: "Chira",
    value: 60113,
    cantonName: "Puntarenas",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 601,
    districtId: 60114,
    label: "Acapulco",
    value: 60114,
    cantonName: "Puntarenas",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 601,
    districtId: 60115,
    label: "El Roble",
    value: 60115,
    cantonName: "Puntarenas",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 601,
    districtId: 60116,
    label: "Arancibia",
    value: 60116,
    cantonName: "Puntarenas",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 602,
    districtId: 60201,
    label: "Espiritu Santo",
    value: 60201,
    cantonName: "Esparza",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 602,
    districtId: 60202,
    label: "San Juan Grande",
    value: 60202,
    cantonName: "Esparza",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 602,
    districtId: 60203,
    label: "Macacona",
    value: 60203,
    cantonName: "Esparza",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 602,
    districtId: 60204,
    label: "San Rafael",
    value: 60204,
    cantonName: "Esparza",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 602,
    districtId: 60205,
    label: "San Jerónimo",
    value: 60205,
    cantonName: "Esparza",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 603,
    districtId: 60301,
    label: "Buenos Aires",
    value: 60301,
    cantonName: "Buenos Aires",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 603,
    districtId: 60302,
    label: "Volcan",
    value: 60302,
    cantonName: "Buenos Aires",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 603,
    districtId: 60303,
    label: "Potrero Grande",
    value: 60303,
    cantonName: "Buenos Aires",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 603,
    districtId: 60304,
    label: "Boruca",
    value: 60304,
    cantonName: "Buenos Aires",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 603,
    districtId: 60305,
    label: "Pilas",
    value: 60305,
    cantonName: "Buenos Aires",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 603,
    districtId: 60306,
    label: "Colinas",
    value: 60306,
    cantonName: "Buenos Aires",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 603,
    districtId: 60307,
    label: "Changena",
    value: 60307,
    cantonName: "Buenos Aires",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 603,
    districtId: 60308,
    label: "Briolley",
    value: 60308,
    cantonName: "Buenos Aires",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 603,
    districtId: 60309,
    label: "Brunka",
    value: 60309,
    cantonName: "Buenos Aires",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 604,
    districtId: 60401,
    label: "Miramar",
    value: 60401,
    cantonName: "Montes de Oro",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 604,
    districtId: 60402,
    label: "La Unión",
    value: 60402,
    cantonName: "Montes de Oro",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 604,
    districtId: 60403,
    label: "San Isidro",
    value: 60403,
    cantonName: "Montes de Oro",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 605,
    districtId: 60501,
    label: "Puerto Cortes",
    value: 60501,
    cantonName: "Osa",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 605,
    districtId: 60502,
    label: "Palmar",
    value: 60502,
    cantonName: "Osa",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 605,
    districtId: 60503,
    label: "Sierpe",
    value: 60503,
    cantonName: "Osa",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 605,
    districtId: 60504,
    label: "Bahia Ballena",
    value: 60504,
    cantonName: "Osa",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 605,
    districtId: 60505,
    label: "Piedras Blancas",
    value: 60505,
    cantonName: "Osa",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 606,
    districtId: 60601,
    label: "Quepos",
    value: 60601,
    cantonName: "Quepos",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 606,
    districtId: 60602,
    label: "Savegre",
    value: 60602,
    cantonName: "Quepos",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 606,
    districtId: 60603,
    label: "Naranjito",
    value: 60603,
    cantonName: "Quepos",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 607,
    districtId: 60701,
    label: "Golfito",
    value: 60701,
    cantonName: "Golfito",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 607,
    districtId: 60702,
    label: "Puerto Jiménez",
    value: 60702,
    cantonName: "Golfito",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 607,
    districtId: 60703,
    label: "Guaycara",
    value: 60703,
    cantonName: "Golfito",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 607,
    districtId: 60704,
    label: "Pavon",
    value: 60704,
    cantonName: "Golfito",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 608,
    districtId: 60801,
    label: "San Vito",
    value: 60801,
    cantonName: "Coto Brus",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 608,
    districtId: 60802,
    label: "Sabalito",
    value: 60802,
    cantonName: "Coto Brus",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 608,
    districtId: 60803,
    label: "Aguabuena",
    value: 60803,
    cantonName: "Coto Brus",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 608,
    districtId: 60804,
    label: "Limóncito",
    value: 60804,
    cantonName: "Coto Brus",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 608,
    districtId: 60805,
    label: "Pittier",
    value: 60805,
    cantonName: "Coto Brus",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 609,
    districtId: 60901,
    label: "Parrita",
    value: 60901,
    cantonName: "Parrita",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 610,
    districtId: 61001,
    label: "Corredor",
    value: 61001,
    cantonName: "Corredores",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 610,
    districtId: 61002,
    label: "La Cuesta",
    value: 61002,
    cantonName: "Corredores",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 610,
    districtId: 61003,
    label: "Canoas",
    value: 61003,
    cantonName: "Corredores",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 610,
    districtId: 61004,
    label: "Laurel",
    value: 61004,
    cantonName: "Corredores",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 611,
    districtId: 61101,
    label: "Jacó",
    value: 61101,
    cantonName: "Garabito",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 611,
    districtId: 61102,
    label: "Tarcoles",
    value: 61102,
    cantonName: "Garabito",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 6,
    cantonId: 612,
    districtId: 61201,
    label: "Monte Verde",
    value: 61201,
    cantonName: "Monte Verde",
    provinceName: "Puntarenas",
  },
  {
    provinceId: 7,
    cantonId: 701,
    districtId: 70101,
    label: "Limón",
    value: 70101,
    cantonName: "Limón",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 701,
    districtId: 70102,
    label: "Valle La Estrella",
    value: 70102,
    cantonName: "Limón",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 701,
    districtId: 70103,
    label: "Río Blanco",
    value: 70103,
    cantonName: "Limón",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 701,
    districtId: 70104,
    label: "Matama",
    value: 70104,
    cantonName: "Limón",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 702,
    districtId: 70201,
    label: "Guapiles",
    value: 70201,
    cantonName: "Pococí",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 702,
    districtId: 70202,
    label: "Jiménez",
    value: 70202,
    cantonName: "Pococí",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 702,
    districtId: 70203,
    label: "Rita",
    value: 70203,
    cantonName: "Pococí",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 702,
    districtId: 70204,
    label: "Roxana",
    value: 70204,
    cantonName: "Pococí",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 702,
    districtId: 70205,
    label: "Cariari",
    value: 70205,
    cantonName: "Pococí",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 702,
    districtId: 70206,
    label: "Colorado",
    value: 70206,
    cantonName: "Pococí",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 703,
    districtId: 70301,
    label: "Siquirres",
    value: 70301,
    cantonName: "Siquirres",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 703,
    districtId: 70302,
    label: "Pacuarito",
    value: 70302,
    cantonName: "Siquirres",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 703,
    districtId: 70303,
    label: "Florida",
    value: 70303,
    cantonName: "Siquirres",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 703,
    districtId: 70304,
    label: "Germania",
    value: 70304,
    cantonName: "Siquirres",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 703,
    districtId: 70305,
    label: "Cairo",
    value: 70305,
    cantonName: "Siquirres",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 703,
    districtId: 70306,
    label: "Alegria",
    value: 70306,
    cantonName: "Siquirres",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 704,
    districtId: 70401,
    label: "Bratsi",
    value: 70401,
    cantonName: "Talamanca",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 704,
    districtId: 70402,
    label: "Sixaola",
    value: 70402,
    cantonName: "Talamanca",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 704,
    districtId: 70403,
    label: "Cahuita",
    value: 70403,
    cantonName: "Talamanca",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 704,
    districtId: 70404,
    label: "Telire",
    value: 70404,
    cantonName: "Talamanca",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 705,
    districtId: 70501,
    label: "Matina",
    value: 70501,
    cantonName: "Matina",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 705,
    districtId: 70502,
    label: "Battan",
    value: 70502,
    cantonName: "Matina",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 705,
    districtId: 70503,
    label: "Carrandi",
    value: 70503,
    cantonName: "Matina",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 706,
    districtId: 70601,
    label: "Guácimo",
    value: 70601,
    cantonName: "Guácimo",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 706,
    districtId: 70602,
    label: "Mercedes",
    value: 70602,
    cantonName: "Guácimo",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 706,
    districtId: 70603,
    label: "Pocora",
    value: 70603,
    cantonName: "Guácimo",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 706,
    districtId: 70604,
    label: "Río Jiménez",
    value: 70604,
    cantonName: "Guácimo",
    provinceName: "Limón",
  },
  {
    provinceId: 7,
    cantonId: 706,
    districtId: 70605,
    label: "Duacari",
    value: 70605,
    cantonName: "Guácimo",
    provinceName: "Limón",
  },
];
