import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ASbutton } from "../buttons/ASbutton";

import {
  cardNumberFormatter,
  expirationDateFormatter,
} from "../../utils/Formatters";
import { getCardType } from "../../utils/validateIssuer";

const issuerList = [
  {
    label: "Visa",
    value: "visa",
  },
  {
    label: "Mastercard",
    value: "mastercard",
  },
  {
    label: "American Express",
    value: "american-express",
  },
  {
    label: "Diners Club",
    value: "diners-club",
  },
];

const AddCardForm = ({
  formik,
  setCardNumber,
  cardNumber,
  setExpiration,
  expiration,
}) => {
  return (
    <form style={{ padding: 30 }}>
      <>
        <Typography variant="h4" component="h2" mb={3}>
          Nueva tarjeta
        </Typography>
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Nombre en la tarjeta"
                id="cardName"
                name="cardName"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cardName}
                error={
                  formik.touched.cardName && Boolean(formik.errors.cardName)
                }
                helperText={formik.touched.cardName && formik.errors.cardName}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Número de la tarjeta"
                id="cardNumber"
                name="cardNumber"
                variant="outlined"
                onChange={(event) => {
                  const text = event.target.value.toString(); // Ensure it's a string
                  const formatted = cardNumberFormatter
                    ? cardNumberFormatter(formik.values.cardNumber, text)
                    : text;
                  setCardNumber(formatted);

                  console.log(formatted.replace(/\s/g, ""));
                  const cardType = getCardType(formatted.replace(/\s/g, ""));
                  console.log(cardType);
                  formik.setFieldValue("issuerByCardNumber", cardType);
                  formik.setFieldValue("cardNumber", formatted);
                }}
                onBlur={formik.handleBlur}
                value={cardNumber}
                error={
                  formik.touched.cardNumber && Boolean(formik.errors.cardNumber)
                }
                helperText={
                  formik.touched.cardNumber && formik.errors.cardNumber
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                label="Fecha de expiración"
                id="cardExpiration"
                name="cardExpiration"
                variant="outlined"
                onChange={(event) => {
                  const text = event.target.value.toString(); // Ensure it's a string
                  const formatted = expirationDateFormatter
                    ? expirationDateFormatter(
                        formik.values.cardExpiration,
                        text
                      )
                    : text;
                  setExpiration(formatted);
                  formik.setFieldValue("cardExpiration", formatted);
                }}
                onBlur={formik.handleBlur}
                value={expiration}
                error={
                  formik.touched.cardExpiration &&
                  Boolean(formik.errors.cardExpiration)
                }
                helperText={
                  formik.touched.cardExpiration && formik.errors.cardExpiration
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                label="CCV"
                id="cardSecurityCode"
                name="cardSecurityCode"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cardSecurityCode}
                error={
                  formik.touched.cardSecurityCode &&
                  Boolean(formik.errors.cardSecurityCode)
                }
                helperText={
                  formik.touched.cardSecurityCode &&
                  formik.errors.cardSecurityCode
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <Select
                labelId="issuer-select-label"
                id="issuer-select-label"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.issuer}
                name="issuer"
                error={formik.touched.issuer && Boolean(formik.errors.issuer)}
              >
                {issuerList.map((issuer) => {
                  return (
                    <MenuItem value={issuer.value}>{issuer.label}</MenuItem>
                  );
                })}
              </Select>
              <Typography variant="body2" color="red">
                {formik.touched.issuer && formik.errors.issuer}
              </Typography>
            </FormControl>
            <FormControl fullWidth sx={{ visibility: "hidden" }}>
              <TextField
                disabled
                label="issuerByCardNumber"
                id="cardSecurityCode"
                name="issuerByCardNumber"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.issuerByCardNumber}
              />
            </FormControl>
          </Grid>
        </Grid>
      </>

      <Box display="flex">
        <Box marginLeft="auto">
          <ASbutton
            buttonText={"Guardar"}
            onClick={() => formik.handleSubmit()}
          />
        </Box>
      </Box>
    </form>
  );
};

export default AddCardForm;
