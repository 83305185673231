import { SET_USER_INFO, LOGOUT_USER, SET_IS_LOADING_INFO } from "../actions/user";
import { types } from "../types/types";

const initialState = {
  userProfile: {},
  userInfo: null,
  isLoadingInfo:false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.setUserProfile:
      return {
        ...state,
        userProfile: action.payload,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
      case SET_IS_LOADING_INFO:
        return {
          ...state,
          isLoadingInfo: action.payload.isLoadingInfo,
        };
    case LOGOUT_USER:
      // Handle logout here by resetting both user profile and user info
      return initialState;

    default:
      return state;
  }
};
