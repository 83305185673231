import { useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { BsFillHeartFill } from "react-icons/bs";

import afSegurosLogo from "../../assests/afSegurosLogo.png";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";

const SelectedPlan = ({
  plan,
  handleClose,
  index,
  agedPlan,
  setOpenPayPlan,
}) => {
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const isDown1300 = useMediaQuery("(max-width:1300px)");
  const isDown390 = useMediaQuery("(max-width:390px)");

  const applyToPlan = () => {
    handleClose(false);
    setOpenPayPlan(true);
  };

  return (
    <Card
      className={
        index === 1
          ? "shadow-lg border-0 plan-card"
          : "shadow border-0 plan-card"
      }
      style={{
        height: "650px",
        minWidth: isDown1300 ? (isDown390 ? "300px" : "350px") : "400px",
        backgroundColor: "#f5f6ff",
        zIndex: 400,
        fontSize: "14px",
      }}
    >
      <Card.Body className="d-flex flex-column justify-content-between">
        <div className="pt-4">
          <div className="mx-auto text-center">
            <img src={plan.imgURL} alt="Medalla" width="25%" />
          </div>
          <h3 className="text-center lh-1" style={{ fontSize: "48px" }}>
            {plan.tier} <br />
            <span className="fw-bold">{plan.subTier}</span>
          </h3>
        </div>

        <Card.Text className="mx-auto">
          <Stack direction="horizontal">
            <p className="w-50 fw-bold">Beneficios</p>
            <p className="ms-auto fw-bold">Cobertura</p>
          </Stack>
          {plan.data.map((datum) => {
            return (
              <Stack direction="horizontal" gap={3}>
                <p className="w-50">{datum.description}</p>
                <p className="ms-auto">{datum.value}</p>
              </Stack>
            );
          })}
        </Card.Text>
        <div>
          <Stack direction="horizontal" className="position-relative">
            <Button
              variant={"secondary"}
              className={
                index === 1 ? "w-50 mx-auto" : "text-white w-50 mx-auto"
              }
              onClick={applyToPlan}
            >
              {!agedPlan && "Activar"}
              {agedPlan && (
                <a
                  href="https://api.whatsapp.com/send/?phone=50685676200&text&type=phone_number&app_absent=0"
                  className="text-secondary text-decoration-none"
                  target="_blank"
                >
                  Activar
                </a>
              )}
            </Button>
          </Stack>
          <Stack direction="horizontal" className="position-relative">
            {index === 1 ? (
              <span
                className="rounded-circle text-white py-2 px-3 position-absolute"
                style={{ background: "#ff5353", top: 0, left: "20px" }}
              >
                <BsFillHeartFill size={"15px"} />
              </span>
            ) : null}
          </Stack>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SelectedPlan;
