import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { styles } from "../../styles/styles";
import { Close as CloseIcon } from "@mui/icons-material"; // Import CloseIcon
import { MdClose } from "react-icons/md";

const SponsorModal = ({ open, onClose, province, sponsors }) => {
  const theme = useTheme();
  // Use the sponsorsFiltered state to store the filtered sponsors
  const itemsPerPage = 16; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [sponsorsFiltered, setSponsorsFiltered] = useState([]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const filteredSponsors = sponsors
      .filter((sponsor) => {
        const result = sponsor.branches.some(
          (branch) => branch.province === province
        );
        return result;
      })
      .slice(startIndex, endIndex);
    setSponsorsFiltered(filteredSponsors);
  }, [province, sponsors, currentPage]);

  const handleLink = () => {
    window.open("https://www.allsports.plus/", "_blank");
  };

  const totalPages = Math.ceil(
    sponsors.filter((sponsor) => sponsor.province === province).length /
      itemsPerPage
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      PaperProps={{
        sx: {
          boxShadow: "none",
          background: "transparent",
          minWidth: "600px",
          borderRadius: "18px",
          position: "relative",
          [theme.breakpoints.down("md")]: {
            minWidth: "90%",
          },
        },
      }}
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={onClose}
        sx={{ position: "absolute", right: 30, color: "white" }}
      >
        <MdClose size={32} />
      </IconButton>
      <DialogTitle
        mb={4}
        variant="h5"
        sx={{
          fontWeight: "bold",
          backgroundColor: theme.palette.primary.main,
          width: "450px",
          borderTopRightRadius: "18px",
          borderTopLeftRadius: "18px",
          mb: 0,
          ml: 2,
          [theme.breakpoints.down("md")]: {
            width: "90%",
          },
        }}
        color={"white"}
      >
        <Typography variant="h5">Red de Comercios</Typography>
        {`Afiliados en ${province}`}
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        {sponsorsFiltered.length > 0 ? (
          <Grid
            container
            spacing={0}
            pt={2}
            pb={2}
            justifyContent={"center"}
            alignItems={"center"}
            rowGap={4}
            sx={styles.sponsorsList}
          >
            {sponsorsFiltered.map((sponsor, index) => (
              <Grid
                item
                key={index}
                xs={4}
                sm={6}
                md={2}
                sx={{ display: "flex" }}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                    maxWidth: "90px",
                    height: "80px",
                    width: "90px",
                    padding: 0.5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "18px",
                    position: "relative",
                    borderWidth: "2px",
                    border: "solid",
                    borderColor: "white",
                    boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px;",
                  }}
                >
                  <img
                    src={sponsor.logo}
                    alt={sponsor.name}
                    style={{
                      width: "auto",
                      height: "100%",
                      borderRadius: "18px",
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid
            container
            spacing={0}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid container spacing={0} sx={styles.noData}>
              <Typography>No Hay sponsors en este región</Typography>
              <Typography
                variant="body1"
                color={theme.palette.primary.main}
                sx={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => handleLink()}
              >
                Conviertete en Sponsor
              </Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      {sponsorsFiltered.length > 0 && (
        <Grid container justifyContent="center" mt={2}>
          <IconButton
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            sx={{ backgroundColor: "white", width: 28, height: 28, mx: 1 }}
          >
            <Typography variant="body1">&lt;</Typography>
          </IconButton>
          {Array.from({ length: totalPages }, (_, i) => (
            <IconButton
              sx={{ backgroundColor: "white", width: 28, height: 28, mx: 1 }}
              key={i}
              onClick={() => setCurrentPage(i + 1)}
              color={i + 1 === currentPage ? "primary" : "default"}
            >
              <Typography variant="body1">{i + 1}</Typography>
            </IconButton>
          ))}
          <IconButton
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            sx={{ backgroundColor: "white", width: 28, height: 28, mx: 1 }}
          >
            <Typography variant="body1">&gt;</Typography>
          </IconButton>
        </Grid>
      )}
    </Dialog>
  );
};

export default SponsorModal;
