import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

import { bottomListData } from "../../utils/landingData.js";
import PromoCard from "../cards/PromoCard";
import { styles } from "../../styles/styles";
import Plus from "../../assests/svg/Plus";
import cellphones from "../../assests/cells.png";
import playstore from "../../assests/playstore.png";
import apple from "../../assests/apple.png";

const BottomSection = ({ theme }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (item) => {
    setSelectedItem(item.id);
    // Add your onClick logic here
  };
  const handleApple = () => {
    window.open(
      "https://apps.apple.com/us/app/all-sports/id1625764526",
      "_blank"
    );
  };
  const handleAndroid = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=plus.allsports.app",
      "_blank"
    );
  };

  const renderList = () => {
    return (
      <List>
        {bottomListData.map((item) => (
          <ListItem key={item.id} sx={{ pl: 0, pr: 0, mb: 1 }}>
            <Box mr={1}>
              <Plus />
            </Box>
            <ListItemText>
              <Typography variant="body1" color={"white"} sx={{ fontSize: 16 }}>
                {item.text}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Container maxWidth="false" sx={{ padding: "0 !important" }}>
      {/* FOURTH SECTION */}

      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          px: { xs: "20px", sm: "40px" },
        }}
      >
        <Grid
          container
          justifyContent={{
            md: "space-between",
            xs: "center",
            sm: "center",
            xxl: "space-between",
          }}
          alignItems={"flex-start"}
          mt={16}
          sx={styles.bottomCard}
        >
          <Grid
            item
            container
            xs={12}
            md={6}
            mt={{ xs: 4, md: 6, lg: 6 }}
            sx={{
              [theme.breakpoints.up("xxl")]: {
                minHeight: "650px",
              },
            }}

            //   mb={10}
          >
            <Box
              sx={{
                width: "75%",
                [theme.breakpoints.down("sm")]: {
                  width: "90%",
                  margin: "1em auto",
                },
              }}
              mt={1}
            >
              <Typography
                variant="h3"
                color={"white"}
                sx={{
                  fontWeight: "500",
                  fontSize: 48,
                  [theme.breakpoints.down("md")]: {
                    fontSize: 28,
                    mb: 4,
                  },
                }}
              >
                Listo para iniciar
              </Typography>
            </Box>
            <Box
              sx={{
                width: "75%",
                [theme.breakpoints.down("md")]: {
                  width: "90%",
                  margin: "1em auto",
                },
              }}
              mt={1}
            >
              <Typography
                variant="h4"
                color={"white"}
                sx={{
                  fontWeight: "600",
                  fontSize: 24,
                  [theme.breakpoints.down("md")]: {
                    fontSize: 18,
                  },
                }}
              >
                ¿Quieres saber cómo aprovechar al máximo All Sports +? ¡Es muy
                sencillo!
              </Typography>
            </Box>
            <Box
              sx={{
                width: "65%",
                [theme.breakpoints.down("md")]: {
                  width: "90%",
                  margin: "0 auto",
                },
              }}
              mt={1}
            >
              {renderList()}
            </Box>
            <Box
              sx={{
                flexDirection: "row",
                [theme.breakpoints.down("md")]: {
                  display: "flex",
                  margin: " 2em auto",
                },
                [theme.breakpoints.down("sm")]: {
                  display: "flex",
                  flexDirection: "column",
                  margin: " 2em auto",
                },
              }}
              mt={2}
              mb={6}
            >
              <Box
                component="img"
                sx={{
                  width: "auto",
                  width: "150px",
                  cursor: "pointer",
                  [theme.breakpoints.down("sm")]: {
                    marginRight: "auto",
                    marginLeft: "auto",
                    width: "125px",
                    mb: 2,
                  },
                }}
                alt="playstore"
                src={playstore}
                onClick={handleAndroid}
                mr={6}
              />
              <Box
                component="img"
                sx={{
                  width: "auto",
                  width: "150px",
                  cursor: "pointer",
                  [theme.breakpoints.down("sm")]: {
                    marginRight: "auto",
                    marginLeft: "auto",

                    width: "125px",
                  },
                }}
                alt="The house from the offer."
                onClick={handleApple}
                src={apple}
              />
            </Box>
            <Box
              sx={{
                width: "85%",
                [theme.breakpoints.down("md")]: {
                  width: "90%",
                  margin: "0 auto",
                },
              }}
              mt={4}
            >
              <Typography
                variant="body1"
                color={"white"}
                sx={{ fontWeight: "400", mb: 4 }}
                fontSize={12}
              >
                *Ofrecemos opciones flexibles para empresas, asociaciones y
                entrenadores. ¡Conversemos!
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                justifyContent: { xs: "center", sm: "center" },
                [theme.breakpoints.down("md")]: {
                  display: "flex",
                },
              }}
            >
              <Box
                component="img"
                sx={{
                  position: "absolute",
                  height: "auto",
                  width: "100%",
                  top: -55,
                  bottom: 0,
                  [theme.breakpoints.down("xxl")]: {
                    position: "absolute",
                    width: "600px",
                    top: -50,
                    bottom: 0,
                  },

                  [theme.breakpoints.up("xxl")]: {
                    position: "absolute",
                    width: "60%",
                    top: -50,
                    bottom: 0,
                    pl: 6,
                  },
                  [theme.breakpoints.down("md")]: {
                    position: "relative",
                    width: "65%",
                    margin: "4em auto 0 auto",
                  },
                }}
                alt="phones"
                src={cellphones}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default BottomSection;
