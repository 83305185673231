import ImageOne from "../assests/card1.png";
import ImageTwo from "../assests/card2.png";
import ImageThree from "../assests/card3.png";
import ImageFour from "../assests/card4.png";
import IconOne from "../assests/Icon.png";
import IconTwo from "../assests/Icon2.png";
import IconThree from "../assests/Icon3.png";
import af from "../assests/afSegurosLogo.png";
import trucha from "../assests/ciclotrucha.png";
import unibe from "../assests/unibe.png";
import sapore from "../assests/sapore.png";
import hutch from "../assests/hutch.png";
import selva from "../assests/laselva.png";
import windham from "../assests/windham.png";
import { MdEmail, MdLocalPhone, MdLocationPin } from "react-icons/md";
export const topData = [
  {
    id: 1,
    text: "Sincroniza con relojes inteligentes",
    icon: IconOne,
  },
  {
    id: 2,
    text: "Cada día es un inicio",
    icon: IconTwo,
  },
  {
    id: 3,
    text: "Desbloquea recompensas ",
    icon: IconThree,
  },
];
export const contactData = [
  {
    id: 1,
    text: "Tibas, San José",
    text2: "Costa Rica",
    icon: <MdLocationPin color="white" />,
    link: "https://www.google.com/maps?q=Tibas,San+Jose,Costa+Rica",
  },
  {
    id: 2,
    text: "+506 8888-8808",
    text2: "",
    icon: <MdLocalPhone color="white" />,
    link: "https://wa.me/50688888808",
  },
  {
    id: 3,
    text: "info@allsports.plus",
    text2: "allsports.plus",
    icon: <MdEmail color="white" />,
    link: "mailto:info@allsports.plus",
  },
];

export const cardData = [
  {
    id: 1,
    text: "Adquiere",
    title: "Seguro Médico Especial",
    list: [
      "Cobertura por cualquier tipo de accidente, 24/7",
      "Asistencias médicas",
      "Adquiérelo desde el App o Web",
    ],
    image: ImageOne,
    bgColor: "#F5E4D3",
  },
  {
    id: 2,
    text: "Cada día es un inicio",
    title: "Catálogo de Descuentos",
    list: [
      "Disfruta de los beneficios exclusivos de nuestra membresía",
      "Todo tipo de comercio",
      "En todo el país",
    ],
    image: ImageTwo,
    bgColor: "#D2D5F5",
  },
  {
    id: 3,
    text: "Desbloquea recompensas ",
    title: "Convierte tus calorías",
    list: [
      "Sincroniza y acumula Sports Coins",
      "Disfruta y dona tus Sports Coins",
    ],
    image: ImageThree,
    bgColor: "#D2EEF5",
  },
  {
    id: 3,
    text: "Desbloquea recompensas ",
    title: "Contenido Educativo",
    list: [
      "Educación desde la Ciencia",
      "Diversas Actividades Física",
      "Guías de Nutrición, Salud y Bienestar, Exclusivos",
    ],
    image: ImageFour,
    bgColor: "#625EA7",
  },
];

export const bottomListData = [
  {
    id: 1,
    text: "Descarga nuestra APP y regístrate.",
  },
  {
    id: 2,
    text: "Accede a la sección de Seguros y elige el que mejor se adapte a ti.",
  },
  {
    id: 3,
    text: "¡Listo! Ahora puedes empezar a disfrutar de todos los beneficios y actividades.",
  },
];
export const faqData = [
  {
    id: 1,
    text: "¿Cómo descargar el APP",
    answer:
      " Actualmente el nuestra app funciona en dispositivos Android y iPhone. Puedes descargarla a través de Play Store y App Store.",
  },
  {
    id: 2,
    text: "¿Qué son causas de impacto social?",
    answer:
      " Actualmente el nuestra app funciona en dispositivos Android y iPhone. Puedes descargarla a través de Play Store y App Store.",
  },
  {
    id: 3,
    text: "¿Por qué debo integrar un app deportiva?",
    answer:
      " Actualmente el nuestra app funciona en dispositivos Android y iPhone. Puedes descargarla a través de Play Store y App Store.",
  },
  {
    id: 3,
    text: "Errores frecuentes",
    answer:
      " Actualmente el nuestra app funciona en dispositivos Android y iPhone. Puedes descargarla a través de Play Store y App Store.",
  },
];

export const linkListData = [
  {
    id: 1,
    text: "Sobre All Sports Plus",
    section: "about",
  },
  {
    id: 2,
    text: "Plan Premium",
    section: "plan",
  },
  {
    id: 3,
    text: "Compra y Facturación",
    section: "payment",
  },
  {
    id: 4,
    text: "Seguro Médico",
    section: "insurance",
  },
  {
    id: 5,
    text: "Coins, canjes y descuentos",
    section: "coins",
  },
  {
    id: 6,
    text: "Aplicación",
    section: "app",
  },
  {
    id: 7,
    text: "Cuenta y configuración",
    section: "config",
  },
];

export const sponsorData = [
  {
    id: 1,
    img: trucha,
  },
  {
    id: 2,
    img: unibe,
  },
  {
    id: 3,
    img: sapore,
  },
  {
    id: 4,
    img: af,
  },
  {
    id: 5,
    img: hutch,
  },
  {
    id: 6,
    img: selva,
  },
  {
    id: 7,
    img: windham,
  },
];

export const benefitsData = [
  {
    id: 1,
    text: "Adquiere",
    title: "Seguro Médico Especial",
    list: [
      "Cobertura por cualquier tipo de accidente, 24/7",
      "Asistencias médicas",
      "Adquiérelo desde el App o Web",
    ],
    image: ImageOne,
    bgColor: "#F5E4D3",
  },
  {
    id: 2,
    text: "Cada día es un inicio",
    title: "Catálogo de Descuentos",
    list: [
      "Disfruta de los beneficios exclusivos de nuestra membresía",
      "Todo tipo de comercio",
      "En todo el país",
    ],
    image: ImageTwo,
    bgColor: "#D2D5F5",
  },
  {
    id: 3,
    text: "Desbloquea recompensas ",
    title: "Convierte tus calorías",
    list: [
      "Sincroniza y acumula Sports Coins",
      "Disfruta y dona tus Sports Coins",
    ],
    image: ImageThree,
    bgColor: "#D2EEF5",
  },
  {
    id: 3,
    text: "Desbloquea recompensas ",
    title: "Contenido Educativo",
    list: [
      "Educación desde la Ciencia",
      "Diversas Actividades Física",
      "Guías de Nutrición, Salud y Bienestar, Exclusivos",
    ],
    image: ImageFour,
    bgColor: "#5B48D2",
  },
];
