import React, { useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import costarica from "../../assests/svg/costarica.svg";
import CostaRica from "../../assests/svg/CostaRica";
import { Container, Grid } from "@mui/material";
const CustomMap = ({ handleRegionClick }) => {
  return (
    <Grid container justifyContent={"center"} mt={10} mb={10}>
      <Grid
        item
        md={10}
        xs={12}
        sx={{ display: "flex" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CostaRica handleRegionClick={handleRegionClick} />
      </Grid>
    </Grid>
  );
};

export default CustomMap;
