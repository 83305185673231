import { SUPPORT_PHONE } from "../actions/auth";
import { types } from "../types/types";

const initialState = {
  user: null,
  loggedIn: false,
  idToken: "",
  isLoadingAuth: true,
  supportPhone: "+50687038008",
  errorMsg: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.login:
      return {
        ...state,
        user: action.payload.user,
        loggedIn: action.payload.loggedIn,
        idToken: action.payload.idToken,
        isNewUser: action.payload.userState,
      };
    case types.isLoadingAuth:
      return {
        ...state,
        isLoadingAuth: action.payload.isLoadingAuth,
      };
    case types.errorMsg:
      return {
        ...state,
        errorCode: action.payload.errorCode,
        errorMsg: action.payload.errorMsg,
      };
    case SUPPORT_PHONE:
      return {
        ...state,
        supportPhone: action.payload,
      };
    case types.logout:
      return initialState;

    default:
      return state;
  }
};
