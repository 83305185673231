import { Stack } from "react-bootstrap";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

import logo from "../../assests/footer-logo.png";
import { Box } from "@mui/material";

const AllSportDetails = ({ theme }) => {
  return (
    <>
      <Box
        component={"img"}
        src={logo}
        sx={{
          width: "250px",
          height: "auto",
          cursor: "pointer",
          mb: 1,
          position: "relative",
          left: "-22px",

          [theme.breakpoints.down("sm")]: {
            width: "175px",
            height: "auto",
            left: "-18px",
          },
        }}
      />
      <p className="fw-bold text-white mb-0">Monetiza tu esfuerzo</p>
      <p style={{ color: "#9b9ca8" }}>
        Transforma tus habitos saludables en beneficios medicos, bienestar
        integral e impacto social directo
      </p>

      <Stack
        direction="horizontal"
        gap={4}
        style={{ color: "#9b9ca8", marginTop: "50px" }}
      >
        <span className="social-icons">
          <FaFacebookF
            className="social-icons"
            size={18}
            href="https://www.facebook.com/allsportsplus.cr"
            target="_blank"
          />
        </span>
        {/* <a className="social-icons">
          <FaTwitter className="social-icons" size={18} />
        </a>
        <span className="social-icons">
          <FaYoutube className="social-icons" size={18} />
        </span>
        <span className="social-icons">
          <FaLinkedin className="social-icons" size={18} />
        </span> */}
        <a
          className="social-icons"
          href="https://www.instagram.com/allsportsplus.cr/"
          target="_blank"
        >
          <FaInstagram className="social-icons" size={18} />
        </a>
      </Stack>
    </>
  );
};

export default AllSportDetails;
