import MUIDataTable from "mui-datatables";
import { useColumns } from "./useColumns";
import { Box, Button, Typography } from "@mui/material";
import { styles } from "../../../styles/styles";
import { useEffect, useState } from "react";
import { getUserPayments } from "../../../store/actions/user";
import { useSelector } from "react-redux";

const elementsLimit = 3;
let isProduction = process.env.NODE_ENV === "production";

const PaymentHistory = () => {
  const paymentColumns = useColumns();
  const { user } = useSelector((state) => state.auth);

  const [userPayments, setUserPayments] = useState([]);
  const [lastPayment, setLastPayment] = useState(null);
  const [paymentDataSize, setPaymentDataSize] = useState(10);

  const getOneUserPayments = async () => {
    const result = await getUserPayments(user.uid, lastPayment, elementsLimit);

    const payments = result.map((item) => {
      return {
        id: item.id,
        transactionId: item.transactionId,
        transactionDate: item.transactionDate,
        transactionAmount: item.transactionAmount,
        plan: item.plan.subTier,
      };
    });

    console.log(payments);

    if (!isProduction) {
      setUserPayments((prevValues) => {
        const temp = [...payments, ...prevValues];

        const uniqueArr = temp.filter(
          (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
        );

        return uniqueArr;
      });
    } else {
      setUserPayments((prevValues) => [...payments, ...prevValues]);
    }

    setLastPayment(payments[payments.length - 1]);
    setPaymentDataSize(payments.length);
  };

  useEffect(() => {
    getOneUserPayments();
  }, []);

  const options = {
    selectableRows: "none",
    responsive: "standard",
    filter: false,
    search: false,
    print: false,
    viewColumns: false,
    customFooter: () => <></>,
    textLabels: {
      body: {
        noMatch: "No se han encontrado registros coincidentes",
      },
      pagination: {
        rowsPerPage: "Filas en cada página:",
      },
    },
  };

  return (
    <>
      <Typography variant="h5" my={2} sx={{ fontWeight: "bold" }}>
        Facturación
      </Typography>
      <Box sx={styles.summaryCard}>
        <MUIDataTable
          columns={paymentColumns}
          data={userPayments}
          options={options}
        />
      </Box>

      <Box sx={{ paddingRight: "24px" }}>
        <Box textAlign="left">
          <Button
            variant="contained"
            sx={{ borderRadius: "18px", my: 4 }}
            disabled={paymentDataSize < elementsLimit}
            onClick={() => getOneUserPayments}
          >
            Ver más
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default PaymentHistory;
