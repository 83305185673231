import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { styles } from "../../styles/styles";
import PlanModal from "../general/PlanModal";
import { IconButton } from "@mui/material";
import { MdArrowOutward, MdQrCode, MdQuestionMark } from "react-icons/md";
import { getAllPlans } from "../../store/actions/plans";
import Plans from "../plans/Plans";

const ActivePlans = ({
  handleNext,
  handleBack,
  selectedPlan,
  setSelectedPlan,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.plans.plans);
  const [productsFromDB, setProductsFromDB] = useState([]);
  const [inactiveProducts, setInactiveProducts] = useState([]);
  const [loading, setloading] = useState(false);
  const isFirstUse = useRef(true);
  const [noDataAvailable, setNoDataAvailable] = useState(false);

  const dummyPlans = [
    { id: 1, subTier: "Basic Plan", code: "BASIC123", price: 10.99 },
    { id: 2, subTier: "Standard Plan", code: "STANDARD456", price: 19.99 },
    { id: 3, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 4, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 5, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 6, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 7, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 8, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 9, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },
    { id: 10, subTier: "Premium Plan", code: "PREMIUM789", price: 29.99 },

    // ... add more dummy plans
  ];

  useEffect(() => {
    if (plans) {
      const filteredProducts = plans.filter((p) => p.isB2bActive);
      const inactive = plans.filter((p) => !p.isB2bActive);
      setProductsFromDB(filteredProducts);
      setInactiveProducts(inactive);
    }
  }, [plans]);

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan.id === selectedPlan ? null : plan);
  };
  const next = () => {
    const dataToAdd = {
      planSelected: selectedPlan,
    };

    handleNext(dataToAdd);
  };

  const renderGrid = () => {
    if (loading && !noDataAvailable) {
      return (
        <Grid container spacing={2} sx={[styles.planSelectionLoading]}>
          <CircularProgress />
        </Grid>
      );
    }
    if (noDataAvailable || productsFromDB.length === 0) {
      return (
        <Grid container spacing={2} sx={[styles.planSelectionLoading]}>
          <Typography>No Hay productos disponibles</Typography>
        </Grid>
      );
    }

    return (
      <Grid
        container
        spacing={0}
        rowGap={0}
        justifyContent={"center"}
        sx={styles.planSelection}
        // justifyContent={
        //   productsFromDB.length > 2 ? "space-around" : "flex-start"
        // }
      >
        <Plans />
      </Grid>
    );
  };

  return renderGrid();
};

export default ActivePlans;
