export const types = {
  login: "[Auth] Login",
  logout: "[Auth] Logout",

  isLoadingAuth: "[Auth] isLoading",
  errorMsg: "[Auth] error",
  setUserProfile: "[SET] UserProfile",

  getPlans: "[GET] plans",
};
