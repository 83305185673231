import Form from "react-bootstrap/Form";
import { Stack, Button, Row, Col } from "react-bootstrap";

import PurchaseRaceProgress from "../PurchaseRaceProgress/PurchaseRaceProgress";

import { useFormik } from "formik";

import { FiArrowRight } from "react-icons/fi";
import { BsGoogle } from "react-icons/bs";

import { Stepper, Step, StepLabel, useMediaQuery } from "@mui/material";
import register from "../../assests/register.png";

import { signUpFormFormSchema } from "../../schemas/signupSchema";
import { useDispatch, useSelector } from "react-redux";
import {
  loginWithGoogle,
  signUpWithEmailAndPassword,
} from "../../store/actions/auth";

import "./formStyles.scss";
import { useState } from "react";
import SignInModal from "../modals/SignInModal";
import { setUserProfile } from "../../store/actions/user";
import { useTheme } from "@emotion/react";

const SignUpForm = ({
  handleNext,
  handleClose: setOpenSignUp,
  handleOpenLogIn,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const {
    setFieldValue,
    handleChange,
    touched,
    errors,
    values,
    isValid,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      lastname: "",
      secondLastname: "",
      password: "",
      passwordConfirmation: "",
      termsAndConditions: false,
    },
    validationSchema: signUpFormFormSchema,
    onSubmit: (values) => {
      const profile = {
        name: values.name,
        lastname: values.lastname,
        secondLastname: values.secondLastname,
      };

      dispatch(setUserProfile(profile));

      dispatch(signUpWithEmailAndPassword(values.email, values.password));
      handleNext();
    },
  });

  return (
    <Row>
      <Col xl="7">
        <PurchaseRaceProgress progressPorcentage={10} />
        <h4 className="fw-bold my-3">Registrate</h4>
        <form>
          <Row>
            <Form.Group className="mb-3" controlId="groupName" as={Col} md="6">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                onChange={handleChange}
                name="name"
                isInvalid={!!errors.name && touched.name}
                onBlur={handleBlur}
                value={values.name}
              />

              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="groupLastname"
              as={Col}
              md="6"
            >
              <Form.Label>Apellido</Form.Label>
              <Form.Control
                type="text"
                placeholder="Apellido"
                onChange={handleChange}
                name="lastname"
                isInvalid={!!errors.lastname && touched.lastname}
                onBlur={handleBlur}
                value={values.lastname}
              />

              <Form.Control.Feedback type="invalid">
                {errors.lastname}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              className="mb-3"
              controlId="groupSecondLastname"
              as={Col}
              md="6"
            >
              <Form.Label>Segundo apellido</Form.Label>
              <Form.Control
                type="text"
                placeholder="Segundo apellido"
                onChange={handleChange}
                name="secondLastname"
                isInvalid={!!errors.secondLastname && touched.secondLastname}
                onBlur={handleBlur}
                value={values.secondLastname}
              />

              <Form.Control.Feedback type="invalid">
                {errors.secondLastname}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="groupEmail" as={Col} md="6">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                onChange={handleChange}
                name="email"
                isInvalid={!!errors.email && touched.email}
                onBlur={handleBlur}
                value={values.email}
              />

              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              className="mb-3"
              controlId="groupPassword"
              as={Col}
              md="6"
            >
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                placeholder="Contraseña"
                onChange={handleChange}
                name="password"
                isInvalid={!!errors.password && touched.password}
                onBlur={handleBlur}
                value={values.password}
              />

              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="groupPassRepeat"
              as={Col}
              md="6"
            >
              <Form.Label>Repita su contraseña</Form.Label>
              <Form.Control
                type="password"
                placeholder="Contraseña"
                onChange={handleChange}
                name="passwordConfirmation"
                isInvalid={
                  !!errors.passwordConfirmation && touched.passwordConfirmation
                }
                onBlur={handleBlur}
                value={values.passwordConfirmation}
              />

              <Form.Control.Feedback type="invalid">
                {errors.passwordConfirmation}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Form.Group className="mb-3">
            <Form.Check
              name="termsAndConditions"
              onChange={handleChange}
              isInvalid={!!errors.termsAndConditions}
              label={
                <span>
                  Acepto los{" "}
                  <a href="#" style={{ color: "#168FCA" }}>
                    Terminos y condiciones
                  </a>{" "}
                  de uso de All Sports Plus
                </span>
              }
              feedback={errors.termsAndConditions}
              feedbackType="invalid"
            />
          </Form.Group>

          <Form.Group>
            <Button
              variant="link"
              onClick={() => {
                setOpenSignUp(false);
                handleOpenLogIn(true);
              }}
              style={{ color: "#168FCA", paddingLeft: 0 }}
            >
              ¿Ya tiene una cuenta?
            </Button>
          </Form.Group>

          <Row className="mt-4">
            <Col md="6" sm="12" className="mb-3">
              <Button
                style={{
                  backgroundColor: theme.palette.primary.main,
                  width: "100%",
                  padding: "10px 15px",
                  borderRadius: "15px",
                }}
                disabled={!isValid}
                onClick={() => {
                  handleSubmit();
                }}
                variant="secondary"
              >
                Avanzar <FiArrowRight />
              </Button>
            </Col>
            <Col md="6" sm="12">
              <Button
                style={{
                  background: theme.palette.terciary, //"#4285f4",
                  width: "100%",
                  color: "#fff",
                  padding: "10px 15px",
                  borderRadius: "15px",
                }}
                onClick={() => {
                  dispatch(loginWithGoogle(handleNext));
                }}
              >
                <span className="me-2">
                  <BsGoogle />
                </span>
                Continuar con Google
              </Button>
            </Col>
          </Row>
        </form>
      </Col>
      <Col>
        <div
          className="position-absolute sign-up-form-biker d-none d-xl-block"
          style={{ width: "35%", top: "20px", right: "40px" }}
        >
          <img
            src={register}
            alt=""
            style={{ borderRadius: "55px", width: "100%" }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default SignUpForm;
