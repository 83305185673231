import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { styles } from "../../styles/styles";

import { useSelector } from "react-redux";
import SponsorGrid from "./SponsorGrid.js";
import { getMainSponsors } from "../../store/actions/sponsors";

const SponsorsSection = ({ theme }) => {
  const { supportPhone } = useSelector((state) => state.auth);

  const [sponsors, setSponsors] = useState([]);
  const [activeSponsor, setActiveSponsor] = useState(null);
  const [activeSponsorIndex, setActiveSponsorIndex] = useState(0);
  const [imageLoading, setImageLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [intervalPaused, setIntervalPaused] = useState(false); // Add state to track interval pause
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (!dataFetched) {
      const fetchSponsors = async () => {
        const sponsorsData = await getMainSponsors();
        setSponsors(sponsorsData);
        setDataFetched(true);
      };

      fetchSponsors();
    }
  }, [dataFetched]);

  useEffect(() => {
    const active = sponsors.find(
      (sponsor) => sponsor.id === activeSponsorIndex.toString()
    );
    console.log(active);
    setActiveSponsor(active);
  }, [activeSponsorIndex]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!intervalPaused) {
        setActiveSponsorIndex((prevIndex) => (prevIndex + 1) % sponsors.length);
        setImageLoading(false);
      } else {
        setActiveSponsorIndex((prevIndex) => (prevIndex + 1) % sponsors.length);
        setImageLoading(false);
      }
    }, 3000);

    return () => clearInterval(timer);
  }, [sponsors, intervalPaused]);

  const pauseInterval = () => {
    setIntervalPaused(true);
    setActiveSponsorIndex(parseInt(activeSponsor?.id, 10));
    setTimeout(() => {
      setIntervalPaused(false);
    }, 3000);
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        minHeight: "600px",
        [theme.breakpoints.down("sm")]: {
          minHeight: "600px",
        },
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          mt={10}
          sx={styles.sponsorCard}
        >
          <Grid
            item
            container
            xs={12}
            md={10}
            lg={8}
            mt={{ xs: 4, md: 6, lg: 6 }}
          >
            <Box sx={{ width: "85%" }}>
              <Typography
                variant="body1"
                color={"white"}
                sx={{ fontWeight: "400", mb: 2 }}
                fontSize={12}
              >
                Sponsors
              </Typography>
            </Box>
            <Box sx={{ width: "85%", mb: 2 }}>
              <Typography
                variant="h3"
                color={"white"}
                sx={{
                  fontWeight: "600",
                  fontSize: 38,
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                    fontWeight: "600",
                    fontSize: 24,
                  },
                }}
              >
                Explora nuestro centro de canjes y descuentos para brindarte una
                experiencia única!
              </Typography>
            </Box>
            <Box
              sx={{
                width: "75%",
                [theme.breakpoints.down("xl")]: {
                  width: "95%",
                },
              }}
              mt={1}
            >
              <Typography
                variant="h4"
                color={"white"}
                sx={{ fontWeight: "400", fontSize: 16 }}
              >
                Desde hoteles, gimnasios, restaurantes hasta universidades, la
                variedad es infinita. ¿Listo para aprovechar al máximo tus
                oportunidades?
              </Typography>
            </Box>

            <SponsorGrid
              pauseInterval={pauseInterval}
              theme={theme}
              sponsors={sponsors}
              activeSponsor={activeSponsor}
              activeSponsorIndex={activeSponsorIndex}
              imageLoading={imageLoading}
              setActiveSponsorIndex={setActiveSponsorIndex}
              isMobile={isMobile}
            />
          </Grid>
          {!isMobile && (
            <Grid
              item
              container
              xs={12}
              md={10}
              lg={4}
              sx={{
                display: "flex",
                height: "600px",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,

                [theme.breakpoints.down("lg")]: {
                  height: "350px",
                  mb: 5,
                },
              }}
            >
              <Box
                sx={{
                  width: "300px",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 50,
                  backgroundColor: "white",
                  boxShadow: "rgba(0, 0, 0, 0.4) 0px 6px 10px;",
                  ml: 10,
                  [theme.breakpoints.down("md")]: {
                    ml: 0,
                  },
                }}
              >
                {imageLoading ? (
                  <CircularProgress
                    color="secondary"
                    size={125}
                    thickness={2}
                  />
                ) : (
                  <Box
                    component="img"
                    sx={{
                      height: activeSponsorIndex !== 2 ? "200px" : "150px",
                      width: activeSponsorIndex !== 2 ? "200px" : "150px",
                      userSelect: "none",
                      borderRadius: activeSponsorIndex !== 2 ? "50%" : 0,
                      [theme.breakpoints.down("xl")]: {
                        //   width: "600px",
                        //   top: -50,
                        //   bottom: 0,
                      },
                    }}
                    alt="join"
                    src={
                      activeSponsor && activeSponsor.imgURL
                        ? activeSponsor.imgURL
                        : "default-image-url"
                    }
                  />
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default SponsorsSection;
