import { db } from "../../firebase/firebase-config";
import { types } from "../types/types";

export const getAllPlans = () => {
  return async (dispatch) => {
    const data = await db
      .collection("products")
      .orderBy("finalPrice", "asc")
      .get();

    const items = [];

    data.docs.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });

    const plans = items.filter((item) => {
      if (item.subTier !== "Training" && item.subTier !== "Testing") {
        return true;
      } else {
        return false;
      }
    });

    dispatch(getPlans(plans));
  };
};

const getPlans = (plans) => ({
  type: types.getPlans,
  payload: {
    plans,
  },
});
