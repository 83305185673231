import React, { useEffect, useState } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import Header from "../components/header/Header";
import Hero from "../components/hero/Hero";
import Plans from "../components/plans/Plans";
import Benefits from "../components/benefits/Benefits";
import Footer from "../shared/ASFooter";

import LogInModal from "../components/modals/logInModal";
import authErrors from "../firebase/firebaseErrorMsg";
import { useSelector } from "react-redux";
import SignInModal from "../components/modals/SignInModal";
import RegisterModal from "../components/modals/RegisterModal";
import PlansPreview from "../components/plans/PlansPreview";
import { useTheme } from "@mui/material";
import BottomSection from "../components/bottom/BottomSection";
import FaqSection from "../components/bottom/FaqSection";
import SponsorsSection from "../components/bottom/SponsorsSection";
import MapSection from "../components/customMap/MapSection";
import { analytics } from "../firebase/firebase-config";
export const HomePage = () => {
  const { loggedIn, user } = useSelector((state) => state.auth);
  const theme = useTheme();
  const [show, setShow] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const errorCode = useSelector((state) => state.auth.errorCode);

  analytics.logEvent("custom_event_name", {
    param1: "value1",
    param2: "value2",
  });

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "auto";
    }
  }, []);

  return (
    <>
      {errorCode && (
        <Alert
          severity="error"
          className="mb-3"
          sx={{ width: "100%", position: "absolute", top: "72.81px" }}
        >
          <AlertTitle>Error</AlertTitle>
          {authErrors[errorCode ? errorCode : "no-error"].msg}
        </Alert>
      )}
      {/* <Header /> */}
      <Hero theme={theme} loggedIn={loggedIn} user={user} />
      <PlansPreview theme={theme} />
      <Plans handleShow={handleShow} theme={theme} />
      <BottomSection theme={theme} />
      <FaqSection theme={theme} />
      <SponsorsSection theme={theme} />
      <MapSection />
      <Benefits theme={theme} />

      <RegisterModal
        open={openSignUp}
        setOpen={setOpenSignUp}
        openLogIn={setShow}
      />
      <SignInModal
        handleClose={handleClose}
        show={show}
        setOpenSignUp={setOpenSignUp}
      />
    </>
  );
};
