import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  useTheme,
  IconButton,
  Box,
  TextField,
  Button,
} from "@mui/material";
import Hero from "../components/hero/Hero";

import contact from "../assests/contact.png";

import { styles } from "../styles/styles";
import { contactData } from "../utils/landingData";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { uploadForm } from "../store/actions/user";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BottomSection from "../components/bottom/BottomSection";
import ActivePlans from "../components/profile/ActivePlans";
import Plans from "../components/plans/Plans";

const MySwal = withReactContent(Swal);

const PlansPage = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, []);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo Obligatorio"),
    email: Yup.string().email("Correo Inválido").required("Campo obligatorio"),
    message: Yup.string().required("Campo Obligatorio"),
  });
  const handleSubmit = async (values, { resetForm }) => {
    const confirmResult = await MySwal.fire({
      title: "¿Enviar? ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, enviar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    });

    if (confirmResult.isConfirmed) {
      try {
        await uploadForm(values);

        MySwal.fire("Enviado!", "", "success");
      } catch (error) {
        MySwal.fire("Error", "Hubo un error al enviar tu mensaje", "error");
      }

      setLoading(false);
    }

    console.log(values);
    resetForm();
  };

  return (
    <>
      <Hero theme={theme} />
      <Box mb={20}>
        <Plans />
      </Box>
    </>
  );
};

export default PlansPage;
