import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import CustomMap from "./CustomMap";
import { useCallback, useEffect, useRef, useState } from "react";
import { getSponsorsByProvince } from "../../store/actions/sponsors";
import { useDispatch, useSelector } from "react-redux";
import SponsorModal from "./SponsorsModal";
import { styles } from "../../styles/styles";

const MapSection = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { supportPhone } = useSelector((state) => state.auth);
  const { sponsors } = useSelector((state) => state.sponsors);
  const isFirstUse = useRef(true);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [clickedRegions, setClickedRegions] = useState([]);
  const getSponsorsFromDB = useCallback(async () => {
    try {
      await dispatch(getSponsorsByProvince());
      // setLoading(false) should be placed here when the data is successfully fetched
    } catch (error) {
      console.log(error);
      // Handle the error here, and you can choose to set loading to false or handle it differently.
    }
  }, [dispatch]);

  useEffect(() => {
    if (isFirstUse.current) {
      setLoading(true);
      getSponsorsFromDB().finally(() => {
        isFirstUse.current = false;
        setLoading(false); // Set loading to false after the operation is complete
      });
    }
    // Note: You can remove the "else" block because "sponsors.length > 0" is not a reliable indicator of loading completion.
  }, [getSponsorsFromDB]);

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleRegionClick = (regionName) => {
    setOpen(true);
    setClickedRegions(regionName);
  };

  const whatsappLink = `https://wa.me/${supportPhone}`;
  // useEffect(() => {
  //   console.log(sponsors);
  // }, [sponsors]);

  if (loading) {
    return (
      <Container maxWidth={"lg"} sx={{ justifyContent: "center" }}>
        <Box mt={6}>
          <Typography
            variant="h3"
            sx={{ fontSize: "2.3em", fontWeight: "600" }}
            color={theme.palette.primary.main}
          >
            Descubre nuestros afiliados por
          </Typography>
          <Typography
            variant="h3"
            sx={{ fontSize: "2.3em", fontWeight: "600" }}
            color={theme.palette.primary.main}
          >
            ubicación en el mapa
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "600px",
          }}
        >
          <CircularProgress />
        </Grid>
      </Container>
    );
  }
  //   if (noDataAvailable ) {
  //     return (
  //       <Grid container spacing={2} sx={[styles.planSelectionLoading]}>
  //         <Typography>No Hay planes inactivos</Typography>
  //       </Grid>
  //     );
  //   }

  return (
    <>
      <Container maxWidth={"xl"} sx={{ justifyContent: "center" }}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          sx={styles.sponsorCard}
          columnGap={1}
        >
          <Grid item md={9} mt={6}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: "600",

                fontSize: 42,
                [theme.breakpoints.down("sm")]: {
                  fontWeight: "600",
                  fontSize: 24,
                },
              }}
              color={theme.palette.primary.main}
            >
              Descubre nuestros afiliados por ubicación en el mapa
            </Typography>
          </Grid>
          <Grid
            item
            md={2.5}
            xs={12}
            alignItems={"center"}
            justifyContent={"center"}
            mt={6}
            sx={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "400", textAlign: "center" }}
              color={theme.palette.secondary.secondary}
              mb={3}
            >
              ¿Te gustaría formar parte de nuestra red de afiliados?
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                width: "100%",
                mx: "auto",
                color: "white",
                width: "100%",
                borderRadius: "18px",
                pt: 1.5,
                pb: 1.5,
                mx: "auto",
                mt: 1,
                "&:hover": {
                  backgroundColor: theme.palette.secondary.main,
                  cursor: "pointer !important",
                },

                "& .MuiPaper": {
                  cursor: "pointer !important",
                },
                [theme.breakpoints.down("sm")]: {
                  width: "50%",
                  mx: "auto",
                },
              }}
              onClick={() => window.open(whatsappLink, "_blank")}
            >
              <Typography
                variant="body1" // Use variant instead of component
                sx={{ fontWeight: "600", cursor: "pointer !important" }}
                onClick={() => window.open(whatsappLink, "_blank")}
              >
                ¡Únete ahora!
              </Typography>
            </Button>
          </Grid>
          <CustomMap handleRegionClick={handleRegionClick} />
        </Grid>
      </Container>

      <SponsorModal
        sponsors={sponsors}
        province={clickedRegions}
        open={open}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default MapSection;
