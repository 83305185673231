import React from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";

const SponsorGrid = ({
  theme,
  sponsors,
  activeSponsorIndex,
  activeSponsor,
  setActiveSponsorIndex,
  pauseInterval,
  imageLoading,
  isMobile,
}) => {
  const activeStyle = {
    color: theme.palette.accent.main,
    fontSize: 18,
    fontWeight: "800",
    textTransform: "uppercase",
    cursor: "pointer",
    boxShadow: "none",
  };

  const inactiveStyle = {
    color: "white",
    fontSize: 18,
    fontWeight: "700",
    textTransform: "uppercase",
    cursor: "pointer",
    boxShadow: "none",
  };
  const handleSponsorClick = (item) => {
    pauseInterval();
    setActiveSponsorIndex(parseInt(item?.id, 10));
  };

  return (
    <>
      <Grid
        container
        mt={4}
        mb={{ xs: 0, sm: 0, md: 4 }}
        justifyContent={"space-between"}
        width={{ md: "100%", sm: "100%", xs: "100%" }}
      >
        {sponsors.map((item) => (
          <Grid
            item
            key={item.id}
            container
            md={2}
            xs={6}
            mb={4}
            mt={4}
            justifyContent={"center"}
          >
            <Button
              disableRipple
              onClick={() => handleSponsorClick(item)}
              sx={
                item.id === activeSponsorIndex.toString()
                  ? activeStyle
                  : inactiveStyle
              }
            >
              {item.name}
            </Button>
          </Grid>
        ))}
      </Grid>
      {isMobile && (
        <Grid
          item
          container
          xs={12}
          md={10}
          lg={4}
          sx={{
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
            flex: 1,

            [theme.breakpoints.down("lg")]: {
              height: "350px",
              mb: 0,
            },
          }}
        >
          <Box
            sx={{
              width: "300px",
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 50,
              backgroundColor: "white",
              boxShadow: "rgba(0, 0, 0, 0.4) 0px 6px 10px;",
            }}
          >
            {imageLoading ? (
              <CircularProgress color="secondary" size={125} thickness={2} />
            ) : (
              <Box
                component="img"
                sx={{
                  height: "200px",
                  width: "200px",
                  userSelect: "none",
                  borderRadius: 50,
                  [theme.breakpoints.down("xl")]: {
                    //   width: "600px",
                    //   top: -50,
                    //   bottom: 0,
                  },
                }}
                alt="join"
                src={
                  activeSponsor && activeSponsor.imgURL
                    ? activeSponsor.imgURL
                    : "default-image-url"
                }
              />
            )}
          </Box>
        </Grid>
      )}
      <Grid
        container
        mb={{ xs: 2, md: 6 }}
        width={{
          md: "100%",
          sm: "100%",
          xs: "100%",
          minHeight: "80px",
          maxHeight: "80px",
          [theme.breakpoints.down("sm")]: {
            // minHeight: "100px",
            // maxHeight: "100x",
          },
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontSize: 18,
            fontWeight: "400",
            mb: isMobile ? 4 : 0,
            [theme.breakpoints.down("sm")]: {
              fontSize: 14,
            },
          }}
        >
          {activeSponsor?.description}
        </Typography>
      </Grid>
    </>
  );
};

export default SponsorGrid;
