import { useState } from "react";
import ExchangesPromotions from "../ExchangesPromotions";
import MedicalAssistance from "../MedicalAssistance";
import MedicalInsurance from "../MedicalInsurance";

import "./responsiveAccordion.scss";
import SelectedPlan from "../../selectedPlan/SelectedPlan";
import { Container } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";

import { BsXLg } from "react-icons/bs";

const ResponsiveAccordion = ({ plan, handleClose, setOpenPayPlan }) => {
  const [activeTab, setActiveTab] = useState(null);

  const isDown600 = useMediaQuery("(max-width:600px)");

  const handleTabClick = (index) => {
    setActiveTab((prevActiveTab) => (prevActiveTab === index ? null : index));
  };

  const accordionData = [
    {
      component: <MedicalAssistance productData={plan} />,
      title: "Seguro Medico",
      bg: "#7265e3",
    },
    {
      component: <MedicalInsurance productData={plan} />,
      title: "Asistencia Medica",
      bg: "#9488fb",
    },
    {
      component: <ExchangesPromotions productData={plan} />,
      title: "Canjes y Promociones",
      bg: "#ada4ff",
    },
  ];

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignItems: "start",
        position: "relative",
      }}
    >
      <ul className="content position-relative">
        <SelectedPlan
          plan={plan}
          agedPlan={false}
          handleClose={handleClose}
          setOpenPayPlan={setOpenPayPlan}
        />
        <span
          onClick={() => handleClose(false)}
          className="d-block d-lg-none"
          style={{
            position: "absolute",
            top: 20,
            left: "95%",
            color: "#000",
            zIndex: 1500,
            cursor: "pointer",
          }}
        >
          <BsXLg />
        </span>
        {accordionData.map((item, index) => {
          return (
            <div
              className="inner-content"
              style={
                index === 0
                  ? {
                      backgroundColor: "#7265e3",
                      top: "-65px",
                      borderRadius: "0 55px 55px 55px",
                      padding: "15px",
                      position: "relative",
                      left: 0,
                      zIndex: 300,
                      boxShadow: "9px 4px 34px 0px rgba(0, 0, 0, 0.25)",
                    }
                  : index === 1
                  ? {
                      backgroundColor: "#9488fb",
                      top: "-145px",
                      borderRadius: "0 55px 55px 55px",
                      padding: "15px",
                      position: "relative",
                      left: 0,
                      zIndex: 200,
                      boxShadow: "8px 4px 27px 0px rgba(0, 0, 0, 0.25)",
                    }
                  : {
                      backgroundColor: "#ada4ff",
                      top: "-225px",
                      borderRadius: "0 55px 55px 55px",
                      padding: "15px",
                      position: "relative",
                      left: 0,
                      zIndex: 100,
                      boxShadow: "9px 4px 34px 0px rgba(0, 0, 0, 0.25)",
                    }
              }
            >
              <div style={{ height: "100px" }}></div>
              <li
                key={index}
                onClick={() => handleTabClick(index)}
                className="accordion__item"
                style={{ backgroundColor: item.bg, width: "100%" }}
              >
                <input
                  type="checkbox"
                  className="check"
                  checked={activeTab === index ? false : true}
                />
                {/* <i className="accordion__arrow"></i> */}
                <h2 className="accordion__title text-white">{item.title}</h2>
                <div className="accordion__content">{item.component}</div>
              </li>
            </div>
          );
        })}
      </ul>
      {/* </div> */}
    </Container>
  );
};

export default ResponsiveAccordion;
