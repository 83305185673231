export const handleWhatsapp = (supportPhone) => {
  const whatsappLink = `https://wa.me/${supportPhone}`;
  window.open(whatsappLink, "_blank");
};

export const sendEmail = (email, subject) => {
  // Construct the mailto URL with email, subject, and body
  const mailtoUrl = `mailto:${email}?subject=${subject}`;

  // Open the default email client with the pre-filled email
  window.open(mailtoUrl);
};
