import Button from "react-bootstrap/Button";
import { Col, Container, Row, Stack } from "react-bootstrap";

import AllSportDetails from "../components/allSportDetails/AllSportDetails";
import AllSportLegalInfo from "../components/allSportFooterLinks/AllSportLegalInfo";
import Support from "../components/allSportFooterLinks/Support";

import "./footer.scss";
import AboutUs from "../components/allSportFooterLinks/AboutUs";
import Comunnication from "../components/allSportFooterLinks/Comunnication";
import { useTheme } from "@emotion/react";

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get the current year
  const theme = useTheme();

  return (
    <footer
      style={{ background: theme.palette.dark.main }}
      className="py-5 px-3"
    >
      <Container fluid style={{ width: "90%" }}>
        <Row>
          <Col md="6">
            <h3 className="fw-bold text-white lh-lg w-75">
              ¡No te pierdas nuestros cupones de descuento y actividades!
            </h3>
          </Col>
          <Col sm="12">
            <div className="w-100">
              <div className="row height d-flex justify-content-center align-items-center">
                {/* <div className="col-md-8"> */}
                <div className="search">
                  <i className="fa fa-search"></i>
                  <input
                    type="text"
                    className="form-control rounded-pill"
                    placeholder="Ingresa tu email"
                    style={{ color: "#72737c" }}
                  />
                  <Button style={{ background: theme.palette.accent.main }}>
                    SUSCRIBETE
                  </Button>
                </div>
                {/* </div> */}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-5" style={{ marginTop: "120px" }}>
          <Col lg="3" md="12" className="mb-3">
            <AllSportDetails theme={theme} />
          </Col>
          <Col lg="2" sm="6">
            <AllSportLegalInfo theme={theme} />
          </Col>
          <Col lg="2" sm="6">
            <Support theme={theme} />
          </Col>
          <Col lg="2" sm="6">
            <AboutUs theme={theme} />
          </Col>
          <Col lg="3" sm="6">
            <Comunnication theme={theme} />
          </Col>
        </Row>
      </Container>

      <div className="copyright">
        <p className="text-white mt-3 mx-auto" style={{ width: "90%" }}>
          ALL SPORTS + - ©{currentYear} All Rights Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
