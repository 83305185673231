import ListGroup from "react-bootstrap/ListGroup";
import { FaMapMarkerAlt, FaPhoneAlt, FaRegEnvelope } from "react-icons/fa";
import { useSelector } from "react-redux";
import { handleWhatsapp, sendEmail } from "../../utils/Links";

const Comunnication = ({ theme }) => {
  const { supportPhone } = useSelector((state) => state.auth);
  // console.log(supportPhone);
  return (
    <>
      <ListGroup>
        <ListGroup.Item
          className="border-0 text-white fw-bold ps-0"
          style={{ backgroundColor: theme.palette.dark.main }}
        >
          Hablemos
        </ListGroup.Item>
        <ListGroup.Item
          className="border-0 text-white ps-0"
          style={{ backgroundColor: theme.palette.dark.main, color: "#9b9ca8" }}
        >
          <FaMapMarkerAlt className="me-2" />
          San José, Costa Rica
        </ListGroup.Item>
        <ListGroup.Item
          className="border-0 text-white ps-0"
          style={{ backgroundColor: theme.palette.dark.main }}
          onClick={() => handleWhatsapp(supportPhone)}
        >
          <FaPhoneAlt className="me-2" />
          +506 8703 8008
        </ListGroup.Item>
        <ListGroup.Item
          className="border-0 text-white ps-0"
          style={{
            backgroundColor: theme.palette.dark.main,
            cursor: "pointer",
          }}
          onClick={() => sendEmail("info@allsports.plus", "Soporte")}
        >
          <FaRegEnvelope className="me-2" />
          <a>info@allsports.plus</a>
        </ListGroup.Item>
      </ListGroup>
    </>
  );
};

export default Comunnication;
