import React, { useEffect } from "react";
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { styles } from "../styles/styles";
import apple from "../assests/table.png";
export const InfoCoinsPage = () => {
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, []);
  return (
    <Box>
      <Container maxWidth="lg">
        <Box
          sx={{
            width: "100%",

            [theme.breakpoints.down("md")]: {
              justifyContent: "center",
              minHeight: 0,
            },
          }}
          mt={{ xs: 15, sm: 15, md: 20 }}
          mb={{ xs: 10, sm: 10, md: 20 }}
        >
          <Grid container sx={styles.titleGridContainer}>
            <Box sx={styles.titleCard}>
              <div className="titleIcon">
                <Typography mr={"auto"} ml={2} sx={styles.boldTextLight}>
                  Reglamento sobre la conversión y uso de All Sports Coins
                </Typography>
              </div>
            </Box>
          </Grid>
          <ol className="termsList">
            <li>
              <a href="#section1">1. Definiciones</a>
            </li>
            <li>
              <a href="#section2">2. Condiciones generales del uso Coins</a>
            </li>
            <li>
              <a href="#section3">
                3. Condiciones sobre la conversión de Coins
              </a>
            </li>
            <li>
              <a href="#section4">
                4. Condiciones sobre la conversión de Coins
              </a>
            </li>
            <li>
              <a href="#section5">5. Política de reembolso de Coins</a>
            </li>
          </ol>
          <ol className="termsList">
            <Box mt={2} mb={2} id="section1">
              <Typography mr={"auto"} sx={[styles.boldTextTerms]}>
                1. Introducción
              </Typography>
              <Typography>
                Lea atentamente . Lea atentamente los Términos y Condiciones de
                uso detallados a continuación, ya que establecen de forma
                vinculante y excluyente, las reglas de uso de todos los
                servicios, sitios web y aplicaciones de software desarrolladas
                y/o proporcionados por ALL SPORTS PLUS® incluido el acceso a los
                mismos. EL HECHO DE ACCEDER, VER O UTILIZAR CONTENIDOS,
                PRODUCTOS O SERVICIOS A TRAVÉS DE LA PLATAFORMA DIGITAL DE ALL
                SPORTS PLUS®, PONE DE MANIFIESTO QUE EL USUARIO COMPRENDE Y
                ACEPTA LAS CONDICIONES GENERALES COMO UN ACUERDO LEGAL
                VINCULANTE.
              </Typography>
            </Box>

            <Box mt={4} mb={4}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                1.1. Definiciones
              </Typography>
            </Box>
            <Typography>
              Para preciso entendimiento del Reglamento Sobre la Conversión y
              Uso de All Sports Coins de ALL SPORTS PLUS®, se utilizarán las
              definiciones establecidas en los Términos y Condiciones que rigen
              la Plataforma Digital de All Sports Plus.
            </Typography>

            <Box mt={2} mb={2} id="section2">
              <Typography mr={"auto"} sx={[styles.boldTextTerms]}>
                2. Condiciones generales
              </Typography>
              <Typography>
                Las cláusulas indicadas a continuación regularan el uso de
                cualquier bien, moneda y/o valor digital desarrollado o provisto
                por ALL SPORTS PLUS® y alojados en dominios, subdominios,
                aplicativos móviles o softwares propiedad del ALL SPORTS PLUS y
                utilizados en cualquier tipo de transacción, canje o intercambio
                de productos y servicios físicos y/o digitales entre el usuario
                y el Sponsor.
              </Typography>
              <Typography mr={"auto"} sx={styles.boldTextTerms2}>
                2.1. Actualización y vigencia de las cláusulas.
              </Typography>
              <Typography>
                ALL SPORTS PLUS® se reserva el derecho de cambiar las cláusulas
                de este reglamento en cualquier momento y sin previo aviso. El
                usuario acepta haber sido informado que el reglamento y su
                vigencia se encontrará permanentemente accesible este enlace y
                se compromete a consultar periódicamente el mismo para verificar
                la aplicabilidad de cada cláusula.
              </Typography>

              <Typography mr={"auto"} sx={styles.boldTextTerms2}>
                2.2. Uso no autorizado de Coins
              </Typography>
              <Typography>
                Se prohíbe el uso con fines comerciales de los Coins por parte
                de cualquier persona no autorizada. Bajo ningún término se
                considera la compra de un Plan Premium como una licencia de
                Derechos de Uso Comercial. El cliente acepta no vender,
                licenciar, alquilar, modificar, distribuir, copiar, reproducir,
                transmitir, adaptar, editar o crear material valorizable
                derivado de los mismos. En caso de detectar algún tipo de uso no
                autorizado, ALL SPORTS PLUS® recurrirá a suspender la cuenta de
                usuario involucrada, así como proceder en las vías legales
                correspondientes según lo establece la legislación
                costarricense.
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={[styles.boldTextTerms]} id="section3">
                3. Condiciones sobre la conversión de Coins
              </Typography>
              <Typography>
                Lea atentamente . Lea atentamente los Términos y Condiciones de
                uso detallados a continuación, ya que establecen de forma
                vinculante y excluyente, las reglas de uso de todos los
                servicios, sitios web y aplicaciones de software desarrolladas
                y/o proporcionados por ALL SPORTS PLUS® incluido el acceso a los
                mismos. EL HECHO DE ACCEDER, VER O UTILIZAR CONTENIDOS,
                PRODUCTOS O SERVICIOS A TRAVÉS DE LA PLATAFORMA DIGITAL DE ALL
                SPORTS PLUS®, PONE DE MANIFIESTO QUE EL USUARIO COMPRENDE Y
                ACEPTA LAS CONDICIONES GENERALES COMO UN ACUERDO LEGAL
                VINCULANTE.
              </Typography>
              <Typography mr={"auto"} sx={styles.boldTextTerms2}>
                3.1. Requerimiento de integración
              </Typography>
              <Typography>
                El usuario está de acuerdo y acepta integrar a la Plataforma
                Digital una aplicación de salud, entrenamiento o seguimiento
                deportivo de su elección, para compartir datos privados como el
                número de kilocalorías (kcal) consumidas durante la ejecución de
                una actividad físico-deportiva, para el cálculo de sus Coins y
                personalizar su experiencia.
              </Typography>
              <Typography mr={"auto"} sx={styles.boldTextTerms2}>
                3.2 Formula de conversión
              </Typography>
              <Typography>
                Para el cálculo de Coins la Plataforma Digital establece la
                siguiente fórmula de conversión: kcal x (f) = Coins. Donde
                “kcal” representa el total de kilocalorías consumidas durante de
                la ejecución de una actividad físico-deportiva reportada por la
                aplicación integrada y (f) representa el factor constante de
                1,5. Ejemplo: 10 kcal x 1,5 = 15 Coins.
              </Typography>
              <Typography mr={"auto"} sx={styles.boldTextTerms2}>
                3.3. Tipos de Coins
              </Typography>
              <Typography>Se establecen dos tipos de Coins:</Typography>
              <ol className="termsList">
                <li>
                  ● Coins de nivel: son el resultado acumulativo de todos los
                  Coins que el usuario ha generado históricamente y determinan
                  el nivel en las categorías: Rookie, Master o VIP. Los Coins de
                  Nivel no son retroactivos, pero estarán sujetos a la condición
                  de aplicabilidad.
                </li>
                <li>
                  ● Nutrición. Asistencia con especialista en Nutrición.
                  Información y orientación nutricional telefónica como consejos
                  nutricionales, guía para la preparación de dietas saludables,
                  información sobre contenido calórico de los alimentos o tabla
                  de calorías y recetas especiales para diabetes, sobrepeso o
                  intolerantes a lactosa. r.
                </li>
                <li>
                  ● Coins disponibles: son el resultado de la resta de los Coins
                  de Nivel o totales y los Coins usados para canjes y
                  donaciones. Los Coins Disponibles son retroactivos y están
                  sujetos a la condición de aplicabilidad.
                </li>
              </ol>
              <Typography mr={"auto"} sx={styles.boldTextTerms2}>
                3.4. Niveles
              </Typography>
              <Typography>
                Se establecen 4 niveles de acceso en la Plataforma Digital:
              </Typography>

              <Box
                component="img"
                sx={{
                  width: "600px",
                  cursor: "pointer",
                  [theme.breakpoints.down("sm")]: {
                    marginRight: "auto",
                    marginLeft: "auto",

                    width: "90%",
                  },
                  mb: 2,
                }}
                alt="The house from the offer."
                src={apple}
              />
              <Typography mr={"auto"} sx={styles.boldTextTerms2}>
                3.5. Retroactividad de los Coins
              </Typography>
              <Typography>
                Los Coins están sujetos a la condición de aplicabilidad y su
                disponibilidad es retroactiva. Los usuarios con una cuenta
                Training podrán acumular un máximo de 24.999 Coins de Nivel
                equivalentes al tope del nivel Beginer. El titular de Plan
                Premium que cancele paga o que sea suspendida por impago, se le
                asignmará nuevamente una cuenta Training con un tope de 24.999.
                Los coins acumulados durante tiempo de vigencia del Plan
                Premium, no se eliminarán, por lo que el usuario podrá recuperar
                su nivel y coins disponibles al momento de reactivar el plan
                Premium o de actualizar los pagos pendientes.
              </Typography>
              <Typography mr={"auto"} sx={styles.boldTextTerms2}>
                3.6. Vigencia y caducidad de los Coins
              </Typography>
              <Typography>
                Los Coins no caducan ni están sujetos a un periodo de vigencia
                mientras la Cuenta de Usuario se mantenga activa. Los Coins solo
                pueden ser eliminados en caso de eliminar la Cuenta de Usuario
                por solitud del titular o en caso administrativo, por violar los
                Términos y Condiciones de la Plataforma Digital.
              </Typography>
            </Box>
            <Box mt={2} mb={2} id="section4">
              <Typography mr={"auto"} sx={[styles.boldTextTerms]}>
                4. Condiciones del canje de Coins
              </Typography>
              <Typography mr={"auto"} sx={styles.boldTextTerms2}>
                4.1 Obtención del cupón.
              </Typography>
              <Typography>
                El usuario cliente puede hacer uso de la promoción canjeando o
                intercambiando sus All Sports Coins por un cupón equivalente a
                un artículo/servicio/producto. Este proceso de identifica como
                “Canje”. El proceso de “Canje” se realiza exclusivamente a
                través del app y su uso es exclusivo para los titulares de un
                Plan Premium. Los cupones no son retroactivos, esto significa
                que no se reintegrarán los Coins utilizados para su obtención.
                De igual forma el usuario cliente debe ser consiente y
                responsable de elegir las promociones de su agrado e verificar
                que las fechas de caducidad publicadas sean afines a su
                disponibilidad e intereses.
              </Typography>
              <Typography mr={"auto"} sx={styles.boldTextTerms2}>
                4.2 Consulta de cupones​
              </Typography>
              <Typography>
                Una vez canjeado el cupón se creará un váucher digital con un
                código QR que el usuario cliente deberá presentar en el comercio
                o local del Sponsor para redimir la promoción. El usuario
                cliente puede consultar sus cupones en la sección “Canje”
                pestaña “Mis Cupones”. Esta pantalla mostrará los cupones en
                “Activos” para los vigentes y “Redimidos” para ver el historial
                de redenciones efectivas ante los Sponsors.
              </Typography>
              <Typography mr={"auto"} sx={styles.boldTextTerms2}>
                4.3. Uso del cupón
              </Typography>
              <Typography>
                El uso de un cupón generado mediante la Plataforma Digital se
                hace exclusivamente a través de los medios comerciales
                facilitados por el Sponsors responsable de la promoción
                publicada. El uso de un cupón también está sujeto a la
                “condición de aplicabilidad” y a los procedimientos internos
                establecidos por el proveedor y/o emisor del servicio en
                cuestión.
              </Typography>

              <Typography mr={"auto"} sx={styles.boldTextTerms2}>
                4.4. Requisitos para la redención de una promoción
              </Typography>
              <ol className="termsList">
                <li>
                  ● El usuario debe presentar sin excepción, el cupón activo y
                  alojado en la sección “Mis Cupones” con el código QR desde su
                  teléfono en el establecimiento del Sponsor para poder redimir
                  el cupón. Cualquier otra forma de presentación incluyendo
                  capturas de pantalla o material impreso, invalida el reclamo
                  de cualquier beneficio.
                </li>
                <li>
                  ● El Sponsor solo podrá escanear el QR a través el app All
                  Sports Business para autorizar la redención del cupón.
                  Cualquier otra forma de escaneo, invalida el reclamo de
                  cualquier beneficio.
                </li>
              </ol>
            </Box>
            <Box mt={2} mb={2} id="section5">
              <Typography mr={"auto"} sx={[styles.boldTextTerms]}>
                5. Política de reembolso de Coins​
              </Typography>
              <Typography>
                En primera instancia, El Sponsor es el responsable de resolver
                ante el usuario cliente las diferencias o controversias que
                puedan presentarse por disconformidad al momento de la redención
                de un cupón. El Sponsor es el único responsable de establecer
                restricciones y condiciones adecuadas a sus productos y
                promociones publicitadas en la Plataforma Digital.
              </Typography>
              <Typography mr={"auto"} sx={styles.boldTextTerms2}>
                5.1. Condiciones de aplicabilidad para el reintegro.
              </Typography>
              <ol className="termsList">
                <li>
                  ● Cuando por falta de previsión del Sponsor, se agote la
                  existencia del producto, artículo o servicio ofertado en la
                  promoción. Esta situación puede darse por falta de proyección
                  en el inventario, errores de cálculo, datos comerciales
                  erróneos o falta de mantenimiento adecuado a su perfil
                  comercial en la plataforma digital de All Sports Business. Por
                  lo que en todos los casos, se demuestra de forma clara la
                  responsabilidad del Sponsor.
                </li>
                <li>
                  ● Cuando por error del Sponsor, en el cupón publicado se haya
                  puesto información errónea o incompleta incluyendo: tipo de
                  producto, descripción, condiciones y restricciones, que
                  generen un perjuicio económico al sponsor.
                </li>
                <li>
                  ● Cuando por error de All Sports Plus, se publique una
                  promoción incorrecta o no se elimine a tiempo una promoción
                  vencida, o se genere cualquier error en la interfase de la
                  plataforma que induzca a un error al usuario, cuya situación
                  genere un perjuicio económico al sponsor.
                </li>
              </ol>
              <Typography mr={"auto"} sx={styles.boldTextTerms2}>
                5.2. Protocolo de rembolso
              </Typography>
              <ol className="termsList">
                <li>
                  ● 5.2.1. Rembolso inmediato. Esta operación debe ejecutarse en
                  primera instancia, cuando exista común acuerdo entre el
                  Sponsor y el usuario sobre la aplicabilidad del reembolso.
                  Será aplicado por el Sponsor directamente desde la Plataforma
                  Business de All Sports Plus:
                  <ol className="termsList">
                    <li>
                      1. Podrá ser aplicado únicamente en el local comercial o
                      punto de venta del Sponsor y se ejecuta través del escaneo
                      del QR del cupón digital que se quiere reembolsar
                    </li>
                    <li>
                      2. Posterior a la aplicación del rembolso inmediato por el
                      Sponsor, el Usuario Cliente recibirá una notificación
                      indicando que su cupón fue rembolsado y los coins
                      correspondientes se regresarán al saldo disponible para
                      canjear por otros cupones o ser donados.
                    </li>
                    <li>
                      3. Las operaciones de reembolso serán periódicamente
                      monitoreadas por el equipo de Soporte Operativo para
                      detectar usos indebidos o fraudulentos. En caso de
                      detectar estas conductas por parte del Sponsor o el
                      Usuario Cliente, All Sports Plus deberá aplicar de
                      inmediato su Protocolo de Comprobación Antifraude.
                    </li>
                  </ol>
                </li>
                <li>
                  ● 5.2.2. Solicitud de rembolso. Cuando las posibilidades de
                  acuerdo se vean agotadas entre el Sponsor y el Usuario
                  Cliente, y la situación deba ser escalada al equipo de soporte
                  de All Sports Plus, los reportes se recibirán única y
                  exclusivamente a través de los canales de soporte:
                  <ol className="termsList">
                    <li>1. Chat en el módulo de ayuda del app</li>
                    <li>2. Correo electrónico soporte@allsports.plus</li>
                  </ol>
                </li>
              </ol>
              <Typography
                sx={{ fontWeight: "600", fontStyle: "italic", mt: 10 }}
              >
                Última actualización - Ene 23, 2022.
              </Typography>
            </Box>
          </ol>
        </Box>
      </Container>
    </Box>
  );
};
