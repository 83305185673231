import { auth, db, storageRef } from "../../firebase/firebase-config";
import { API_URL } from "../../utils/globals";
import { types } from "../types/types";

export const SET_USER_INFO = "SET_USER_INFO";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_IS_LOADING_INFO = "SET_IS_LOADING_INFO";

export const getOneUser = async (id) => {
  // console.log(id);
  try {
    const user = await db.collection("users").doc(id).get();
    const privateUserData = await db
      .collection("users")
      .doc(id)
      .collection("private")
      .doc("data")
      .get();

    const userData = {
      general: user.data(),
      private: privateUserData.data(),
    };

    return userData;
  } catch (error) {
    console.log(error);
  }
};

export const getUserInfo = (userId) => {
  console.log("start");
  return async (dispatch) => {
    try {
      // Fetch user data for the given userId
      // Fetch user data for the given userId
      const userFromFB = await db.collection("users").doc(userId).get();

      // Await the userFromFB promise to ensure data is available

      if (userFromFB.exists) {
        // User data exists, extract and process it
        const userData = userFromFB.data();
        const { personalId, phone } = await getPrivateData(userId);

        if (userData) {
          // Fetch user's plan data using getUserPlan function
          const userPlan = await getUserPlan(userData.planType.subTier); // Assuming subTier is relevant to the user's plan

          // Fetch the user's image from authentication
          const user = auth.currentUser;
          const userImage = user && user.photoURL; // Use a dummy image path if the user doesn't have one

          const userInfo = {
            userId: userId,
            name: userData.name ?? "N/A",
            lastname: userData.lastname ?? "N/A",
            secondLastname: userData.secondLastname ?? "N/A",
            email: userData.email ?? "N/A",
            personalId: personalId ?? "N/A",
            phone: phone ?? "N/A",
            userPlan: userPlan,
            productId: userPlan ? userPlan.id : "N/A",
            userImage: userImage ?? "", // Add userImage field

            ...userData,
          };

          console.log(userInfo);

          // Dispatch the user data to the Redux store
          dispatch(setUserInfo(userInfo));
        }
      } else {
        console.log("end");
        return null;
      }
    } catch (error) {
      console.log(error);
      // Handle errors as needed
      // Dispatch an error action or handle it accordingly
    }
  };
};

export const uploadForm = async (data) => {
  try {
    const collectionRef = db
      .collection("contacts")
      .doc("landing")
      .collection("requests");
    await collectionRef.add(data);
    console.log("Document successfully added!");
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};
// export const uploadFiles = async (userId, associationId, files) => {
//   console.log("Association ID:", associationId);
//   console.log("User ID:", userId);
//   console.log("files:", files);
//   try {
//     const userRef = db.doc(`users/${userId}`);
//     const userDoc = await userRef.get();

//     const storagePath = `associations/${associationId}/${userId}/userFiles/`;
//     const ref = storageRef.ref(storagePath);
//     if (!files) {
//       console.log("Files array is undefined or null");
//       return;
//     }
//     const uploadPromises = files.map(async (fileData) => {
//       const { id, file } = fileData; // Destructure id and file from the object
//       const fileRef = ref.child(file.name); // Use original file name as the reference key

//       await fileRef.put(file);

//       const imageURL = await fileRef.getDownloadURL();

//       const fileType = file.type.startsWith("image/") ? "image" : "pdf";

//       return { link: imageURL, type: fileType };
//     });

//     const uploadedFilesData = await Promise.all(uploadPromises);

//     const existingFiles = userDoc.data()?.associationFiles?.files || [];
//     const updatedFiles = existingFiles.concat(uploadedFilesData);

//     const associationDataRef = db.doc(`users/${userId}/private/documents`);
//     await associationDataRef.set(
//       {
//         associationFiles: {
//           files: updatedFiles,
//         },
//       },
//       { merge: true }
//     );
//   } catch (err) {
//     console.log(err);
//   }
// };
export const getUserPlan = async (subTier) => {
  const productsRef = await db
    .collection("products")
    .where("subTier", "==", subTier)
    .get();

  if (!productsRef.empty) {
    const productDoc = productsRef.docs[0]; // Assuming there's only one matching product
    const userProduct = {
      id: productDoc.id,
      ...productDoc.data(),
    };

    return userProduct;
  }

  return null; // No matching product found
};

export const getProduct = async (productId) => {
  const product = await db.collection("products").doc(productId).get();

  return product;
};

export const editUser = async (userId, data) => {
  try {
    await db.collection("users").doc(userId).set(data, { merge: true });
  } catch (error) {
    console.log(error);
  }
};

export const updateBillingData = async (id, billingData) => {
  try {
    db.collection("users")
      .doc(id)
      .collection("private")
      .doc("billingData")
      .set(billingData, { merge: true });
  } catch (error) {
    console.log(error);
  }
};

export const updateUserData = async (id, data, privateData, type) => {
  // console.log(id, data, type);
  try {
    if (data) {
      await db.collection("users").doc(id).set(data, { merge: true });
    }

    if (privateData) {
      await db
        .collection("users")
        .doc(id)
        .collection("private")
        .doc(type)
        .set(privateData, { merge: true });
    }
  } catch (error) {
    console.log(error);
  }
};

export const modifyUserData = async (id, data, privateData, type) => {
  try {
    if (data) {
      db.collection("users").doc(id).update(data);
    }

    if (privateData) {
      db.collection("users")
        .doc(id)
        .collection("private")
        .doc(type)
        .update(privateData);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getPrivateData = async (userId) => {
  return await db
    .collection("users")
    .doc(userId)
    .collection("private")
    .doc("data")
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.log("Error getting private data:", error);
      return {};
    });
};

export const getWalletData = async (userId) => {
  return await db
    .collection("users")
    .doc(userId)
    .collection("private")
    .doc("wallet")
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.log("Error getting wallet data:", error);
      return {};
    });
};
export const getOverallSportCoinsSum = async () => {
  try {
    const walletQuery = await db.collectionGroup("wallet").get();
    let overallSportCoinsSum = 0;

    walletQuery.forEach((doc) => {
      const walletData = doc.data();
      if (walletData && walletData.overallSportCoins) {
        overallSportCoinsSum += walletData.overallSportCoins;
      }
    });

    return overallSportCoinsSum;
  } catch (error) {
    console.error("Error getting overallSportCoins sum:", error);
    return 0; // Return 0 or handle the error as appropriate for your application.
  }
};

export const getUserCoins = (userId, callback) => {
  const walletRef = db
    .collection("users")
    .doc(userId)
    .collection("private")
    .doc("wallet");

  return walletRef.onSnapshot((doc) => {
    if (doc.exists) {
      const walletData = doc.data();
      callback({
        overallCoins: walletData.overallSportCoins || 0,
        sportCoins: walletData.sportCoins || 0,
      });
    } else {
      callback({
        overallSportCoins: 0,
        sportCoins: 0,
      });
    }
  });
};

export const uploadFiles = async (userId, files) => {
  try {
    const userRef = db.doc(`users/${userId}`);
    const userDoc = await userRef.get();

    const storagePath = `users/${userId}/userFiles/`;
    const ref = storageRef.ref(storagePath);
    if (!files) {
      console.log("Files array is undefined or null");
      return;
    }
    const uploadPromises = files.map(async (fileData) => {
      const { id, file } = fileData; // Destructure id and file from the object
      const fileRef = ref.child(file.name); // Use original file name as the reference key

      await fileRef.put(file);

      const imageURL = await fileRef.getDownloadURL();

      const fileType = file.type.startsWith("image/") ? "image" : "pdf";

      return { link: imageURL, type: fileType };
    });

    const uploadedFilesData = await Promise.all(uploadPromises);

    const existingFiles = userDoc.data()?.associationFiles?.files || [];
    const updatedFiles = existingFiles.concat(uploadedFilesData);

    const userDataRef = db.doc(`users/${userId}/private/documents`);
    await userDataRef.set(
      {
        userFiles: {
          files: updatedFiles,
        },
      },
      { merge: true }
    );
  } catch (err) {
    console.log(err);
  }
};

//PAYMENTS

export const getUserPayments = async (userId, lastPayment, limit) => {
  try {
    let payments = [];
    let query;

    if (lastPayment) {
      query = db
        .collection("users")
        .doc(userId)
        .collection("private")
        .doc("wallet")
        .collection("paymentHistory")
        .orderBy("transactionDate")
        .startAfter(lastPayment)
        .limit(limit);
    } else {
      query = db
        .collection("users")
        .doc(userId)
        .collection("private")
        .doc("wallet")
        .collection("paymentHistory")
        .limit(limit);
    }

    const result = await query.get();
    result.docs.forEach((doc) => {
      payments.push({ id: doc.id, ...doc.data() });
    });

    return payments;
  } catch (error) {
    console.log(error);
  }
};

export const setUserToSigma = async (data, token) => {
  const url = API_URL + "/sigma/users";

  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

// isWEB

export const setPayment = async (data, token) => {
  const url = API_URL + "/sigma/users";

  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const setSale = async (data, token) => {
  const url = API_URL + "/payments/sale";

  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(url, options);
    const res = await response.json();

    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const setReSale = async (data, token) => {
  const url = API_URL + "/payments/resale";

  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(url, options);
    const res = await response.json();

    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const setUserProfile = (userProfileData) => ({
  type: types.setUserProfile,
  payload: userProfileData,
});

export const setUserInfo = (userInfo) => ({
  type: SET_USER_INFO,
  payload: userInfo,
});
export const setIsLoadingInfo = (isLoading) => ({
  type: SET_IS_LOADING_INFO,
  payload: {
    isLoadingInfo: isLoading,
  },
});
export const logoutUser = () => ({
  type: LOGOUT_USER,
  payload: {},
});
export const createUser = () => {};
